
import React from 'react';
import PropTypes from 'prop-types';

import CTAButton from 'src/components/cta-button/CTAButton';


function TextField({
    fieldName,
    className,
    currentFormValue,
    setValue,
    label,
    type,
    performSearch,
    isSearchOngoing,
    hasSearchButton,
    setFieldVisiblity,
    autofocus,
}) {
    if (typeof setFieldVisiblity === 'function') {
        setFieldVisiblity(fieldName, true);
    }

    let setRef = null;
    if (autofocus) {
        setRef = function setRef(el) {
            if (autofocus && el) {
                el.focus();
            }
        }
    }

    return (
        <div
            key={fieldName}
            className={'generic-field-row '+(className || '')}
        >
            { type === 'textarea'
                ? (
                    <textarea
                        ref={setRef}
                        value={currentFormValue}
                        onChange={e => { setValue(e.target.value) }}
                        placeholder={label || ''}
                    />
                )
                : (
                    <input
                        ref={setRef}
                        type={type || 'text'}
                        value={currentFormValue}
                        onChange={e => { setValue(e.target.value) }}
                        placeholder={label || ''}
                    />
                )
            }

            { hasSearchButton &&
                <CTAButton
                    id="sfs-search-btn-small"
                    label=""
                    action={performSearch}
                    isEnabled={!isSearchOngoing}
                    >
                    <div className="fas fa-search"></div>
                </CTAButton>
            }
        </div>
    );
}

TextField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    currentFormValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setValue: PropTypes.func.isRequired,
    label: PropTypes.string,
    type: PropTypes.string, // common input types for text field (text, number, email, also handles textarea)
    setFieldVisiblity: PropTypes.func, // called on render

    // Ability to have a search button (can easily be abstracted later)
    performSearch: PropTypes.func,
    isSearchOngoing: PropTypes.bool,
    hasSearchButton: PropTypes.bool,
};

export default TextField;