
import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header/Header';
import Content from '../content/Content';
import Footer from '../footer/Footer';
import FooterBackButton from '../footer/FooterBackButton';
import StatusBlock from '../status-block/StatusBlock';

import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels'
import showConfirmModal from 'src/core/util/showConfirmModal';


import './ExtendedMenuScreen.scss';
import '../disclaimer-common.scss';


function ExtendedMenuScreen({
    context,
    totalContactsCount,
    nonSyncContactsCount,
    actions,
    labels,
}) {

    function onHeaderCloseClick() {
        actions.navigateToHome();
    }

    function onResetLicenceClick() {
        if (nonSyncContactsCount > 0) {
            actions.showNotification({
                message: labels.klipsoLeads.resetLicenceWithNonSyncContacts,
                level: NotificationLevels.WARNING,
            });
            return;
        }
        showConfirmModal({
            text: labels.klipsoLeads.resetLicenceConfirm,
            yesCb: () => {
                actions.klipsoLeadsResetLicence();
                actions.klipsoLeadsNavigate();
            },
            noCb: global.noop,
        })
    }

    function onExportClick() {
        console.log('TODO: onExportClick');
    }

    function onFooterClick() {
        actions.klipsoLeadsExitExtendedMenuScreen();
    }

    return (
        <div id="kl-ems">
            <Header
                subtitle={context.exhibitor.name}
                labels={labels}
                onCloseClick={onHeaderCloseClick}
            />

            <Content>
                <StatusBlock eventName={context.event.name} />

                <div className="kl-ems-licence-username-row">
                    <div>
                        <Row
                            icon="fas fa-key"
                            label={labels.klipsoLeads.fields.licence}
                            value={context.licence}
                        />
                        <Row
                            icon="fas fa-user"
                            label={labels.klipsoLeads.fields.userName}
                            value={context.userName}
                        />
                    </div>
                    <div
                        className="kl-ems-reset-licence"
                        onClick={onResetLicenceClick}
                    >{labels.klipsoLeads.resetLicence}</div>
                </div>

                <div
                    className="kl-ems-export"
                    onClick={onExportClick}
                >
                    <div className="kl-ems-export-label">{labels.klipsoLeads.exportContacts}</div>
                    <div className="kl-ems-export-icon far fa-external-link" />
                </div>

                <div
                    className="kl-disclaimer"
                    dangerouslySetInnerHTML={{ __html: labels.klipsoLeads.disclaimer }}
                />
            </Content>

            <Footer onClick={onFooterClick} justifyContent="start">
                <FooterBackButton labels={labels} />
            </Footer>
        </div>
    );
}

ExtendedMenuScreen.propTypes = {
    context: PropTypes.object, // licence, userName, exhibitor, event, checkpointId...
    totalContactsCount: PropTypes.number.isRequired,
    nonSyncContactsCount: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    labels : PropTypes.object.isRequired,
};

export default ExtendedMenuScreen;


function Row({ icon, label, value }) {
    return (
        <div className="kl-ems-row">
            <div className={'kl-ems-r-icon '+icon}></div>
            <div className="kl-ems-r-label">{label}</div>
            <div className="kl-ems-r-value">{value}</div>
        </div>
    );
}
