
import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-custom';

import { iosHackScrollTo0 } from 'src/core/util/browser';

import './SearchField.scss';


const LOG_PREF = '[SearchField] ';

const CLEAR_ICON_INACTIVE_STYLE = { opacity: .3 };
const CLEAR_ICON_ACTIVE_STYLE = { opacity: 1 };


class SearchField extends React.PureComponent {

    value = ''

    inputEl = null

    state = {
        clearIconStyle: CLEAR_ICON_INACTIVE_STYLE
    }

    setInputElement = el => {
        this.inputEl = el;
    }

    onInputChange = e => {
        this.setValue(e.target.value);
    }

    /**
     * Handle search value change
     * @param {string} newValue
     */
    setValue(newValue) {
        this.value = newValue;

        if (!this.value || this.value.length === 0) {
            // CASE OF EMPTY INPUT
            this.setState({ clearIconStyle: CLEAR_ICON_INACTIVE_STYLE });

            // Stop debounced search
            this.debouncedPerform.cancel();

            // Remove search results
            this.props.clearResults();
        }
        else {
            // CASE OF NON-EMPTY INPUT
            this.setState({ clearIconStyle: CLEAR_ICON_ACTIVE_STYLE });

            // Trigger search
            if (this.value.length > this.props.minChars-1) {
                this.debouncedPerform(this.value);
            }
        }
    }

    /**
     * Spare CPU - don't send a search action for each character typed
     */
    debouncedPerform = debounce(str => {
        this.props.perform(str);
    }, 500);

    clearBtnClickHandler = () => {
        this.clear();
        this.focus(); // keep keyboard displayed
    }

    /**
     * Clear typed string
     */
    clear = () => {
        if (this.inputEl) {
            this.inputEl.value = '';
            this.setValue('');
        }
    }

    focus = () => {
        if (this.inputEl) {
            this.inputEl.focus();
        }
    }

    onBlur = e => {
        iosHackScrollTo0();

        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur(e);
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.searchResults && !nextProps.searchResults) {
            this.clear()
        }
    }

    /**
     * special cancel button handler (e.g to hide search field)
     */
    cancel = () => {
        if (typeof this.props.onCancel !== 'function') {
            console.error('Implementation error: SearchField has a cancel button '
                + '(`hasCancelButton` prop is truthy) but is missing `onCancel` prop (function)');
            return;
        }
        this.props.onCancel();
        this.clear();
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <div className={'search-field-container '+(this.props.isContextual ? 'search-field-contextual' : '')}>
                <div className="search-field-input-container">
                    <input ref={this.setInputElement}
                           onChange={this.onInputChange}
                           onBlur={this.onBlur}
                           placeholder={this.props.placeholder} />

                    { this.props.hasClearButton &&
                        <span className="search-field-clear fal fa-times"
                              style={this.state.clearIconStyle}
                              onClick={this.clearBtnClickHandler} />
                    }
                </div>

                { this.props.hasCancelButton &&
                    <div className="search-field-cancel-container">
                        <span className="search-field-cancel fa fa-times"
                              onClick={this.cancel} />
                    </div>
                }
            </div>
        );
    }
};

SearchField.propTypes = {
    placeholder : PropTypes.string.isRequired,
    perform     : PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired,
    minChars    : PropTypes.number.isRequired,
    onBlur      : PropTypes.func,
    isContextual: PropTypes.bool,
    hasClearButton: PropTypes.bool,
    hasCancelButton: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default SearchField;
