
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// App modules
import { HOME_PAGE_KEY } from 'src/pages/pagesKeys';
import Menu from 'src/components/menu/Menu';

import * as actions from 'src/store/actions';

import './HomePage.scss';


const LOG_PREF = '[HomePage] ';


class HomePage extends Component {

    constructor(props) {
        super(props);
        this.pageKey = HOME_PAGE_KEY;
    };

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    /**
     * String displayed in app toolbar
     * @return {string}
     */
    getPageTitle() {
        return this.props.labels.home.title;
    }

    componentDidMount() {
        this.setDocumentContext();
    }
    componentDidUpdate() {
        this.setDocumentContext();
    }

    getContent(props) {
        if (Array.isArray(props.homeConfig) !== true) {
            return null;
        }
        return props.homeConfig.map((entry, index) => {
            let entryProps = entry.props || {};
            entryProps.inboxCount = this.props.inboxUnreadCount;
            return <entry.component
                        key={index}
                        {...entryProps}
                        lang={props.labels.id}
                        labels={props.labels}
                        actions={props.actions}
                        profile={props.profile}
                        isLoggedIn={props.isLoggedIn}
                        associatedPageKey={this.pageKey}
                        adConfig={props.adConfig}
                        isPageVisible={props.isVisible}
                        isAppVisible={props.isAppVisible} />;
        });
    }

    render() {
        console.log(LOG_PREF+'render');

        // Keep rendering (avoid ad "glitch" rendering)
        // if (!this.props.isVisible) return null;

        return (
            <>
                <Menu
                    options={this.props.menuOptions}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    profile={this.props.profile}
                    associatedPageKey={this.pageKey}
                    adConfig={this.props.adConfig}
                    twoColumns={this.props.twocolumns}
                    isLocationEnabled={this.props.isLocationEnabled}
                    />

                <div id="home-content"
                     className="content-font"
                     style={this.props.homeStyle}
                     >
                    { this.getContent(this.props) }
                </div>
            </>
        );
    };
}

HomePage.propTypes = {
    homeConfig : PropTypes.array.isRequired,
    homeStyle  : PropTypes.object,
    adConfig   : PropTypes.object,
    isLoggedIn : PropTypes.bool,
    // Common page props
    menuOptions       : PropTypes.object.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    isVisible         : PropTypes.bool, // set by togglePageAfterNavigation common reducer function
};

const mapStateToProps = (state, ownProps) => state[HOME_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);
