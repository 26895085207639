
import { SET_LOGIN_STATUS } from 'src/store/actionTypes';

import { getParticipants } from './ParticipantsService';


let isLoggedIn = false;

export default ({ dispatch, getState }) => next => action => {
    const result = next(action);

    switch (action.type) {

        case SET_LOGIN_STATUS:
            if (isLoggedIn !== true && action.loggedIn) {
                // Init participants data on log in
                getParticipants();
            }
            isLoggedIn = action.loggedIn;
            break;

        default: // for linter
    }

    return result;
}