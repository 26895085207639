
import React from 'react';
import PropTypes from 'prop-types';


function FooterExtendedMenuButton({ actions }) {
    return (
        <div
            className="klf-btn far fa-ellipsis-h"
            onClick={() => { actions.klipsoLeadsEnableExtendedMenuScreen(); }}
        />
    );
}

FooterExtendedMenuButton.propTypes = {
    actions: PropTypes.object.isRequired,
};

export default FooterExtendedMenuButton;