
const LOG_PREF = '[Favorites] ';


/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = (function(){
    const LOCALSTORAGE_KEY = 'favorites';

    return {
        get: function(){
            let value = localStorage.getItem(LOCALSTORAGE_KEY);
            return value ? JSON.parse(value) : {};
        },
        set: function(value){
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(value || {}));
        },
    };
})();


// Init from localstorage
let favorites = persistence.get();


/**
 * Init favorites array for a given `dataType if needed`
 * @param  {string} dataType
 */
function initTypeIfNeeded(dataType) {
    if (!Array.isArray(favorites[dataType])) {
        favorites[dataType] = [];
    }
}


/**
 * Remove all favorites (mostly for dev purpose)
 */
export function removeAll() {
    favorites = {};
    persistence.set(favorites);
}


/**
 * Return the array of favorite for a given `dataType`
 * @param  {string} dataType
 * @return {array}
 */
export function get(dataType) {
    if (typeof dataType === 'undefined') {
        console.error(LOG_PREF+'Missing dataType parameter');
        throw new Error('Favorites.get : dataType argument is undefined');
    }
    else {
        initTypeIfNeeded(dataType);
        return favorites[dataType];
    }
}


/**
 * Simply return all favorites
 * @return {object}
 */
export const getAll = () => favorites;


/**
 * Indicate if an element is included in favorites
 * @param  {number} id
 * @param  {string} dataType
 * @return {boolean}
 */
export const isFavorite = (id, dataType) => {
    if (typeof id === 'string' && /^\d+$/.test(id)) {
        id = parseInt(id, 10);
    }
    return get(dataType).indexOf(id) !== -1;
};


/**
 * @param  {number}  id
 * @param  {string}  dataType
 * @param  {Boolean} isFavorite
 * @return {Boolean} true: if favorites array has been updated
 */
export function toggle(id, dataType, isFavorite) {


    if (typeof id === 'number') {
        // OK
    } else if (typeof id === 'string') {
        if (/^\d+$/.test(id)) {
            id = parseInt(id, 10);
        }
    } else {
        console.error(LOG_PREF+'`id` should be a number or a string');
        return false;
    }

    initTypeIfNeeded(dataType);

    // Remove from favorites
    let index = get(dataType).indexOf(id);

    if (isFavorite === true) {
        if (index === -1) {
            // console.log(LOG_PREF+dataType+ ' of id '+id+' cannot be removed from favorites because it is not included.');
        } else {
            favorites[dataType].splice(index, 1);
            persistence.set(favorites);
            return true;
        }

    // Add to favorites
    } else {
        if (index !== -1) {
            // console.log(LOG_PREF+dataType+ ' of id '+id+' is already included in favorites.');
        } else {
            favorites[dataType].push(id);
            persistence.set(favorites);
            return true;
        }
    }
    return false;
}
