import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import config from "data/config/config";

import note from "src/core/notes/Notes";

import "./NoteButton.scss";

class NoteButton extends PureComponent {
    /**
     * When main button is clicked, display modal with a list of social networks
     * @param {object} e
     */
    onNoteButtonClick = e => {
        const { itemTitle, itemId, dataType, actions } = this.props;
       
        actions.showNoteModal(itemTitle, itemId, dataType);
    };

    render() {
        const { hasNote } = this.props;

        if (config.NOTES.FEATURE_ENABLED !== true) {
            return null;
        }

        if (Array.isArray(config.NOTES.RESTRICTIONS)) {
            let restriction = config.NOTES.RESTRICTIONS.find(
                restr => restr.pageKey === this.props.pageKey
            );
            if (restriction) {
                // A restriction has been defined for this page
                if (restriction.for.indexOf(this.props.profile) !== -1) {
                    // Restriction applies for this profile, so skip rendering note button
                    return null;
                }
            }
        }
        let noteClasses = ["note-btn", "fa-sticky-note", "clickable"];
        if (hasNote === true) {
            noteClasses.push("fas");
        } else {
            noteClasses.push("far");
        }
        return (
            <div>
                <div
                    className={noteClasses.join(" ")}
                    title={hasNote ? this.props.labels.common.editNote : this.props.labels.common.addNote}
                    onClick={this.onNoteButtonClick}
                />
            </div>
        );
    }
}

NoteButton.propTypes = {
    itemTitle: PropTypes.string.isRequired,
    itemId: PropTypes.number.isRequired,
    hasNote: PropTypes.bool,
    // common props
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
};

export default NoteButton;
