
import { NOTES_PAGE_KEY } from 'src/pages/pagesKeys';

import NotesPage from './NotesPage';
import reducer from './notesPageReducer';

export default {
    key      : NOTES_PAGE_KEY,
    path     : '/notes',
    component: NotesPage,
    elId     : 'notes-page',
    getReduxReducer: (state, action) => reducer(state[NOTES_PAGE_KEY], action),
};
