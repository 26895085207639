
import React from 'react';
import PropTypes from 'prop-types';


const FooterCancelButton = ({ labels, callback }) => (
    <div
        className="klf-btn klf-cancel-btn"
        onClick={callback}
    >{labels.klipsoLeads.cancel}</div>
)

FooterCancelButton.propTypes = {
    labels: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
}

export default FooterCancelButton;