
import * as config from 'data/config/config';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    CONFIG_JSON_LOADED,
    HAS_NAVIGATED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    USER_DATA_UPDATED,
    UPDATE_USER_DATA_REQUEST_STATUS,
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    getPageCommonDefaultState,
    profileChanged,
    toggleMenu,
    togglePageAfterNavigation,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import { logout } from 'src/core/login/LoginService';
import { getUserData } from 'src/core/user-data/UserDataService';


function getDefaultState() {
    let commonDefaultState = getPageCommonDefaultState(USER_DATA_PAGE_KEY);

    return Object.assign({}, commonDefaultState, {
        userData: getUserData(),
        sections: config.USER_DATA.SECTIONS,
        tos: config.TOU_LINK[commonDefaultState.labels.id],
        tosLinkTarget: config.TOU_LINK_TARGET,
        externalResourceLinkTarget: config.USER_DATA.externalResourceLinkTarget,
        logout: logout,
        status: {
            saving: false,
            error: null,
        },
    });
}

export const _langChanged = (state, action) => updateObject(state, {
    labels: action.labels,
    tos: config.TOU_LINK[action.labels.id]
});

const _updateUserdata = (state, action) => updateObject(state, {
    userData: action.userData,
    status: {
        saving: false,
        error: null,
    },
})


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case HAS_NAVIGATED: return togglePageAfterNavigation(state, USER_DATA_PAGE_KEY, action.pageKey);
        case LANG_CHANGED: return _langChanged(state, action);
        case PROFILE_CHANGED: return profileChanged(state, action, USER_DATA_PAGE_KEY);
        case TOGGLE_MENU: return toggleMenu(state, action, USER_DATA_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, USER_DATA_PAGE_KEY);
        case USER_DATA_UPDATED: return _updateUserdata(state, action);
        case UPDATE_USER_DATA_REQUEST_STATUS: return updateObject(state, { status: action.status });

        default: return state;
    }
};
