
import React from 'react';

import { getAlphabeticalListByIndexThreshold } from 'data/config/listConfig';

import AlphabeticalList from 'src/components/list/AlphabeticalList';
import List from 'src/components/list/List';
import TypeBar from 'src/components/type-bar/TypeBar';

import STATUS from 'src/store/fetchStatuses';


export function renderList({
    items,
    status,
    favIconDisabled,
    favorites,
    userData,
    actions,
    labels,
    pageKey,
    listHeight,
}) {

    let dataTypes = Object.keys(items);

    return dataTypes.map(dataType => {

        let _items = items[dataType];
        if (_items.length === 0) {
            return null;
        }

        let listProps = {
            key              : dataType,
            actions          : actions,
            labels           : labels,
            favorites        : favorites,
            isPending        : status[dataType] === STATUS.PENDING,
            dataType         : dataType,
            displayFavorites : favIconDisabled !== true,
            pageKey          : pageKey,
            userData         : userData,
        };

        let list;
        if (_items.data.all) {
            listProps.items = _items.data.all;
            list = <List {...listProps} />;
        }
        else {
            listProps.items = _items.data;
            listProps.indexes = Object.keys(_items.data);
            listProps.getSeparators = _items.getSeparators;
            listProps.contentByIndex = _items.length > getAlphabeticalListByIndexThreshold();

            list = <AlphabeticalList
                        parentHeight={listHeight}
                        associatedPageKey={pageKey}
                        {...listProps}
                        itemsCount={_items.length}
                        />;
        }

        // If several lists are shown, wrap with a type separator
        if (dataTypes.length > 1) {
            return (
                <TypeBar
                    key={dataType}
                    label={labels.data[dataType].plural}>
                    {list}
                </TypeBar>
            );
        } else {
            return list;
        }
    });

}
