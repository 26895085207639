import ReactDOM from 'react-dom';

import { elementPropsGetters, LIST_SIDEINDEX_THRESHOLD } from 'data/config/listConfig';

import STATUS from 'src/store/fetchStatuses';
import { indexLetterGetter } from 'src/core/util/StringUtil';

const isAlphabetical = itemsCount => itemsCount > LIST_SIDEINDEX_THRESHOLD


/**
 * Iterate on every entry to:
 *   - identify all different first letters
 *   - group entries by their first letter
 *
 * @param {object}
 * @param {array}
 * @param {string}
 *
 * @return {object}
 */
export function parseListItems(status, items, pageKey) {
    let parsedItems = {};

    // loop on data types
    Object.keys(items).forEach(function parseItemsForListPage(dataType) {
        parsedItems[dataType] = {};

        let _items = items[dataType],
            data = {},
            separators,
            length;

        if (status[dataType] !== STATUS.FETCHED || !Array.isArray(_items)) {
            length = 0;
        } else {
            length = _items.length;

            if (isAlphabetical(_items.length) !== true) {
                data.all = _items;
            }
            else {
                // Spread items by their first letter
                separators = {};
                // parsedItems[dataType].separatorEls = {};
                let listElPropsGetter = elementPropsGetters(dataType, pageKey);

                function push(item, char) {
                    if (Array.isArray(data[char]) !== true) {
                        data[char] = [];

                        // Insert a separator item
                        data[char].push({
                            isSeparator: true,
                            text: char,
                            ref : instance => {
                                // parsedItems[dataType].separatorEls[char] = ReactDOM.findDOMNode(instance);
                                separators[char] = ReactDOM.findDOMNode(instance);
                            },
                        });
                    }
                    // Add item
                    data[char].push(item);
                };

                // loop on items
                _items.forEach(function(item) {
                    let string = (listElPropsGetter.useForIndexLetter || listElPropsGetter.text)(item);
                    let char = indexLetterGetter(string);
                    push(item, char);
                });
            }
        }

        parsedItems[dataType] = {
            data,
            getSeparators: function() { return separators; },
            length,
        };
    });
    return parsedItems;
}
