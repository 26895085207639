
import React from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es.js';

import './Tabs.scss';

const TAB_MIN_WIDTH = 128;
const TAB_MAX_WIDTH = 200;

const FOLLOWING_TAB_WIDTH_VISIBLE = 50; // percent

const SELECTED_TAB_CLASS = 't-tab-selected';
const DISABLED_TAB_CLASS = 't-tab-disabled';


export default class Tabs extends React.Component {

    static propTypes = {
        data: PropTypes.array.isRequired,
        onTabIndexChange: PropTypes.func.isRequired,
        style: PropTypes.object,
        selectedTabStyle: PropTypes.object,
    }

    static defaultProps = {
        style: {},
        selectedTabStyle: {},
    }

    state = {}
    tabsEls = {}

    componentWillUnmount() {
        this.tabsEls = {};
    }

    setTabEl = tabEl => {
        if (tabEl) {
            this.tabsEls[tabEl.dataset.index] = tabEl;
        }
    }

    determineTabWidth() {
        let totalTabs = Object.keys(this.props.data).length;
        let clientWidth = document.documentElement.clientWidth;
        let tempTabWidth = clientWidth/totalTabs;
        let tabWidth = tempTabWidth;

        if (tempTabWidth<TAB_MIN_WIDTH) {
            let percentOfTheNextTab = FOLLOWING_TAB_WIDTH_VISIBLE/100;
            let tempTabNumber = clientWidth/TAB_MIN_WIDTH
            let tempTabNumberFloored = Math.floor(tempTabNumber)
            let tempTabNumberFloat = tempTabNumber - tempTabNumberFloored
            let tabNumber = Math.max(1+percentOfTheNextTab,Math.floor(clientWidth/TAB_MIN_WIDTH)-(1-percentOfTheNextTab))

            if(tempTabNumberFloat>percentOfTheNextTab)
                tabNumber = Math.max(1+percentOfTheNextTab,Math.floor(clientWidth/TAB_MIN_WIDTH)+percentOfTheNextTab)

            tabWidth = clientWidth/tabNumber
        }
        else if (tempTabWidth>TAB_MAX_WIDTH) {
            tabWidth = TAB_MAX_WIDTH
        }
        // Update state only if necessary
        if (tabWidth !== this.state.tabWidth) {
            this.setState({ tabWidth: tabWidth });
        }
    }

    setTabsRef = el => {
        this.tabsEl = el;
        this.updateXMaxThreshold();
    }

    updateXMaxThreshold() {
        if (this.tabsEl) {
            if (this.tabsEl.offsetWidth < document.documentElement.clientWidth) {
                // No auto scroll when there is no x overflow
                this.xMaxThreshold = 0;
            } else {
                this.xMaxThreshold = this.tabsEl.offsetWidth - document.documentElement.clientWidth;
            }
        }
    }

    setTabsContainerRef = el => {
        this.tabsContainerEl = el;
    }

    handleTabClick = event => {
        let tabEl = event.target;

        if (tabEl.classList.contains(DISABLED_TAB_CLASS)) {
            return;
        }

        const tabIndex = parseInt(tabEl.dataset.index, 10);

        this.setIndex(tabIndex);
    }

    setIndex(index) {
        this.props.onTabIndexChange(index);

        let tabEl = this.tabsEls[index];

        // Auto scroll to center tab el
        if (tabEl && this.tabsEl && this.tabsContainerEl) {
            let newLeftValue = tabEl.offsetLeft - (document.documentElement.clientWidth-tabEl.offsetWidth)/2;

            // keep most right element along the edge
            if (newLeftValue > this.xMaxThreshold) {
                newLeftValue = this.xMaxThreshold;
            }
            // keep most left element along the edge
            else if (newLeftValue < 0) {
                newLeftValue = 0;
            }
            anime({
                targets: this.tabsContainerEl,
                scrollLeft: newLeftValue,
                easing: 'easeInOutCirc',
                duration: 600,
            });
        }
    }

    tabRenderer = ({ key, getTabContent, isCurrentTab, isClickable, ...remainingProps }, index) => (
        <div
            key={key}
            ref={this.setTabEl}
            data-index={index}
            className={'t-tab' + (isCurrentTab() ? ' '+SELECTED_TAB_CLASS : '') + (typeof isClickable === 'function' && !isClickable() ? ' '+DISABLED_TAB_CLASS : '')}
            style={{ width: this.state.tabWidth+'px' }}
            onClick={this.handleTabClick}
            {...remainingProps}>{ getTabContent() }</div>
    )

    componentDidMount() {
        this.determineTabWidth();
        this.updateXMaxThreshold();
    }

    componentDidUpdate() {
        this.determineTabWidth();
        this.updateXMaxThreshold();
    }

    render() {
        if (!Array.isArray(this.props.data) || this.props.data.length === 0) {
            return null;
        }

        return (
            <div ref={this.setTabsContainerRef}
                 className="t-tabs-container scrollbars-hidden"
                 style={this.props.style}>

                { typeof this.state.tabWidth === 'number' &&
                    <div ref={this.setTabsRef}
                         className="t-tabs"
                         style={{ width: this.props.data.length*this.state.tabWidth }}>

                        { this.props.data.map(this.tabRenderer) }
                    </div>
                }
            </div>
        );
    }
}