
import { HOME_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    // AREA,
    FEATURE_ACTIVATED,
    MIN_WIDTH,
    TOOLBAR_AREA,
    getArea,
    getSecondPage,
} from 'data/config/pagesTwoColumnsConfig';

import { get as getLabels } from 'src/core/Lang';
import { getLocation }      from 'src/core/navigation/History';
import { getOrientation }   from 'src/core/util/DomTools';
import showConfirmModal     from 'src/core/util/showConfirmModal';


let enabled = false;

const isEnabled = () => enabled === true;

function setEnabled(value) {
    if (value !== enabled) {
        console.log('Two columns mode is now '+(enabled ? 'en' : 'dis')+'abled');
    }
    enabled = FEATURE_ACTIVATED && value;
};


const pageHasToolbar = pageKey => (pageKey !== HOME_PAGE_KEY && (isEnabled() !== true || getArea(pageKey) === TOOLBAR_AREA));



/**
 * After a first page has been mounted, determine which page to mount to fill the other column
 * @param  {string}   displayedPageKey
 * @param  {object}   displayedPageProps
 * @param  {string}   profile
 * @param  {function} navigateAction
 * @return {object}   information about the page displayed
 */
function showSecondPage(displayedPageKey, displayedPageProps, profile, navigateAction) {

    // Determine in which column the first rendered page has been displayed
    //let area = getArea(displayedPageKey);

    // Determine which column is empty
    //let targetArea = area === AREA.left ? AREA.right : AREA.left;

    // then auto-mount in empty column
    let secondPage = getSecondPage(displayedPageKey, displayedPageProps, profile);

    navigateAction(secondPage.pageKey, secondPage.props);

    return secondPage;
};


let modalCurrentlyDisplayed = false;

/**
 * Auto-enable '2 columns mode' if conditions are met
 * @param  {true} shouldReload
 */
function autoEnable(shouldReload) {
    if (FEATURE_ACTIVATED === true) {
        let newValue = getOrientation() === 'landscape' && window.innerWidth >= MIN_WIDTH;

        if (newValue !== isEnabled()) {

            // Ask the user if he wants to toggle mode
            if (shouldReload && !modalCurrentlyDisplayed) {
                modalCurrentlyDisplayed = true;

                showConfirmModal({
                    title: getLabels().common.display,
                    text: getLabels().common.switch2Columns,
                    anywayCb: function() {
                        modalCurrentlyDisplayed = false;
                    },
                    yesCb: function confirmed() {
                        getLocation().reload();
                    },
                    noCb: () => {},
                });
            }
            else {
                setEnabled(newValue);
            }
        }
    }
}


export default {
    autoEnable    : autoEnable,
    pageHasToolbar: pageHasToolbar,
    isEnabled     : isEnabled,
    setEnabled    : setEnabled,
    showSecondPage: showSecondPage,
};
