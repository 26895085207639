import { SHOW_FORM_MODAL, HIDE_FORM_MODAL } from "src/store/actionTypes";
import { langChanged, updateObject } from "src/store/reducers/commons";

import { get as getLabels } from "src/core/Lang";

const getDefaultState = () => ({
    labels: getLabels(),
});

const _toggleModal = (state, action, value) => {
    return updateObject(state, {
        isOpen: value,
        form: action.form,
        formAction: action.formAction,
        formActionPayload: action.formActionPayload,
        liEl: action.liEl,
        labels: getLabels()
    });
};

export default (state = getDefaultState(), action) => {
    switch (action.type) {
        case SHOW_FORM_MODAL:
            return _toggleModal(state, action, true);
        case HIDE_FORM_MODAL:
            return _toggleModal(state, action, false);
        default:
            return state;
    }
};
