
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { iosHackScrollTo0 } from 'src/core/util/browser';

import './InputModal.scss';


class InputModal extends React.PureComponent {

    state = {
        showModal: false,
    }

    constructor(props) {
        super(props);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.isValueModified = this.isValueModified.bind(this);
        this.submit = this.submit.bind(this);
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
    }

    showModal(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({ showModal: true });
    }

    hideModal(evt) {
        this.setState({ showModal: false });
    }

    updateValue(newValue) {
        this.setState({ value: newValue });
    }

    onCancelButtonClick() {
        let onCancelReturnedValue;
        if (typeof this.props.onCancel === 'function') {
            onCancelReturnedValue = this.props.onCancel();
        }
        if (onCancelReturnedValue !== false) {
            this.hideModal();
            this.setState({ value: this.props.initialValue });
        }
    }

    submit(evt) {
        this.props.submit(this.props.name, this.state.value);

        if (this.props.hideOnSubmit) {
            this.hideModal();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && prevProps.requestStatus
                && prevProps.requestStatus.saving
                && this.props.requestStatus
                && !this.props.requestStatus.saving
                && !this.props.requestStatus.error) {
            // Was saving and is now saved
            this.hideModal();
        }
    }

    isValueModified() {
        return this.state.value !== undefined
                    && JSON.stringify(this.state.value) !== JSON.stringify(this.props.initialValue);
    }

    renderInput(isClickable) {
        let {
            disabled,
            hasError,
            label,
            name,
            placeHolder,
            readOnly,
            required,
            type,
            initialValue,
        } = this.props;

        let inputValue = isClickable ? initialValue : (this.isValueModified() ? this.state.value : initialValue);

        let inputProps = {
            name: name,
            className: 'generic-input' + (hasError(name, inputValue) ? ' generic-form-error' : ''),
            placeholder: isClickable ? placeHolder + (required ? ' *' : '') : null,
            type: type,
            label: label,
            value: inputValue,
            onBlur: iosHackScrollTo0,
            readOnly: isClickable ? true : !!readOnly,
            disabled: !!disabled,
            required: !!required,
            onClick: !readOnly && !disabled && isClickable ? this.showModal : null,
            onChange: !isClickable ? this.updateValue : null,
        };

        return isClickable
                    ? this.props.renderClickableInput(inputProps)
                    : this.props.renderModalInput(inputProps);
    }

    getModalButtons() {
        let {
            requestStatus,
            cancelButtonLabel,
            okButtonLabel,
            labels,
        } = this.props;

        let buttons = [
            <div key={0}
                 className="generic-btn"
                 onClick={this.onCancelButtonClick}>{cancelButtonLabel || labels.common.cancel}</div>
        ];
        if (this.isValueModified()) {
            buttons.push(
                <div key={1}
                     className={'generic-btn cta-modal-btn'+(requestStatus && requestStatus.saving ? ' modal-btn-disabled' : '')}
                     onClick={this.submit}
                     >{okButtonLabel || labels.common.save}</div>
            );
        }
        return buttons;
    }

    render() {
        return (
            <>
                { this.renderInput(true) }

                <Dialog className={'input-modal '+(this.props.className || '')}
                        open={this.state.showModal}
                        onBackdropClick={this.hideModal}
                        >
                    <DialogTitle className="title-font modal-title">{this.props.placeHolder}</DialogTitle>
                    <div className="input-modal-inner">

                        { this.renderInput(false) }

                        <div className="generic-btn-container">{ this.getModalButtons() }</div>
                    </div>
                </Dialog>
            </>
        );
    }
}

InputModal.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    // initialValue
    placeHolder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.func.isRequired,
    renderClickableInput: PropTypes.func.isRequired,
    renderModalInput: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    hideOnSubmit: PropTypes.bool,
    requestStatus: PropTypes.object,
    className: PropTypes.string,
    okButtonLabel: PropTypes.string, // optional (to override)
    cancelButtonLabel: PropTypes.string, // optional (to override)
    onCancel: PropTypes.func, // optional callback when cancel button is actioned
    labels: PropTypes.object.isRequired,
};

export default InputModal;