
import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import UserDataPage from './UserDataPage';
import reducer from './userDataPageReducer';

import { isSessionValid }  from 'src/core/login/LoginService';
import { refreshUserDataFromAPI }  from 'src/core/user-data/UserDataService';

export default {
    key      : USER_DATA_PAGE_KEY,
    path     : '/user-data',
    component: UserDataPage,
    elId     : 'user-data-page',
    getReduxReducer: (state, action) => reducer(state[USER_DATA_PAGE_KEY], action),
    isGranted: () => isSessionValid(),
    onShow: refreshUserDataFromAPI,
};
