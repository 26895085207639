import  {
    NOTE_CREATED,
    NOTE_DELETED
} from 'src/store/actionTypes'
// Conf
import { VALID_DATA_TYPES } from "data/config/dataConfig";
const LOG_PREF = "[Notes] ";


let dispatch;
let storeRef;
export function setReduxStore(store) {
    dispatch = store.dispatch;
    storeRef = store
}

/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = (function() {
    const LOCALSTORAGE_KEY = "notes";

    return {
        get: function() {
            let value = localStorage.getItem(LOCALSTORAGE_KEY);
            value = value ? JSON.parse(value) : {};

            // Migration (ids were stored as strings, now as numbers)
            Object.keys(value).forEach(dataType => {
                if (
                    value.hasOwnProperty(dataType) === true &&
                    VALID_DATA_TYPES.indexOf(dataType) !== -1
                ) {
                    value[dataType] = value[dataType].map(id =>
                        typeof id === "string" ? parseInt(id, 10) : id
                    );
                }
            });

            return value;
        },
        set: function(value) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(value));
        }
    };
})();

// Init from localstorage
let notes = persistence.get();
if (!notes.data) notes.data = {};

/**
 * Init notes array for a given `dataType if needed`
 * @param  {string} dataType
 */
function initTypeIfNeeded(dataType) {
    if (!Array.isArray(notes[dataType])) {
        notes[dataType] = [];
    }
    if (!Array.isArray(notes.data[dataType])) {
        notes.data[dataType] = [];
    }
}

/**
 * Remove all notes (mostly for dev purpose)
 */
export function removeAll() {
    notes = {};
    persistence.set(notes);
}

/**
 * Return the array of favorite for a given `dataType`
 * @param  {string} dataType
 * @return {array}
 */
export function get(dataType) {
    if (typeof dataType === "undefined") {
        console.error(LOG_PREF + "Missing dataType parameter");
        throw new Error("notes.get : dataType argument is undefined");
    } else {
        initTypeIfNeeded(dataType);
        return notes[dataType];
    }
}

export function saveNote(itemTitle, itemId, note, dataType) {
    if (typeof itemId === "number") {
        // OK
    } else if (typeof itemId === "string") {
        itemId = parseInt(itemId, 10);
    } else {
        console.error(LOG_PREF + "`itemId` should be a number or a string");
        return false;
    }

    if (note == "") {
        deleteNote(itemId, dataType);
        return;
    }

    initTypeIfNeeded(dataType);

    let index = get(dataType).indexOf(itemId);
    if (index === -1) {
        notes[dataType].push(itemId);
        notes.data[dataType].push(note);

        dispatch({
            type: NOTE_CREATED
        })


    } else {
        notes.data[dataType][index] = note;
    }

    //notes[itemId] = note;
    persistence.set(notes);
}

export function getNote(itemId, dataType) {
    if (!dataType || !itemId) return null;
    let index = get(dataType).indexOf(itemId);
    if (
        notes[dataType] &&
        index != undefined &&
        index != -1 &&
        notes[dataType][index]
    ) {
        return notes.data[dataType][index];
    }
    return null;
}

export function deleteNote(itemId, dataType) {
    if (!dataType || !itemId) return null;
    let index = get(dataType).indexOf(itemId);
    if (
        notes[dataType] &&
        index != undefined &&
        index != -1 &&
        notes[dataType][index]
    ) {
        notes[dataType].splice(index, 1);
        notes.data[dataType].splice(index, 1);
        persistence.set(notes);
    }
    dispatch({
        type: NOTE_DELETED,
        notes: getAll(),
    })
}

/**
 * Simply return all notes
 * @return {object}
 */
export const getAll = () => notes;

/**
 * Indicate if an element is included in notes
 * @param  {number} id
 * @param  {string} dataType
 * @return {boolean}
 */
export const hasNote = (itemId, dataType) => {
    let search = itemId;
    if (typeof itemId !== "number") {
        search = Number(itemId);
    }
    return get(dataType).indexOf(search) !== -1;
};
