
import React from 'react';
import PropTypes from 'prop-types';

import { performScan } from 'src/core/klipso-leads/KlipsoLeadsBadgeHelper';


function FooterScanButton({ actions, labels, callback }) {
    return (
        <div
            className="klf-btn fa fa-qrcode"
            onClick={() => performScan({ actions, labels, callback })}
        />
    );
}

FooterScanButton.propTypes = {
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
}

export default FooterScanButton;