 /**
  *
  * Message Component :
  * React Component used to display Messages inside the inbox
  * For details about the message structure see :
  * src/pages/inbox/inboxReducer.js
  *
  */
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import moment               from 'moment';
import { resolveAction }    from 'src/store/reducers/utils';

import 'src/components/inbox/inbox.css';

function noop () {}


const formatHour = {
    en: moment => moment.format('H:mm'),
    fr: moment => `${moment.format('H')}h${moment.format('mm')}`,
    zh: moment => moment.format('H:mm'),
};

const formatMonth = {
    en: moment => moment.format('MMMM Do'),
    fr: moment => moment.format('Do MMMM'),
    zh: moment => moment.format('MMMM Do'),
};


/**
 * @param props
 * @param props.date {Number} - timestamp, is used with the Date constructor
 * @returns JSX
 *
 */
function DateLabel({ date, lang }) {
    const m = moment(date);

    return (
        <div style={{ visibility: date === null ? 'hidden' : 'visible' }} className='inbox-date-container'>
            <div style={{ textTransform: 'capitalize' }}>{ `${formatMonth[lang](m)}` }</div>
            <div>{ `${formatHour[lang](m)}` }</div>
        </div>
    );
}

function MessageContent({ content, title }) {
    return (
        <div className='inbox-content-container'>
            <div className='inbox-content-title'>{title}</div>
            {content}
        </div>
    );
}

function MessageRedirection({ action = null }) {
    let className = 'inbox-content-redirection';

    if (!action || (typeof action === 'object' && action.type === 'inbox')) {
        className += ' inbox-no-redirect';
    }

    return (
        <div className={className}>
            <div className='fa fa-angle-right'></div>
        </div>
    );
}


export default class Message extends Component {

    static propTypes = {
        message: PropTypes.object.isRequired,
        onClick: PropTypes.func.isRequired,
        lang   : PropTypes.string.isRequired
    }


    UNSAFE_componentWillMount() {
        // we generate the handlers for items
        // if there is no action for this message
        // then its a noop, else we attach a click event
        const action = this.props.message.action;
        const handler = this.props.onClick || noop;
        if (action) {

            this.liProps = {
                onClick: () => {

                    // handler({
                    //     pageKey: action.type,
                    //     options: {
                    //         id: action.id
                    //     }
                    // });

                    if (!action || typeof action !== 'object' || action.type === 'inbox') {
                        // do nothing
                    }
                    else if (action.type && action.type.match(/https?/)) {
                        global.open(action.type, '_blank');
                    } 
                    else {
                        const params = resolveAction(action);
                        console.log(params);
                        handler(params);
                    }
                }
            };

        } else {
            this.liProps = {};
        }
    }


    render() {
        const { action, read } = this.props.message;
        const className = `inbox-message-container
                           ${action ? 'inbox-content-pointer' : ''}
                           ${read ? '' : 'inbox-message-unread'}`;

        return (
            <li {...this.liProps} >
                <div className={className}>
                    <DateLabel {...this.props.message} lang={this.props.lang} />
                    <MessageContent {...this.props.message} />
                    <MessageRedirection {...this.props.message} />
                </div>
            </li>
        );
    }
}
