
import React from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_KLIPSO_LEADS_CONTACTS } from 'data/config/dataConfig';
import { KLIPSO_LEADS_PAGE_KEY } from 'src/pages/pagesKeys';
import STATUS from 'src/store/fetchStatuses';

import Header from '../header/Header';
import Content from '../content/Content';
import StatusBlock from '../status-block/StatusBlock';
import SortSearchBar from './sort-search-bar/SortSearchBar';
import SearchArea from './search-area/SearchArea';
import ScanResult from './scan-result/ScanResult';
import Footer from '../footer/Footer';
import FooterSyncButton from '../footer/FooterSyncButton';
import FooterScanButton from '../footer/FooterScanButton';
import FooterExtendedMenuButton from '../footer/FooterExtendedMenuButton';
import OverlayLoader from '../overlay-loader/OverlayLoader';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';

import { renderList } from 'src/components/list/listUtil';
import { parseListOfGroups } from 'src/components/list/listOfGroupsUtil';

import './MainScreen.scss';

global.klDebugScanResult = false;


class MainScreen extends React.Component {

    state = {}

    static defaultProps = {}

    constructor(props) {
        super(props);
        this.onScan = this.onScan.bind(this);
    }

    navigateToHome = () => {
        this.props.actions.navigateToHome();
    }

    onScan({ result, resultFormat }) {
        if (result) {
            if (global.klDebugScanResult) {
                this.setState({
                    scanResult: result,
                    scanResultFormat: resultFormat,
                });
            } else {
                this.props.actions.klipsoLeadsEnableFormScreen(result);
            }
        }
    }

    isFooterVisible = () => !this.props.keyboardHeight

    render() {
        let content, sideIndex;

        // Search enabled
        if (this.props.searchEnabled) {
            content = (
                <SearchArea
                    results={this.props.searchResults}
                    labels={this.props.labels}
                    actions={this.props.actions}
                />
            );
        }
        // Debug: show scanned badge content
        else if (this.state.scanResult) {
            content = (
                <ScanResult
                    result={this.state.scanResult}
                    resultFormat={this.state.scanResultFormat}
                />
            );
        }
        // No result
        else if (this.props.totalContactsCount < 1) {
            content = <div className="kl-list-empty">{this.props.labels.klipsoLeads.listEmpty}</div>;
        }
        // Items sorted by company
        else if (this.props.sortedByCompany) {
            content = renderList({
                items: this.props.items,
                status: STATUS.FETCHED,
                favIconDisabled: true,
                actions: this.props.actions,
                labels: this.props.labels,
                pageKey: KLIPSO_LEADS_PAGE_KEY,
                // listHeight: this.state.listHeight,
            });
        }
        // Items sorted by last modification date
        else {
            let parseResult = parseListOfGroups({
                groupedItems: this.props.items,
                dataType: DATA_TYPE_KLIPSO_LEADS_CONTACTS,
                favIconDisabled: true,
                isPending: false,
                pageKey: KLIPSO_LEADS_PAGE_KEY,
                actions: this.props.actions,
                labels: this.props.labels,
            });
            content = parseResult.content;
            sideIndex = parseResult.sideIndex;
        }

        return (
            <div className="kl-main">
                <Header
                    labels={this.props.labels}
                    onCloseClick={this.navigateToHome}
                    subtitle={this.props.context.exhibitor.name}
                />

                <Content>
                    { this.props.syncOngoing &&
                        <OverlayLoader
                            label={this.props.labels.klipsoLeads.ongoingSync}
                        />
                    }

                    <div className="kl-main-not-scrollable">
                        <StatusBlock
                            eventName={this.props.context.event.name}
                            contactsCount={this.props.totalContactsCount}
                            nonSyncContactsCount={this.props.nonSyncContactsCount}
                            labels={this.props.labels}
                        />

                        { this.props.totalContactsCount > 0 && !this.props.searchEnabled &&
                            <SortSearchBar
                                sortedByCompany={this.props.sortedByCompany}
                                setSortedByCompany={this.props.actions.klipsoLeadsSetSortedByCompany}
                                enableSearchArea={this.props.actions.klipsoLeadsEnableSearch}
                                labels={this.props.labels}
                            />
                        }
                        <div className="kl-main-list-container">
                            { content }
                            { sideIndex }
                        </div>

                        <DummyKeyboardPlaceholder keyboardHeight={this.props.keyboardHeight} />
                    </div>
                </Content>

                { this.isFooterVisible() &&
                    <Footer>
                        <FooterSyncButton
                            hasNonSyncData={this.props.nonSyncContactsCount > 0}
                            actions={this.props.actions}
                        />
                        <FooterScanButton
                            actions={this.props.actions}
                            labels={this.props.labels}
                            callback={this.onScan}
                        />
                        <FooterExtendedMenuButton
                            actions={this.props.actions}
                        />
                    </Footer>
                }
            </div>
        );
    }
}

MainScreen.propTypes = {
    context: PropTypes.object, // licence, userName, exhibitor, event, checkpointId...
    items: PropTypes.object.isRequired,
    nonSyncContactsCount: PropTypes.number.isRequired,
    totalContactsCount: PropTypes.number.isRequired,
    syncOngoing: PropTypes.bool.isRequired,
    sortedByCompany: PropTypes.bool.isRequired,
    searchEnabled: PropTypes.bool.isRequired,
    searchResults: PropTypes.object,
    keyboardHeight: PropTypes.number,
    labels : PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
}

export default MainScreen;
