
import React from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';


const Loader = ({ theme, labels }) => (

    <div className={ ['simple-loader', theme || ''].join(' ') }>
        <div>{ labels.common.loading }</div>
    </div>

);

Loader.propTypes = {
    labels: PropTypes.object.isRequired,
    theme : PropTypes.string,
};

export default Loader;