
import { INBOX_PAGE_KEY } from 'src/pages/pagesKeys';

import InboxPage from './InboxPage';
import reducer from './inboxReducer';

export default {
    key      : INBOX_PAGE_KEY,
    path     : '/inbox',
    component: InboxPage,
    elId     : 'inbox',
    getReduxReducer: (state, action) => reducer(state[INBOX_PAGE_KEY], action)
};
