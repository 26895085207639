
import React from 'react';
import PropTypes from 'prop-types';

import Url from 'src/components/url/Url';

// import AdBanner from 'src/components/ad-banner/AdBanner';
// import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';


import './MediasPageContentBasic.scss';


class MediasPageContent extends React.PureComponent {

    render() {

        return (
            <div className="media-content-container-basic content-font content-below-apptoolbar">

                <div className="media-content-label">{this.props.labels.medias.label}</div>

                <div className="media-buttons-container">
                    { this.props.mediaButtons && this.props.mediaButtons.map(btn => (
                        <Url callback={this.props.actions.linkClicked}
                             key={btn.key}
                             href={btn.link}
                             target={global.isCordovaContext === true ? "_system" : btn.target || "_system"}
                             className="basic-media-button">
                            <div style={{ backgroundImage: `url(${btn.image})` }}></div>
                        </Url>
                    )) }
                </div>

                {/* <AdBanner
                    associatedPageKey={MEDIAS_PAGE_KEY}
                    adConfig={this.props.adConfig}
                    actions={this.props.actions}
                    labels={this.props.labels} /> */}
            </div>
        );
    }

}

MediasPageContent.propTypes = {
    mediaButtons: PropTypes.object,
    adConfig: PropTypes.object,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

export default MediasPageContent;