
import { MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import MapPage from './MapPage';
import reducer from './mapPageReducer';

export default {
    mountOnce: true,
    key      : MAP_PAGE_KEY,
    path     : '/map',
    component: MapPage,
    elId     : 'map-page',
    getReduxReducer: (state, action) => reducer(state[MAP_PAGE_KEY], action),
    toggleMode  : 'visibility',
};
