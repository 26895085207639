
import React from 'react';
import PropTypes from 'prop-types';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';
import { DATA_TYPE_KLIPSO_LEADS_CONTACTS } from 'data/config/dataConfig';
import { KLIPSO_LEADS_PAGE_KEY } from 'src/pages/pagesKeys';

import SearchField from 'src/components/search/SearchField';
import ListsPerDataType from 'src/components/list/ListsPerDataType';

import './SearchArea.scss';


class SearchArea extends React.Component {

    state = {
        searched: null,
    }

    constructor(props) {
        super(props);
        this.perform = this.perform.bind(this);
        this.clearResults = this.clearResults.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    perform(value) {
        this.setState({ searched: value });
        this.props.actions.performSearch(value, KLIPSO_LEADS_PAGE_KEY, [ DATA_TYPE_KLIPSO_LEADS_CONTACTS ]);
    }

    clearResults() {
        this.setState({ searched: null });
        this.props.actions.clearSearchResults(KLIPSO_LEADS_PAGE_KEY);
    }

    onCancel() {
        this.props.actions.klipsoLeadsExitSearch();
    }

    shouldShowResults() {
        return (
            typeof this.state.searched === 'string'
                || (this.props.results
                    && Array.isArray(this.props.results[DATA_TYPE_KLIPSO_LEADS_CONTACTS])
                    && this.props.results[DATA_TYPE_KLIPSO_LEADS_CONTACTS].length > 0)
        );
    }

    render() {
        return (
            <div className="kl-main-sa">
                <SearchField
                    labels={this.props.labels}
                    placeholder={this.props.labels.klipsoLeads.searchPlaceholder}
                    clearResults={this.clearResults}
                    perform={this.perform}
                    minChars={SEARCH_MIN_CHARS}
                    hasClearButton={false}
                    hasCancelButton={true}
                    onCancel={this.onCancel}
                />

                { this.shouldShowResults() &&
                    <ListsPerDataType
                        items={this.props.results}
                        favIconDisabled={true}
                        actions={this.props.actions}
                        labels={this.props.labels}
                        pageKey={KLIPSO_LEADS_PAGE_KEY}
                    />
                }
            </div>
        );
    }
}

SearchArea.propTypes = {
    results: PropTypes.object,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

export default SearchArea;