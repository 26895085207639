
import React from 'react';
import PropTypes from 'prop-types';

import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';
import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';

import SearchField from 'src/components/search/SearchField';
import ListsPerDataType from 'src/components/list/ListsPerDataType';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';


class SearchPageContent extends React.PureComponent {

    clearResults = () => {
        this.props.actions.clearSearchResults(SEARCH_PAGE_KEY);
    }

    render() {
        return (
            <div id="search-page-container"
                 className="content-font content-below-apptoolbar">

                <SearchField
                    labels={this.props.labels}
                    placeholder={this.props.placeholder}
                    clearResults={this.clearResults}
                    perform={this.props.performSearch}
                    minChars={SEARCH_MIN_CHARS}
                    hasClearButton={true}
                    />

                <div className="search-results">
                    { this.props.searched &&
                        <ListsPerDataType
                            items={this.props.results}
                            searched={this.props.searched}
                            isPending={this.props.isPending}
                            favorites={this.props.favorites}
                            favIconDisabled={this.props.favIconDisabled}
                            userData={this.props.userData}
                            actions={this.props.actions}
                            labels={this.props.labels}
                            pageKey={SEARCH_PAGE_KEY}
                        />
                    }
                </div>

                <DummyKeyboardPlaceholder keyboardHeight={this.props.keyboardHeight} />
            </div>
        );
    }
}

SearchPageContent.propTypes = {
    isPending: PropTypes.bool,
    placeholder: PropTypes.string,
    favIconDisabled: PropTypes.bool,
    favorites: PropTypes.object,
    performSearch: PropTypes.func.isRequired,
    searched: PropTypes.string,
    results: PropTypes.object,
    keyboardHeight: PropTypes.number,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

export default SearchPageContent;