
import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import EventContent from './EventContent';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

export default {
    key      : EVENT_PAGE_KEY,
    path     : '/event',
    elId     : DOM_ID,
    className: 'event-page',
    component: GenericItemPage,
    childComponent: EventContent,
    relatedDataToFetch: [ 'exhibitor', 'places', 'speakers', 'moderators', 'events', 'papers'],
};
