
import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import SearchPage from './SearchPage';
import reducer from './searchPageReducer';

export default {
    key      : SEARCH_PAGE_KEY,
    path     : '/search',
    component: SearchPage,
    elId     : 'search-page',
    getReduxReducer: (state, action) => reducer(state[SEARCH_PAGE_KEY], action),
};
