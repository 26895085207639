
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Message from 'src/components/inbox/Message';


export default class MessageList extends Component {

    static propTypes = {
        messages: PropTypes.array,
        onClick : PropTypes.func.isRequired,
        lang    : PropTypes.string.isRequired,
    }

    render () {
        return (
            <ul className="inbox-msg-list">
                { this.props.messages.map((message, index) => (
                    <Message
                        key={message.id + '-' + index}
                        message={message}
                        onClick={this.props.onClick}
                        lang={this.props.lang}
                    />
                )) }
            </ul>
        );
    }

}
