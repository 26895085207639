
import config from 'data/config/config';

import cordovaShare from './CordovaShare';

import { removeHtml } from 'src/core/util/DomTools';
import { addHttpIfNeeded } from 'src/core/util/JsTools';
import { getBindedActions } from 'src/store/bindedActions';


export default function(name, queryString, description, image, labels) {

    var _name = labels.common.appTitle + (name ? config.APP_TITLE_SEPARATOR + name : ''),
        desc = removeHtml(description),
        url = addHttpIfNeeded(config.WEB_URL+'/'+queryString, true);


    // CORDOVA app context: use a cordova plugin to share through OS UI
    if (typeof cordova !== 'undefined' && navigator.share) {
        cordovaShare(_name, desc, url, image, labels);
    }
    // WEB context: show a modal with mail & various social media buttons
    // @see src/components-standalone/share-dialog/ShareDialog.js
    else {
        getBindedActions().showShareDialog(_name, desc, url, image);
    }
};
