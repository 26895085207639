
import { SERVICE_PAGE_KEY } from 'src/pages/pagesKeys';

import ServiceContent from './ServiceContent';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

export default {
    key      : SERVICE_PAGE_KEY,
    path     : '/service',
    elId     : DOM_ID,
    className: 'service-page',
    component: GenericItemPage,
    childComponent: ServiceContent,
    relatedDataToFetch: [ 'places' ],
};
