
import { SEARCH_TAIGA_PAGE_KEY } from 'src/pages/pagesKeys';

import SearchTaigaPage from './SearchTaigaPage';
import reducer from './searchTaigaPageReducer';

import { isSessionValid }  from 'src/core/login/LoginService';

export default {
    key      : SEARCH_TAIGA_PAGE_KEY,
    path     : '/search-taiga',
    component: SearchTaigaPage,
    elId     : 'search-taiga-page',
    getReduxReducer: (state, action) => reducer(state[SEARCH_TAIGA_PAGE_KEY], action),
    isGranted: props => isSessionValid(),
};
