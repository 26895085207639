
import React from 'react';
import PropTypes from 'prop-types';

import './Footer.scss'


function Footer({ children, onClick, isTextBtnMode, justifyContent }) {

    let className = ['klipso-leads-footer', 'content-font'];
    if (isTextBtnMode) {
        className.push('klf-text-btn-mode');
    }

    return (
        <div
            className={className.join(' ')}
            onClick={onClick}
            style={typeof justifyContent === 'string' ? { justifyContent: justifyContent } : null}
        >
            { children }
        </div>
    )
}

Footer.propTypes = {
    onClick: PropTypes.func,
    isTextBtnMode: PropTypes.bool, // when the footer is used as a text button (e.g disclaimer)
    justifyContent: PropTypes.string, // ability to override default justify-content
}

export default Footer;
