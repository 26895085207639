import moment from 'moment';


// e.g "2019-10-25 09:00:39"
// see https://momentjs.com/docs/#/parsing/string-format/
const STRING_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const parseDate = s => moment(s, STRING_DATE_FORMAT).toDate()


function transform(data, mapping) {
    // Transform only the fields present in `data`
    let transformed = {};
    mapping.forEach(transformFunc => {
        if (typeof transformFunc !== 'function') {
            // console.log('ignoring field: '+field);
        } else {
            Object.assign(transformed, transformFunc(data));
        }
    });
    return transformed;
}

/*
    {
    x "id": "a4beeea9-6eea-e911-80e2-005056ae0696",
      "type": "",
    x "civility": "",
    x "firstName": "Séga",
    x "lastName": "SYLLA",
    x "email": "ssylla@leni.fr",
      "profilePicture": [],
    x "phoneNumber": "0320000000",
      "linkedIn": "https://fr.linkedin.com/",
    x "role": "Dev",
    x "visible": true,
    x "contactable": true,
    x "organizationName": "LENI",
      "organizationLogo": [],
      "organisationWebsite": "http://www.leni2.fr",
    x "contactedParticipants": [
        {
          "contactId": "7a8f1c7f-6fea-e911-80e2-005056ae0696",
          "contactDate": "2019-10-25 09:00:39"
        },
        {
          "contactId": "3e5619d7-f6ef-e911-80e2-005056ae0696",
          "contactDate": "2019-10-25 09:16:36"
        },
        {
          "contactId": "14700dc7-0ef0-e911-80e2-005056ae0696",
          "contactDate": "2019-10-29 11:04:06"
        }
      ],
      "events": [],
      "externalRessources": []
    }
*/

/**
 * Return format expected by UserData form/ImageUpload react components
 * @param  {object} profilePicture
 * @return {object}
 */
function normalizeProfilePicture(profilePicture) {
    if (!profilePicture || (Array.isArray(profilePicture) && profilePicture.length === 0)) {
        return null;
    }
    /*
    "profilePicture": {
      "namefile": "avatar_0.png",
      "extension": "png",
      "mime": "image/png",
      "size": 4077,
      "externalTarget": "S--V3EVT__C--Individu__F--x_picture",
      "externalId": "6ee35931-6d05-ea11-80e6-005056ae670e",
      "url": "https://lenirecettealpha.svc.calypso-event.net/lenirecettealpha/Media/Get?entityId=6ee35931-6d05-ea11-80e6-005056ae670e&property=x_picture"
    },*/
    return {
        value: profilePicture.url,
        metaData: {
            name: profilePicture.namefile,
            size: profilePicture.size,
            type: profilePicture.mime,
        }
    };
}

/**
 * Transform user data from Taiga format to app format
 */
const userDataApiToAppMapping = [
    userData => ({ id: userData.id }),
    userData => ({ title: userData.civility }),
    userData => ({ firstName: userData.firstName }),
    userData => ({ lastName: userData.lastName }),
    userData => ({ organizationName: userData.organizationName }),
    userData => ({ organizationWebsite: userData.organisationWebsite }),
    userData => ({ organizationLogo: userData.organizationLogo ? userData.organizationLogo.url : null }),
    userData => ({ position: userData.role }),
    userData => ({ role: userData.role }),
    userData => ({ type: userData.type }),
    userData => ({ email: userData.email }),
    userData => ({ phone: userData.phoneNumber }),
    userData => ({ visible: userData.visible }),
    userData => ({ contactable: userData.contactable }),
    userData => ({ contactedParticipants: userData.contactedParticipants }),
    userData => ({ profilePicture: normalizeProfilePicture(userData.profilePicture) }),
    userData => ({ social: { ln: userData.linkedIn }}),
    userData => ({ events: (userData.events || []).map(transformEventDataApiToApp) }),
    userData => ({ externalResources: userData.externalRessources }),
];

const transformUserDataApiToApp = userData => transform(userData, userDataApiToAppMapping)

/**
 * Transform user data from app format to Taiga format
 */
const userDataAppToApiMapping = [
    userData => ({ id: userData.id }),
    userData => ({ civility: userData.title }),
    userData => ({ firstName: userData.firstName }),
    userData => ({ lastName: userData.lastName }),
    userData => ({ organizationName: userData.organisation }),
    userData => ({ role: userData.position }),
    userData => ({ email: userData.email }),
    userData => ({ phoneNumber: userData.phone }),
    userData => ({ visible: userData.visible }),
    userData => ({ contactable: userData.contactable }),
    userData => ({ contactedParticipants: userData.contactedParticipants }),
    userData => ({ profilePicture: userData.profilePicture }),
    userData => ({ socialNetworkProfiles: userData.socialNetworkProfiles }),
    userData => ({ events: userData.events }),
];

const transformUserDataAppToApi = userData => transform(userData, userDataAppToApiMapping)

// TAIGA FORMAT
/*{ id: "b333b806-dcf6-e811-816f-9cb65494fc1a",
    title: "Atelier pratique en échocardiographie",
    startDate: "2020-12-11 10:00:00",
    endDate: "2020-12-11 13:00:00"
}*/

// CAKE BACKEND FORMAT
/*
  ...
  "start_date" : 1579478400000,
  "end_date" : null,
  "start_time" : "08:30",
  "end_time" : "08:40",
*/

// TEMP - WHEN PARTICIPANTS' EVENTS ARE SHIPPED IN DB.JSON
// Get them in the usual format expected by the app

const toDigitNumber = num => num < 10 ? '0'+num : num

const transformEventDataApiToApp = event => {
    let startDate = event.startDate ? parseDate(event.startDate) : null,
        endDate = event.endDate ? parseDate(event.endDate) : null;

    return {
        id: event.id,
        title: event.title,
        start_date: startDate,
        end_date: endDate && (endDate.getDay() !== startDate.getDay() || endDate.getMonth() !== startDate.getMonth()) ? endDate : '',
        start_time: startDate ? startDate.getHours()+':'+toDigitNumber(startDate.getMinutes()) : '',
        end_time: endDate ? endDate.getHours()+':'+toDigitNumber(endDate.getMinutes()) : '',
    };
}



export const transformData = {
    apiToApp: {
        user: transformUserDataApiToApp,
        event: transformEventDataApiToApp
    },
    appToApi: {
        user: transformUserDataAppToApi,
    },
}
