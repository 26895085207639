
// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

// app modules
import {
    MESSAGE_DISMISSED,
    MESSAGE_ACTIONED,
} from 'src/pages/inbox/inboxReducer';

import './InboxAlert.scss';


export const COMPONENT_KEY = 'InboxAlert';
export const CONTAINER_DOM_ID = 'inbox-alert-container';


const DEFAULT_DIALOG_PROPS = {
    // TODO: Export that to data/config/dialogsConfig ?
};



// TODO à tester



function InboxAlert(props) {

    let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
        open   : props.isOpen,
        onClose: props.onCancel, // important, to update `open` value in all cases
    });

    return (
        <Dialog {...dialogProps}>
            { props.message && props.message.title &&
                <DialogTitle className="title-font modal-title">{props.message.title}</DialogTitle>
            }
            <div className="generic-modal-content content-font notif-dialog">

                <p>{ props.message ? props.message.content : null }</p>

                <div className="generic-btn-container">
                    <div className="generic-btn-cancel" onClick={props.onCancel}>{ props.labels.inbox.dismiss }</div>
                    <div className="generic-btn cta-modal-btn" onClick={props.onAccept}>{ props.labels.inbox.confirmNotif }</div>
                </div>
            </div>
        </Dialog>
    );
}


InboxAlert.propTypes = {
    isOpen    : PropTypes.bool.isRequired,
    cancelable: PropTypes.bool.isRequired,
    onCancel  : PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    const notif = state.Inbox.notif;
    let message = null;
    if ( notif !== null ) {
        message = state.Inbox.byDate.filter(message => message.id === notif)[0];
    }

    return {
        isOpen: state.Inbox.notif !== null && typeof message === 'object' && message !== null,
        cancelable: true,
        message,
        labels: state.Inbox.labels,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCancel: () => dispatch({
            type: MESSAGE_DISMISSED,
        }),
        onAccept: () => dispatch({
            type: MESSAGE_ACTIONED,
        })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InboxAlert);
