
// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import './TypeBar.scss';


const LOG_PREF = '[TypeBar] ';


class TypeBar extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isClosed: props.isClosed === true,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isClosed !== nextProps.isClosed) {
            this.setState({ isClosed: nextProps.isClosed, });
        }
    }

    onClick = () => {
        this.calculateContentHeight();

        // For a transition when folding/unfolding content
        // we need to set content height first, and delay open/close state update
        window.setTimeout(this.state.isClosed ? this.open : this.close, 50);
    }

    open = () => {
        this.setState({
            isClosed: false,
        });
        if (typeof this.props.onToggle === 'function') {
            this.props.onToggle();
        }
    }

    close = () => {
        this.setState({
            isClosed: true,
        });
        if (typeof this.props.onToggle === 'function') {
            this.props.onToggle();
        }
    }

    getIconClassName () {
        if (this.props.isClickable === false) {
            return [];

        } else {
            let classNames = [ 'type-bar-icon', 'fa fa-chevron-down' ];
            if (this.state.isClosed !== true) {
                classNames.push('type-bar-icon-open');
            }
            return classNames.join(' ');
        }
    }

    getContentContainerStyle() {
        if (this.state.isClosed) {
            return {
                maxHeight: 0,
                opacity  : 0,
            };
        } else {
            return {
                height : '100%',
                opacity: 1,
            };
        }
    }

    setWrapperRef = wrapperRef => {
        this.wrapperRef = wrapperRef;
        this.calculateContentHeight();
    }

    calculateContentHeight() {
        if (this.wrapperRef) {
            let height = this.wrapperRef.offsetHeight;
            if (this.state.contentHeight !== height) {
                this.setState({
                    contentHeight: height,
                });
            }
        }
    }

    componentDidUpdate() {
        this.calculateContentHeight();
    }

    render() {
        console.log(LOG_PREF+'render ('+this.props.label+')');

        // Interactivity
        let typeBarAttrs;
        if (this.props.isClickable !== false) {
            typeBarAttrs = {
                onClick: this.onClick,
                style: Object.assign({ cursor: 'pointer' }, this.props.style),
            }
        } else {
            typeBarAttrs = {
                style: Object.assign({ cursor: 'default' }, this.props.style),
            }
        }

        let iconClassName = this.getIconClassName();

        return (
            <div>
                <div className="type-bar horizontal-separator" {...typeBarAttrs}>
                    {/* image */}
                    { this.props.image && <img className="type-bar-image" alt="" src={ getUrl(this.props.image) } /> }

                    {/* label */}
                    <span className="type-bar-label">
                        <div dangerouslySetInnerHTML={{ __html: this.props.label }} />

                        { this.props.subtitle &&
                             <div><small><span dangerouslySetInnerHTML={{__html:this.props.subtitle}} /></small></div>
                        }
                    </span>

                    {/* count */}
                    { typeof this.props.count === 'number' &&
                        <span className="type-bar-count">
                            <span>{this.props.count}</span>
                        </span>
                    }

                    {/* fold arrow icon */}
                    { iconClassName && <span className={ iconClassName } /> }
                </div>

                { this.props.children &&
                    <div className={'type-bar-content '+ (this.state.isClosed ? 'type-bar-content-closed' : '')}
                         style={ this.getContentContainerStyle(this.state.isClosed) }>

                        <div ref={this.setWrapperRef}>
                            {this.props.children}
                        </div>
                    </div>
                }
            </div>
        );
    }
};

TypeBar.propTypes = {
    label      : PropTypes.string.isRequired,
    subtitle   : PropTypes.string,
    image      : PropTypes.string,
    style      : PropTypes.object,
    onToggle   : PropTypes.func,

    // is list hidden on initial show
    isClosed   : PropTypes.bool,

    // is bar clickable to show/hide the children
    isClickable: PropTypes.bool,

    // Optional information displayed in a rounded and lighter <span>
    count      : PropTypes.number,

    children   : PropTypes.element,
};

export default TypeBar;
