
import { DATA_TYPE_KLIPSO_LEADS_CONTACTS } from 'data/config/dataConfig';
import { groupItems } from 'data/config/sortConfig';

import {
    CONFIG_JSON_LOADED,
    HAS_NAVIGATED,
    KEYBOARD_TOGGLED,
    KLIPSOLEADS_CONTACTS_UPDATED,
    KLIPSOLEADS_REGISTER_SUCCESS,
    KLIPSOLEADS_SET_DISCLAIMER_STATUS,
    KLIPSOLEADS_SET_SORTED_BY_COMPANY,
    KLIPSOLEADS_SET_SYNC_ONGOING,
    KLIPSOLEADS_RESET_LICENCE,
    LANG_CHANGED,
    PROFILE_CHANGED,
    UPDATE_PAGE_STATE,
    SEARCH_PERFORMED,
    CLEAR_SEARCH_RESULTS,
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    getPageCommonDefaultState,
    updateKeyboardState,
    togglePageAfterNavigation,
    langChanged,
    profileChanged,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import { KLIPSO_LEADS_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    hasAcceptedDisclaimer,
    getContext,
    getFormFields,
    splitFields,
    getContact,
    getContacts,
} from 'src/core/klipso-leads/KlipsoLeadsData';

import { parseListItems } from 'src/store/reducers/listCommons';
import { sortItems } from 'src/core/data-and-assets/Db';
import STATUS from 'src/store/fetchStatuses';


function getDefaultState(){
    let { readOnlyFields, editableFields } = splitFields(getFormFields());

    const sortedByCompany = false;

    return Object.assign({}, getPageCommonDefaultState(KLIPSO_LEADS_PAGE_KEY), {
        // additional default properties
        disclaimerAccepted: hasAcceptedDisclaimer(),
        context: getContext(),
        // formFields: getFormFields(),
        readOnlyFields,
        editableFields,
        ..._parseContacts(getContacts(), sortedByCompany),
        sortedByCompany: sortedByCompany,
        searchEnabled: false,
        formScreenEnabled: false,
        extendedMenuScreenEnabled: false,
        currentContact: null,
        syncOngoing: false,
    });
}

function _searchPerformed(state, action) {
    if (action.pageKey !== KLIPSO_LEADS_PAGE_KEY) {
        return state;
    }
    return updateObject(state, { searchResults: action.results });
}

function _clearSearchResults(state, action) {
    if (action.pageKey !== KLIPSO_LEADS_PAGE_KEY) {
        return state;
    }
    return updateObject(state, { searchResults: null });
}

function _parseContacts(contacts, sortedByCompany) {
    if (Array.isArray(contacts) !== true || contacts.length === 0) {
        return {
            totalContactsCount: 0,
            nonSyncContactsCount: 0,
            items: {},
        }
    }

    let _contacts = sortItems(contacts, DATA_TYPE_KLIPSO_LEADS_CONTACTS);

    return {
        totalContactsCount: contacts.length,
        nonSyncContactsCount: contacts.filter(contact => !contact.isSync).length,
        items: sortedByCompany
                ? parseListItems(
                    // status
                    { [DATA_TYPE_KLIPSO_LEADS_CONTACTS]: STATUS.FETCHED },
                    // items
                    { [DATA_TYPE_KLIPSO_LEADS_CONTACTS]: _contacts },
                    // page key
                    KLIPSO_LEADS_PAGE_KEY
                )
                // sorted by last modification date:
                : groupItems(_contacts, DATA_TYPE_KLIPSO_LEADS_CONTACTS),
    };
}

const _klSetDisclaimerStatus = (state, action) => updateObject(state, {
    disclaimerAccepted: action.value,
})

function _klRegisterSuccess(state, action) {

    let { readOnlyFields, editableFields } = splitFields(action.formFields);

    return updateObject(state, {
        context: action.context,
        // formFields: action.formFields,
        readOnlyFields,
        editableFields,
    });
}

function _klSetSortedByCompany(state, action) {
    if (state.sortedByCompany === action.value) {
        return state;
    }

    return updateObject(state, {
        sortedByCompany: action.value,
        ..._parseContacts(getContacts(), action.value),
    });
}

function _klContactsUpdated(state, action) {
    let newState = updateObject(state, _parseContacts(action.contacts, state.sortedByCompany));

    if (state.searchResults && Array.isArray(state.searchResults[DATA_TYPE_KLIPSO_LEADS_CONTACTS])) {
        // Refresh search results
        newState.searchResults = {
            [DATA_TYPE_KLIPSO_LEADS_CONTACTS]: (
                state.searchResults[DATA_TYPE_KLIPSO_LEADS_CONTACTS].map(contact => getContact(contact.code))
            ),
        };
    }
    return newState;
}

const _klContactsSetSyncOngoing = (state, action) => (
    updateObject(state, { syncOngoing: action.value })
)

function _updatePageState(state, action) {
    if (action.pageKey !== KLIPSO_LEADS_PAGE_KEY) {
        return state;
    }

    let newState = updatePageState(state, action, KLIPSO_LEADS_PAGE_KEY);

    if (typeof action.props.searchEnabled !== 'boolean') {
        newState.searchEnabled = false;
    }
    if (typeof action.props.formScreenEnabled !== 'boolean') {
        newState.formScreenEnabled = false;
    }
    if (typeof action.props.extendedMenuScreenEnabled !== 'boolean') {
        newState.extendedMenuScreenEnabled = false;
    }
    return newState;
}

const _klResetLicence = state => (
    updateObject(state, {
        context: null,
        readOnlyFields: null,
        editableFields: null,
        searchEnabled: false,
        formScreenEnabled: false,
        extendedMenuScreenEnabled: false,
    })
)

export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED  : return configJsonLoaded(state);
        case HAS_NAVIGATED       : return togglePageAfterNavigation(state, KLIPSO_LEADS_PAGE_KEY, action.pageKey);
        case LANG_CHANGED        : return langChanged(state, action);
        case PROFILE_CHANGED     : return profileChanged(state, action);
        case UPDATE_PAGE_STATE   : return _updatePageState(state, action, KLIPSO_LEADS_PAGE_KEY);
        case SEARCH_PERFORMED    : return _searchPerformed(state, action);
        case CLEAR_SEARCH_RESULTS: return _clearSearchResults(state, action);
        case KEYBOARD_TOGGLED    : return updateKeyboardState(state, action);

        // Klipso leads actions:
        case KLIPSOLEADS_SET_DISCLAIMER_STATUS: return _klSetDisclaimerStatus(state, action);
        case KLIPSOLEADS_REGISTER_SUCCESS     : return _klRegisterSuccess(state, action);
        case KLIPSOLEADS_SET_SORTED_BY_COMPANY: return _klSetSortedByCompany(state, action);
        case KLIPSOLEADS_CONTACTS_UPDATED     : return _klContactsUpdated(state, action);
        case KLIPSOLEADS_SET_SYNC_ONGOING     : return _klContactsSetSyncOngoing(state, action);
        case KLIPSOLEADS_RESET_LICENCE        : return _klResetLicence(state, action);

        default: return state;
    }
};
