
import config from 'data/config/config';

import { getBindedActions } from 'src/store/bindedActions';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { get as getLabels } from 'src/core/Lang';


/**
 * Display a notification depending on the web service error type.
 *
 * This common function reduces duplicated code in various components/modules.
 *
 * Feel free to declare new error types in WS_ERRORS, and implement them
 * in your web services call code and in the function below.
 *
 * @param  {string} error
 * @param  {string} level
 */
function showGenericWsErrorNotification(error, level=NotificationLevels.WARNING) {
    switch (error) {

        case WS_ERRORS.NETWORK:
            getBindedActions().showNotification({
                title  : getLabels().common.noNetworkTitle,
                message: getLabels().common.noNetwork,
                level  : level,
            });
            break;

        case WS_ERRORS.AUTH:
            getBindedActions().showNotification({
                message: getLabels().common.invalidAuth,
                level  : level,
            });
            break;

        case WS_ERRORS.INVALID_LICENCE:
            getBindedActions().showNotification({
                message: getLabels().klipsoLeads.invalidLicence,
                level  : level,
            });
            break;

        default:
            getBindedActions().showNotification({
                message: getLabels().common.error,
                level  : level,
            });
    }
}

export default showGenericWsErrorNotification;

if (config.ENV === 'dev') {
    global.showGenericWsErrorNotification = showGenericWsErrorNotification;
}