
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { KLIPSO_LEADS_PAGE_KEY } from 'src/pages/pagesKeys';

import DisclaimerScreen from './disclaimer-screen/DisclaimerScreen';
import LicenceScreen from './licence-screen/LicenceScreen';
import MainScreen from './main-screen/MainScreen';
import FormScreen from './form-screen/FormScreen';
import ExtendedMenuScreen from './extended-menu-screen/ExtendedMenuScreen';


import * as actions from 'src/store/actions';

import './KlipsoLeadsPage.scss';

const LOG_PREF = '[KlipsoLeadsPage] ';


class KlipsoLeadsPage extends React.Component {

    state = {}

    pageKey = KLIPSO_LEADS_PAGE_KEY

    getPageTitle = () => this.props.labels.klipsoLeads.title

    /**
     * Update the string visible in browser's tab/history/bookmarks
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    isPageVisible = () => this.props.isVisible

    render() {
        console.log(LOG_PREF+'render');
        if (!this.props.isVisible) {
            return null;
        }

        if (!this.props.disclaimerAccepted) {
            return (
                <DisclaimerScreen
                    labels={this.props.labels}
                    actions={this.props.actions}
                />
            );
        }
        if (!this.props.context || !this.props.context.licence) {
            return (
                <LicenceScreen
                    userName={this.props.context ? this.props.context.userName : ''}
                    labels={this.props.labels}
                    actions={this.props.actions}
                    keyboardHeight={this.props.keyboardHeight}
                />
            );
        }
        if (this.props.formScreenEnabled) {
            return (
                <FormScreen
                    context={this.props.context}
                    readOnlyFields={this.props.readOnlyFields}
                    editableFields={this.props.editableFields}
                    contact={this.props.currentContact}
                    labels={this.props.labels}
                    actions={this.props.actions}
                />
            );
        }
        if (this.props.extendedMenuScreenEnabled) {
            return (
                <ExtendedMenuScreen
                    context={this.props.context}
                    totalContactsCount={this.props.totalContactsCount}
                    nonSyncContactsCount={this.props.nonSyncContactsCount}
                    labels={this.props.labels}
                    actions={this.props.actions}
                />
            );
        }

        return (
            <MainScreen
                items={this.props.items}
                nonSyncContactsCount={this.props.nonSyncContactsCount}
                totalContactsCount={this.props.totalContactsCount}
                syncOngoing={this.props.syncOngoing}
                context={this.props.context}
                sortedByCompany={this.props.sortedByCompany}
                searchEnabled={this.props.searchEnabled}
                searchResults={this.props.searchResults}
                keyboardHeight={this.props.keyboardHeight}
                labels={this.props.labels}
                actions={this.props.actions}
            />
        );
    }
}

KlipsoLeadsPage.propTypes = {
    disclaimerAccepted: PropTypes.bool.isRequired,
    context: PropTypes.object, // contains licence, userName, exhibitor, event, checkpointId
    // formFields: PropTypes.array,
    readOnlyFields: PropTypes.array,
    editableFields: PropTypes.array,
    items: PropTypes.object.isRequired,
    nonSyncContactsCount: PropTypes.number.isRequired,
    totalContactsCount: PropTypes.number.isRequired,
    sortedByCompany: PropTypes.bool.isRequired,
    searchEnabled: PropTypes.bool.isRequired,
    searchResults: PropTypes.object,
    formScreenEnabled: PropTypes.bool.isRequired,
    extendedMenuScreenEnabled: PropTypes.bool.isRequired,
    currentContact: PropTypes.object,
    syncOngoing: PropTypes.bool.isRequired,
    // Common page props
    keyboardHeight    : PropTypes.number,
    queryString       : PropTypes.string.isRequired,
    menuOptions       : PropTypes.object.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    adConfig          : PropTypes.object,
    isLocationEnabled : PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => state[KLIPSO_LEADS_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KlipsoLeadsPage);
