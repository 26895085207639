
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import CTAButton from 'src/components/cta-button/CTAButton';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import renderSection from './renderSection';
import renderAdvancedSearchFormWithResults from './renderAdvancedSearchFormWithResults';

import './SearchForm.scss';

const LOG_PREF = '[SearchForm] ';

const DEFAULT_VISIBLE_BUTTON_INDEX = 0;

const _config = config.SEARCH_TAIGA;


/**
 * Parse a configuration section to detect fields
 * @param  {object} sections
 * @return {object} fields
 */
function getFields(sections) {
    let parsedFields = {};

    sections.forEach(section => {

        // special case: Buttons
        (section.buttons || []).forEach(button => {
            switch (button.type) {
                case 'TOGGLE':
                    button.content.forEach(btnConf => {
                        let innerFieldName = Object.keys(btnConf.field)[0];
                        parsedFields[innerFieldName] = btnConf.field[innerFieldName];
                    });
                    break;

                default:
                    console.error(LOG_PREF+'Button type \''+button.type+'\' is not managed yet');
            }
        });

        // Regular fields
        let fieldNames = Object.keys(section.fields || {});
        fieldNames.forEach(fieldName => {
            parsedFields[fieldName] = section.fields[fieldName];
        });
    });

    return parsedFields;
}

/**
 * Parse a configuration section to detect buttons of type TOGGLE
 * @param  {object} sections
 * @return {array} toggleButtons
 */
function getToggleButtons(sections) {
    let toggleButtons = [];
    sections.forEach(section => {
        if (section.buttons && Array.isArray(section.buttons)) {

            section.buttons.forEach(function(buttonConfig) {
                switch (buttonConfig.type) {
                    case 'TOGGLE':
                        if (!buttonConfig.key) {
                            console.error(LOG_PREF+'Toggle button configuration must have a unique \'key\' property');
                        } else {
                            toggleButtons.push(buttonConfig);
                        }
                        break;

                    default: // noop
                }
            });
        }
    });
    return toggleButtons;
}


function getCurrentFormValue(fields, fieldsHooks, fieldName) {
    let currentFormValue = fieldsHooks[fieldName].currentFormValue;

    let fieldConfig = fields[fieldName];

    switch (fieldConfig.type) {
        case 'text':
            return !currentFormValue ? null : currentFormValue;

        case 'checkbox':
            if (currentFormValue.length === 0) {
                // checkboxes checked: none
                return null;
            }
            return currentFormValue;

        default:
            console.error(LOG_PREF+'Field type \''+fieldConfig.type+'\' is not managed yet');
    }
}


function SearchForm({
    isAdvanced=false,
    toggleSearchMode,
    search,
    isSearchOngoing,
    hasResults,
    actions,
    labels,
}) {

    let simpleSearchFields = getFields(_config.SIMPLE_SECTIONS),
        advancedSearchFields = getFields(_config.ADVANCED_SECTIONS);

    // Parsed fields for simple + advanced
    let fields = {
        ...simpleSearchFields,
        ...advancedSearchFields,
    };
    // console.log(LOG_PREF+'parsed fields:', fields);


    // Initiate hooks
    let fieldsHooks = {};
    Object.keys(fields).forEach(fieldName => {
        let defaultValue;

        let fieldType = fields[fieldName].type;
        switch (fieldType) {
            case 'text': defaultValue = ''; break;

            case 'checkbox':
                if (fields[fieldName].multiple) {
                    defaultValue = [];
                } else {
                    defaultValue = '';
                }
                break;

            default: console.error(LOG_PREF+'TODO: manage field type \''+fieldType+'\'');
        }

        let [ currentFormValue, setValue ] = useState(defaultValue);
        fieldsHooks[fieldName] = {
            currentFormValue,
            setValue
        };
    });
    // console.log(LOG_PREF+'hooks initialized:', fieldsHooks);


    function doesAVisibleFieldIncludesSearchButton() {
        let fields = !isAdvanced ? simpleSearchFields : advancedSearchFields;
        let fieldNames = Object.keys(fields);
        for (let i=0; i<fieldNames.length; i++) {
            let fieldName = fieldNames[i];
            if (fieldsVisiblity[fieldName] === true) {
                if (fields[fieldName].hasSearchButton) {
                    return true;
                }
            }
        }
        return false;
    }


    // Initiate toggle buttons visibility hooks
    let toggleButtonsConfigs = [].concat(getToggleButtons(_config.SIMPLE_SECTIONS))
                            .concat(getToggleButtons(_config.ADVANCED_SECTIONS));

    let toggleButtonsVisiblityHooks = {};
    toggleButtonsConfigs.forEach(function(tbc) {
        let visibleIndex = typeof tbc.defaultVisibleButtonIndex === 'number'
                            ? tbc.defaultVisibleButtonIndex
                            : DEFAULT_VISIBLE_BUTTON_INDEX;

        toggleButtonsVisiblityHooks[tbc.key] = useState(visibleIndex);
    });


    // Manage fields visibility to know what data
    // must be attached to webservice call
    let fieldsVisiblity = {};
    function setFieldVisiblity(fieldName, value) {
        fieldsVisiblity[fieldName] = value;
    }
    function setAllFieldsNotVisible() {
        Object.keys(fields).forEach(fieldName => {
            setFieldVisiblity(fieldName, false);
        });
    }
    setAllFieldsNotVisible();


    function performSearch() {
        let searchFields = {};

        Object.keys(fieldsVisiblity).forEach(function(fieldName) {
            if (fieldsVisiblity[fieldName] === true) {
                let value = getCurrentFormValue(fields, fieldsHooks, fieldName);
                if (value !== null) {
                    searchFields[fieldName] = value;
                }
            }
        });

        console.log(LOG_PREF+'search fields: ', searchFields);

        if (Object.keys(searchFields).length === 0) {
            actions.showNotification({
                message: labels.searchTaiga.emptyParameters,
                level: NotificationLevels.WARNING,
            });
            return;
        }

        // perform search
        search(searchFields);
    }

    function goToSimple() {
        toggleSearchMode({
            isAdvanced: false,
        });
    }

    function goToAdvanced() {
        toggleSearchMode({
            isAdvanced: true,
        });
    }

    // Results list displayed
    if (hasResults && isAdvanced) {
        return renderAdvancedSearchFormWithResults({
            labels,
            actions,
            goToSimple,
        });
    }

    // render
    let sections = !isAdvanced
                    ? _config.SIMPLE_SECTIONS
                    : _config.ADVANCED_SECTIONS;
    return (
        <div id="search-form" className="content-font">
            { sections.map((section, index) => (
                renderSection({
                    index,
                    section,
                    fieldsHooks,
                    performSearch,
                    isSearchOngoing,
                    labels,
                    setFieldVisiblity,
                    toggleButtonsVisiblityHooks,
                })
              ))
            }

            { doesAVisibleFieldIncludesSearchButton() !== true &&
                <CTAButton
                    id="sfs-search-btn"
                    label={labels.searchTaiga.search}
                    action={performSearch}
                    isEnabled={!isSearchOngoing}
                    />
            }

            { !isAdvanced
                ? <CTAButton
                    id="sfs-go-to-advanced-btn"
                    label={labels.searchTaiga.goToAdvancedSearch}
                    action={goToAdvanced}
                    />
                : <CTAButton
                    id="sfs-go-to-simple-btn"
                    label={labels.searchTaiga.goToSimpleSearch}
                    action={goToSimple}
                    />
            }
        </div>
    );
}

SearchForm.propTypes = {
    isAdvanced: PropTypes.bool,
    toggleSearchMode: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    isSearchOngoing: PropTypes.bool.isRequired,
    hasResults: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

export default SearchForm;
