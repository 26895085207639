
import React from 'react';
import PropTypes from 'prop-types';

import './DummyKeyboardPlaceholder.scss';

/**
 * NB: there is a store action `KEYBOARD_TOGGLED` that indicates:
 *  - keyboard visibility
 *  - keyboard height
 *
 * An easy way to resize the height of displayed content is to show this
 * 'dummy keyboard placeholder' at the bottom of your content, and give it
 * keyboard height value. It will be displayedd behind the keyboard, with
 * the same height, so your content won't be hidden anymore.
 *
 * What is needed is to have: display:flex + flex-direction:column on the parent
 * and set your content scrollable.
 */

function DummyKeyboardPlaceholder({ keyboardHeight }) {

    return (
        <div
            className="dummy-keyboard-placeholder"
            style={{ flexBasis: keyboardHeight || 0 }}
        />
    );
}

DummyKeyboardPlaceholder.propTypes = {
    keyboardHeight: PropTypes.number,
};

export default DummyKeyboardPlaceholder;