var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = '';
var NODE_WS_BAKEND_DOMAIN = '';
var KLIPSO_LEADS_WS_ROOT = 'http://www.e-registration.net/WebServices/WS_Gaia_Mobile/GaiaMobile';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'blockchaincongress-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/palexpo/blockchaincongress/blockchaincongress2020/blockchaincongress-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/palexpo/blockchaincongress/blockchaincongress2020/blockchaincongress-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-152561747-1',
  projectId: '',
  // FCM sender id
  appId: '',
  // pushwoosh appid
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  LOGIN: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs',
    forgottenPasswordURL: 'https://blockchain2020.site.calypso-event.net/testSDA/authentification/mot-de-passe-oublie.htm'
  },
  USER_DATA: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs'
  },
  PARTICIPANTS: {
    WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs'
  },
  KLIPSO_LEADS: {
    CHECK_LICENCE_WS_URL: KLIPSO_LEADS_WS_ROOT + '/AuthenticateExhibitorAppByCAB',
    REGISTER_CHECKPOINT_WS_URL: KLIPSO_LEADS_WS_ROOT + '/RegisterCheckPoint',
    GET_FORM_FIELDS_WS_URL: KLIPSO_LEADS_WS_ROOT + '/GetFormFields',
    SAVE_WS_URL: KLIPSO_LEADS_WS_ROOT + '/SaveVisitorRelationList'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([KLIPSO_LEADS_WS_ROOT])
});