
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import Header from '../header/Header';
import Content from '../content/Content';
import Footer from '../footer/Footer';
import OverlayLoader from '../overlay-loader/OverlayLoader';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';

import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import { checkLicence, registerCheckpoint, TEST_DATA } from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';
import { getMobileId, getMobileModel } from 'src/core/cordova/CordovaHelper';
import { scan } from 'src/core/cordova/BarCodeScanner';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import './LicenceScreen.scss';

global.klMockFormFields = false;


function LicenceScreen({ userName, labels, actions, keyboardHeight }) {

    let mobileId = getMobileId();

    let [ _licence, setLicence ] = useState('');
    let [ _userName, setUserName ] = useState(userName || getMobileModel() || '');
    let [ requestOngoing, setRequestOngoing ] = useState(false);

    function onChangeLicence(event) {
        setLicence(event.target.value);
    }

    function onChangeUserName(event) {
        setUserName(event.target.value);
    }

    async function scanLicence() {
        // At this point, only CAB can be scanned
        // because FormFields are needed to parse a crypted badge
        try {
            let result = await scan(config.KLIPSO_LEADS.LICENCE_SCAN_ACCEPTED_FORMATS);

            if (result) {
                setLicence(result.text && result.text.length ? result.text : '');
            }
        } catch (e) {
            actions.showNotification({
                message: labels.klipsoLeads.scanDenied,
                level: NotificationLevels.WARNING,
            });
        }
    }

    function detectEnter(e) {
        if (e.key === 'Enter') {
            submit();
        }
    }

    async function submit() {
        if (requestOngoing) {
            return;
        }
        if (!_licence || !_userName) {
            actions.showNotification({
                message: labels.klipsoLeads.emptyLicenceOrUsername,
            });
            return;
        }

        setRequestOngoing(true);

        try {
            let licenceSuccessResponse = await checkLicence(_licence, mobileId);

            let registerSuccessResponse = await registerCheckpoint(
                                                    licenceSuccessResponse.InstanceName,
                                                    licenceSuccessResponse.ExhibitorId,
                                                    _userName,
                                                    mobileId
                                                );

            setRequestOngoing(false);
            actions.klipsoLeadsRegisterSuccess({
                licence: _licence,
                userName: _userName,
                instance: licenceSuccessResponse.InstanceName,
                exhibitorId: licenceSuccessResponse.ExhibitorId,
                exhibitorName: licenceSuccessResponse.ExhibitorName,
                eventDateBegin: licenceSuccessResponse.EventDateBegin,
                eventDateEnd: licenceSuccessResponse.EventDateEnd,
                eventId: licenceSuccessResponse.EventId,
                eventName: licenceSuccessResponse.EventName,
                checkPointId: registerSuccessResponse.CheckPointId,
                formFields: global.klMockFormFields ? TEST_DATA.FormFields :registerSuccessResponse.FormFields,
            });

        } catch(e) {
            setRequestOngoing(false);
            showGenericWsErrorNotification(e);
        }
    }

    return (
        <div id="kl-ls">
            <Header
                labels={labels}
                onCloseClick={() => actions.navigateToHome()}
                />

            <Content>
                { requestOngoing && <OverlayLoader position="bottom" /> }

                <form onSubmit={submit}>
                    <div className="kl-ls-row">
                        <div>{labels.klipsoLeads.fields.licence}</div>
                        <div className="kl-ls-field">
                            <input
                                type="text"
                                value={_licence}
                                onChange={onChangeLicence}
                                onKeyUp={detectEnter}
                            />
                            <div
                                className="kl-ls-scan-btn"
                                onClick={scanLicence}
                            >
                                <span className="fad fa-qrcode" />
                            </div>
                        </div>
                    </div>

                    <div className="kl-ls-row">
                        <div>{labels.klipsoLeads.fields.userName}</div>
                        <div className="kl-ls-field">
                            <input
                                type="text"
                                value={_userName}
                                onChange={onChangeUserName}
                                onKeyUp={detectEnter}
                            />
                        </div>
                    </div>
                </form>
            </Content>

            <Footer
                onClick={submit}
                isTextBtnMode={true}
            >
                <div>{labels.klipsoLeads.validate}</div>
            </Footer>

            <DummyKeyboardPlaceholder keyboardHeight={keyboardHeight} />
        </div>
    );
}

LicenceScreen.propTypes = {
    userName: PropTypes.string,
    labels  : PropTypes.object.isRequired,
    actions : PropTypes.object.isRequired,
    keyboardHeight: PropTypes.number,
}

export default LicenceScreen;