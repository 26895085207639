
// libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';

// conf
import { get as getMenuConfig, MENU_OPTIONS } from 'data/config/menuConfig';

// menu items
import { TYPES } from 'src/core/util/ConfigItemFactory';
import GenericMenuItem from './GenericMenuItem';
import getAppVersionConfigEntry from './getAppVersionConfigEntry';
import ToggleLocationItem from './ToggleLocationItem';
import TogglePMRItem from './TogglePMRItem';


import './Menu.scss';


class Menu extends PureComponent {

    onOpen = () => {
        this.props.actions.openMenu(this.props.associatedPageKey);
    }

    onClose = () => {
        this.props.actions.closeMenu(this.props.associatedPageKey);
    }

    /*getMenuItems() {

        // Get items configuration, depending on profile, current page, etc
        const confEntries = ;

        let menuItems;
        if (!confEntries) {
            menuItems = [];
        } else {
            menuItems = confEntries.map((entry, index) => {

            });
        }

        menuItems.push(
        );

        return menuItems;
    }*/

    renderList = () => (
        <div
          //className={classes.list}
          role="presentation"
          onClick={this.onClose}
          onKeyDown={this.onClose}
        >
          <List className="menu-items-container">

            { getMenuConfig(this.props.profile,
                            this.props.associatedPageKey,
                            this.props.actions,
                            this.props.labels,
                            this.props.adConfig,
                            this.props.twoColumns).map((configEntry, index) => {

                switch (configEntry.type) {

                    case TYPES.TOGGLE_LOCATION:
                        return <ToggleLocationItem
                                    key={index}
                                    entry={configEntry}
                                    isLocationReady={this.props.isLocationEnabled} />

                    case TYPES.TOGGLE_PMR:
                        return <TogglePMRItem
                                    key={index}
                                    entry={configEntry} />

                    default:
                        return <GenericMenuItem
                                    key={index}
                                    entry={configEntry}
                                    associatedPageKey={this.props.associatedPageKey}
                                    closeMenu={this.props.actions.closeMenu} />
                }
            }) }

            <GenericMenuItem
                key="version"
                entry={getAppVersionConfigEntry(this.props.labels)}
                associatedPageKey={this.props.associatedPageKey}
                closeMenu={this.props.actions.closeMenu}
                isDisabled={true} />

          </List>

        </div>
    )

    render() {
        const menuOptions = Object.assign({}, MENU_OPTIONS, this.props.options);

        if (typeof this.props.swipeable === 'boolean') {
            menuOptions.disableSwipeToOpen = !this.props.swipeable;
        }

        return (
            // https://material-ui.com/api/swipeable-drawer/#swipeabledrawer-api
            <SwipeableDrawer
                onOpen={this.onOpen}
                onClose={this.onClose}
                {...menuOptions}
                >

                { this.renderList() }
            </SwipeableDrawer>
        );
    }

}

Menu.propTypes = {
    options : PropTypes.object.isRequired,
    associatedPageKey: PropTypes.string.isRequired,
    isLocationEnabled: PropTypes.bool,
    swipeable: PropTypes.bool,
    labels  : PropTypes.object.isRequired,
    actions : PropTypes.object.isRequired,
    profile : PropTypes.string,
};

export default Menu;
