
const TAIGA = 'taiga';

const NODEBACKEND = 'nodebackend';

// Can't use ES6 export
module.exports = {
    TAIGA,
    NODEBACKEND,
};
