
import moment from 'moment';

import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { WS_ERRORS } from '../WS_ERRORS';
import { getMobileId } from 'src/core/cordova/CordovaHelper';

import {
    FIELD_TYPES,
    getContext,
    getFormFields as getKnownFormFields,
    splitFields,
    getContacts,
    saveContacts,
} from 'src/core/klipso-leads/KlipsoLeadsData';

const LOG_PREF = '[KlipsoLeadsWS] ';


// DOC: https://mobile-spot.atlassian.net/wiki/spaces/FR/pages/107806721/In-App+Klipso+Leads

export const TEST_DATA = {
    licence: '724VYAAAMP',
    userName: 'dev',
    mobileId: 'c35dc8c7d38ae1e1',
    InstanceName: 'Leni',
    ExhibitorId: "ef325617-82a7-33cf-cc42-238785d96901",
    ExhibitorName: "MOBILE SPOT",
    EventDateBegin: "15/04/2017 00:00:00",
    EventDateEnd: "31/12/2020 00:00:00",
    EventId: "761502c1-23e4-e411-b9c7-005056a13c8a",
    EventName: "Master PE Gratuit",
    CheckPointId: "d3ae1fd7-063c-e911-80f9-005056ae1179",
    FormFields: [
        {"CABPosition":1,"Code":"Prenom","Codifications":[],"DefaultValue":"","Id":"Prenom","IsMandatory":false,"IsReadOnly":false,"Label":"Prénom","Nature":0,"PrintPosition":1,"Type":0},
        {"CABPosition":2,"Code":"Nom","Codifications":[],"DefaultValue":"","Id":"Nom","IsMandatory":false,"IsReadOnly":false,"Label":"Nom","Nature":0,"PrintPosition":2,"Type":0},
        {"CABPosition":3,"Code":"Societe","Codifications":[],"DefaultValue":"","Id":"Societe","IsMandatory":false,"IsReadOnly":false,"Label":"Société","Nature":0,"PrintPosition":3,"Type":0},
        {"CABPosition":4,"Code":"Email","Codifications":[],"DefaultValue":"","Id":"Email","IsMandatory":false,"IsReadOnly":false,"Label":"Email","Nature":0,"PrintPosition":4,"Type":0},
        {"CABPosition":null,"Code":"Date","Codifications":[],"DefaultValue":"","Id":"AutreSociete","IsMandatory":false,"IsReadOnly":false,"Label":"Date","Nature":3,"PrintPosition":null,"Type":2},
        {"CABPosition":null,"Code":"AutreEmail","Codifications":[],"DefaultValue":"","Id":"AutreEmail","IsMandatory":false,"IsReadOnly":false,"Label":"AutreEmail","Nature":3,"PrintPosition":null,"Type":8},
        {"CABPosition":null,"Code":"Boolean","Codifications":[],"DefaultValue":false,"Id":"Commentaire","IsMandatory":false,"IsReadOnly":false,"Label":"Boolean","Nature":3,"PrintPosition":null,"Type":4},
        {"CABPosition":null,"Code":"Nombre","Codifications":[],"DefaultValue":"","Id":"Commentaire","IsMandatory":false,"IsReadOnly":false,"Label":"Nombre","Nature":3,"PrintPosition":null,"Type":3},
    ],
};

const TEST_DATA_BADGES = [
    'WXJFEDHGN&Alexandre#CHRUN#LENI#achrun@leni.fr#', // badge 1
    'WPQLP8FND&GAEL#FERREROCOLUN#EXHIBIS#ojoly@leni.fr#', // badge 2
    'WKG6W4BGS&Test#TEST PAYANT#LENI#eperez@leni.fr#', // badge 3
    'W42QVVJQY&Machat#HAIFA#LENI#hmachat@leni.fr#', // badge 4
    'WEVXATH3X&PRENOM#NOM#EXHIBIS#ojoly@leni.fr#', // badge 5
    'HW744RQ86H&Ribeiro#CHRISTINE#VACATAIRE#ducat.c@live.fr#', // salon infirmier

    // Generated dummy data:
    'QUI4OOF3D&Lyons#Potts#KIOSK#XOGGLE#celestedodson@xoggle.com#',
    'GEIXI4IAN&Duran#Thompson#ROTODYNE#duranthompson@rotodyne.com#',
    'EIGH5AHG7&Lesa#Yang#MAGMINA#lesayang@magmina.com#',
    'HAI9WEEZO&Holly#Pate#DAYCORE#hollypate@daycore.com#',
    'OHPHIES3J&Diann#Raymond#DIGIQUE#diannraymond@digique.com#',
    'AHR6EEY3E&Townsend#Parker#EGYPTO#townsendparker@egypto.com#',
    'AHJ7MEECH&Cannon#Mosley#ZYTRAX#cannonmosley@zytrax.com#',
    'TU7PHIENI&Carmen#David#COMVENE#carmendavid@comvene.com#',
    'EYA3EAPHE&Lindsay#Wise#URBANSHEE#lindsaywise@urbanshee.com#',
    'BIE7KA3UI&Susana#Bishop#MAXIMIND#susanabishop@maximind.com#',
    'NEJEI7AIY&Heidi#Brown#GYNK#heidibrown@gynk.com#',
    'AIGH3VIPH&Powers#Roman#ZAJ#powersroman@zaj.com#',
    'UZEICAH8I&Blanca#Mcintosh#INSURETY#blancamcintosh@insurety.com#',
    'CIW6IMEI5&Abigail#Campos#QIMONK#abigailcampos@qimonk.com#',
    'TOOF7OOWU&Clark#Alvarez#RODEOLOGY#clarkalvarez@rodeology.com#',
    'IECH2EIVA&Kerr#Park#IZZBY#kerrpark@izzby.com#',
    'SIE9MU1SH&Butler#Whitfield#EARBANG#butlerwhitfield@earbang.com#',
    'IED9GOHZ4&Bullock#Velez#PORTICA#bullockvelez@portica.com#',
    'VO5VAHVU3&Gay#Kline#OTHERWAY#gaykline@otherway.com#',
    'AEPEEYAH7&Le#Howell#HIVEDOM#lehowell@hivedom.com#',
    'UQU9OHHEE&Boone#Brennan#GEEKULAR#boonebrennan@geekular.com#',
    'OVE0ACAHR&Helen#Curry#CINCYR#helencurry@cincyr.com#',
    'EU4AHVAIB&Caitlin#Nichols#ZANITY#caitlinnichols@zanity.com#',
    'IEK9IEMO8&Golden#Chambers#DELPHIDE#goldenchambers@delphide.com#',
    'XEIM8AZ0H&Maura#Simon#KYAGURU#maurasimon@kyaguru.com#',
    'WAI8EEYIE&Stewart#Adkins#TALKOLA#stewartadkins@talkola.com#',
    'WOH8PHIEY&Garrison#Turner#GEEKOSIS#garrisonturner@geekosis.com#',
    'EEV9FAESH&Donaldson#Farley#GEEKKO#donaldsonfarley@geekko.com#',
    'DIESH5XEE&Gutierrez#Nash#NEWCUBE#gutierreznash@newcube.com#',
    'FO7EENGAE&Allie#Moses#ORBIFLEX#alliemoses@orbiflex.com#',
    'EIXOHB9OU&Kirsten#Gilliam#EXOTERIC#kirstengilliam@exoteric.com#',
    'ZEN1OHB8M&Stokes#Beard#SIGNIDYNE#stokesbeard@signidyne.com#',
    'FEI5CEI6U&Larson#Ochoa#SKYPLEX#larsonochoa@skyplex.com#',
    'EEFAHYO8Z&Kane#Whitley#ACCRUEX#kanewhitley@accruex.com#',
    'EETHEMA7M&Harriett#Arnold#MARVANE#harriettarnold@marvane.com#',
    'AJAZAE1XO&Luna#Kirkland#COMSTAR#lunakirkland@comstar.com#',
    'NAEG3OHH1&Kristine#Benjamin#SNIPS#kristinebenjamin@snips.com#',
    'EERAT8HAH&Kirby#Decker#FLUM#kirbydecker@flum.com#',
    'THEW4AELU&Villarreal#Sears#SPACEWAX#villarrealsears@spacewax.com#',
    'AID6EISH5&Patti#Osborn#ZENSUS#pattiosborn@zensus.com#',
    'JIIGA8ZEI&Teresa#Blackwell#RUBADUB#teresablackwell@rubadub.com#',
    'AEPEI3IEZ&Joann#Crane#PLASTO#joanncrane@plasto.com#',
    'PHAJAEH2K&Spencer#Terrell#GOLOGY#spencerterrell@gology.com#',
    'AHSHAH6PH&May#Avila#PLASMOS#mayavila@plasmos.com#',
    'YAEW8YEAJ&Carolina#Watson#MOLTONIC#carolinawatson@moltonic.com#',
    'GAA0DE2JO&Francine#Sloan#SILODYNE#francinesloan@silodyne.com#',
    'VAINGOHY7&Debbie#Glenn#OVERPLEX#debbieglenn@overplex.com#',
    'AEM5AIHIT&Norris#Sandoval#TELEQUIET#norrissandoval@telequiet.com#',
    'EIPOHT0HU&Stevenson#Floyd#RAMJOB#stevensonfloyd@ramjob.com#',
    'EUM3OOGH7&Richmond#Benson#QUALITERN#richmondbenson@qualitern.com#',
    'ED6ROHTH4&Barker#Zimmerman#XYMONK#barkerzimmerman@xymonk.com#',
    'LAE7MEIKE&Williams#Schneider#STROZEN#williamsschneider@strozen.com#',
    'XO0OOSH2E&Whitaker#Valenzuela#HOPELI#whitakervalenzuela@hopeli.com#',
    'EQUOHTH6P&Tonya#Mercer#DEVILTOE#tonyamercer@deviltoe.com#',
    'UUK7TOH5A&Jones#Wade#ELITA#joneswade@elita.com#',
    'UQU9WA8EE&Pam#Hyde#NETPLAX#pamhyde@netplax.com#',
    'UCEE3NAIL&Cole#Parks#AQUASSEUR#coleparks@aquasseur.com#',
    'PHEELAI2I&Sabrina#Oneil#CYCLONICA#sabrinaoneil@cyclonica.com#',
    'OY2HAAVAH&Bennett#Benton#COSMOSIS#bennettbenton@cosmosis.com#',
    'OU8VEE7TU&Rollins#Paul#COMTOUR#rollinspaul@comtour.com#',
    'OEYEIX6AA&Stacey#Davenport#ANIXANG#staceydavenport@anixang.com#',
    'AIWAI2SHE&Murphy#Larsen#DIGINETIC#murphylarsen@diginetic.com#',
    'XUHEH8FIE&Michele#Gilmore#SHOPABOUT#michelegilmore@shopabout.com#',
    'BAI3RAH8O&June#Navarro#SPRINGBEE#junenavarro@springbee.com#',
    'CHEW6SHO3&Genevieve#Barber#ZIPAK#genevievebarber@zipak.com#',
    'REEP6FEI0&Hampton#Hewitt#VENDBLEND#hamptonhewitt@vendblend.com#',
    'IEW2YEIVA&Jeannette#Hensley#QIMONK#jeannettehensley@qimonk.com#',
    'POHL8WAHK&Ballard#Bridges#PHOLIO#ballardbridges@pholio.com#',
    'DIU1AHGH8&Ida#Leon#ARCTIQ#idaleon@arctiq.com#',
    'OOGHEIX3P&Hayden#Wooten#ULTRASURE#haydenwooten@ultrasure.com#',
    'EIZ3UCH4A&Lesa#Joyce#PAPRIKUT#lesajoyce@paprikut.com#',
    'ENEAFUG1R&Shelly#Wright#ISODRIVE#shellywright@isodrive.com#',
    'OHQU0EEPH&Deann#Hoffman#LETPRO#deannhoffman@letpro.com#',
    'OU1PUGH5T&Janell#Doyle#LIQUICOM#janelldoyle@liquicom.com#',
    'APE4EIGHE&Diaz#Scott#MINGA#diazscott@minga.com#',
    'ITU2SHAI9&Rios#Gallagher#PULZE#riosgallagher@pulze.com#',
    'JIE5AUXAE&Lindsay#Mejia#CORPORANA#lindsaymejia@corporana.com#',
    'AUGH6AHZU&Karyn#Silva#VIRVA#karynsilva@virva.com#',
    'BEEBAEJ0S&Lamb#Humphrey#ZOLARITY#lambhumphrey@zolarity.com#',
    'CHIET3SHU&Sutton#Hendricks#ORBAXTER#suttonhendricks@orbaxter.com#',
    'IZAHC4SOH&Stein#Underwood#CEDWARD#steinunderwood@cedward.com#',
    'OETH4EECH&Osborn#Clemons#KATAKANA#osbornclemons@katakana.com#',
    'VEFOOVO9T&Kara#Baird#ULTRASURE#karabaird@ultrasure.com#',
    'KE1OHSHOH&Adrian#Parrish#STEELTAB#adrianparrish@steeltab.com#',
    'TOOHU1QUE&Lenore#Contreras#INSOURCE#lenorecontreras@insource.com#',
    'IENG7IEKI&Kinney#Rodriquez#RUBADUB#kinneyrodriquez@rubadub.com#',
    'SHU5AHD2S&Gwendolyn#Carrillo#ORBIFLEX#gwendolyncarrillo@orbiflex.com#',
    'EITH5OHGH&Lawrence#Frank#FUTURIS#lawrencefrank@futuris.com#',
    'IICHEE6BO&Boyd#Harding#CORMORAN#boydharding@cormoran.com#',
    'ZEIT3CHIR&Reid#Walsh#COMTRAK#reidwalsh@comtrak.com#',
    'GA6QUAPH4&Morton#Randolph#FORTEAN#mortonrandolph@fortean.com#',
    'CAE6TEI7H&Harrington#Rosales#INSURESYS#harringtonrosales@insuresys.com#',
    'OP1IETIUF&Freda#Sanchez#MAGMINA#fredasanchez@magmina.com#',
    'VOIZ8ZIE1&Alba#Christensen#VALPREAL#albachristensen@valpreal.com#',
    'ENGEEPHE0&Jordan#Wells#GEEKKO#jordanwells@geekko.com#',
    'URAH4OHPE&Raymond#Barrera#UTARA#raymondbarrera@utara.com#',
    'ASAB0JEI3&Florence#Dorsey#NAMEGEN#florencedorsey@namegen.com#',
    'OOHAEX2JO&Hernandez#Johns#ZINCA#hernandezjohns@zinca.com#',
    'OOP2EEXEI&Cristina#Phillips#SUNCLIPSE#cristinaphillips@sunclipse.com#',
    'IQUIEB4AM&Lane#Wood#OTHERWAY#lanewood@otherway.com#',
    'AEWEI0AHS&Coleen#Burnett#GEOFARM#coleenburnett@geofarm.com#',
    'SAI5HEI9G&Ellen#Poole#NETPLAX#ellenpoole@netplax.com#',
    'EOM4APH2U&Lesley#Hester#QNEKT#lesleyhester@qnekt.com#',
    'XOOR2CAIV&Tina#Abbott#TETAK#tinaabbott@tetak.com#',
    'WOOBE1MAE&Cohen#Skinner#ROCKYARD#cohenskinner@rockyard.com#',
    'AECHULE8E&Roman#Case#EXTREMO#romancase@extremo.com#',
    'GAIVEI4RA&Maxine#Kirk#EXOSPEED#maxinekirk@exospeed.com#',
    'POO6AEC6U&Deann#Mcguire#ZENSURE#deannmcguire@zensure.com#',
    'IV1KAGHAI&Deidre#Bartlett#ORBIXTAR#deidrebartlett@orbixtar.com#',
    'IOWAO3WAI&Shelly#Sharp#ZIDOX#shellysharp@zidox.com#',
    'BAIT2AIR4&Allie#Howell#EZENT#alliehowell@ezent.com#',
    'EISHOO7SU&Alston#Santana#TOURMANIA#alstonsantana@tourmania.com#',
    'UG2OOTHAI&Henrietta#Mcleod#FIBRODYNE#henriettamcleod@fibrodyne.com#',
    'OHDAE1YIG&Jeanette#Becker#ASSITIA#jeanettebecker@assitia.com#',
    'OODUF8AEC&Margo#Wallace#MITROC#margowallace@mitroc.com#',
    'IEGOOD3AI&Weiss#Roman#BESTO#weissroman@besto.com#',
    'IR2OOLEAV&Lois#Sawyer#SLOFAST#loissawyer@slofast.com#',
    'SAHTH2HAE&Bernadette#Jones#EXOSWITCH#bernadettejones@exoswitch.com#',
    'IEY4OHNUU&Lolita#Mueller#SKYBOLD#lolitamueller@skybold.com#',
    'DO9EYIYEE&Mattie#Puckett#PATHWAYS#mattiepuckett@pathways.com#',
    'AIK1EU8OO&Tracey#Coffey#XYLAR#traceycoffey@xylar.com#',
    'OOPAEQUO9&Buchanan#Leon#NIXELT#buchananleon@nixelt.com#',
    'IECH1ZUP8&Roberson#Smith#PETICULAR#robersonsmith@peticular.com#',
    'RIEHI1OHG&Gilda#Chang#DOGNOST#gildachang@dognost.com#',
    'EEVAI6SHU&Antoinette#Walker#CONCILITY#antoinettewalker@concility.com#',
    'THEIC5PHA&Jean#Velazquez#ACCUPRINT#jeanvelazquez@accuprint.com#',
    'RE1VOHM3S&Amparo#Sweet#SYBIXTEX#amparosweet@sybixtex.com#',
    'ZU8ZEEGHI&Donovan#Dillard#PYRAMI#donovandillard@pyrami.com#',
    'IEM4OW8RU&Concepcion#Stewart#BRISTO#concepcionstewart@bristo.com#',
    'OHCIEMO9D&Rachelle#Morales#COMVEYER#rachellemorales@comveyer.com#',
];

export function getTestDataBadge() {
    let randomIndex = Math.floor(Math.random()*TEST_DATA_BADGES.length);
    return TEST_DATA_BADGES[randomIndex];
}

/**
 * Check licence
 *
 * response sample:
 * {
      "AuthenticateExhibitorAppByCABResult": {
        "AuthenticationStatus": 0,
        "AuthenticationStatusDescription": "OK",
        "EventDateBegin": "15/04/2017 00:00:00",
        "EventDateEnd": "31/12/2020 00:00:00",
        "EventId": "761502c1-23e4-e411-b9c7-005056a13c8a",
        "EventName": "Master PE Gratuit",
        "ExhibitorId": "ef325617-82a7-33cf-cc42-238785d96901",
        "ExhibitorName": "MOBILE SPOT",
        "InstanceName": "Leni",
        "MobileServicesUrl": "http://www.e-registration.net/WebServices/WS_Gaia_Mobile/GaiaMobile/",
        "Users": [
            "iPhone de Benoît",
            "iPhone de Rayk"
        ],
        "Login": "zaK4eP",
        "Password": "xwuq7q"
      }
   }
 */
export const checkLicence = (CAB, mobileId) => new Promise(function(resolve, reject) {

    if (!global.isCordovaContext && config.ENV === 'dev') {
        // DEV MODE
        CAB = TEST_DATA.licence;
        mobileId = TEST_DATA.mobileId;
    }

    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        body: JSON.stringify({
            CAB: CAB,
            mobileId: mobileId
        }),
    };
    fetchHelper(
        config.KLIPSO_LEADS.CHECK_LICENCE_WS_URL,
        opts,
        true, // parse response as json
        function(response) {
            if (!response || !response.AuthenticateExhibitorAppByCABResult) {
                console.error(LOG_PREF+'Failed to check licence because of empty response', response);
                reject(WS_ERRORS.UNKNOWN);
            }
            else if (response.AuthenticateExhibitorAppByCABResult.AuthenticationStatusDescription !== 'OK') {
                reject(WS_ERRORS.INVALID_LICENCE);
            }
            else {
                resolve(response.AuthenticateExhibitorAppByCABResult);
            }
        },
        function onFailure(error, message) {
            console.error(LOG_PREF+'Failed to check licence / CAB:'+CAB+' / mobileId:'+mobileId);
            reject(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
})

if (config.ENV === 'dev') {
    global.checkLicence = checkLicence;

    global.testCheckLicence = async function() {
        let result = await checkLicence(
            TEST_DATA.licence,
            TEST_DATA.mobileId
        ).catch(global.noop);

        console.log('checkLicence result: ', result);
    };
}


/**
 * Register a device (checkpoint)
 *
 * response sample:
 * {
     "RegisterCheckPointResult": {
        "CheckPointId": "d3ae1fd7-063c-e911-80f9-005056ae1179",
        "FormFields": [
            {
                "CABPosition": 1,
                "Code": "Prenom",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "Prenom",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Prénom",
                "Nature": 0,
                "PrintPosition": 1,
                "Type": 0
            },
            {
                "CABPosition": 2,
                "Code": "Nom",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "Nom",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Nom",
                "Nature": 0,
                "PrintPosition": 2,
                "Type": 0
            },
            {
                "CABPosition": 3,
                "Code": "Societe",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "Societe",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Société",
                "Nature": 0,
                "PrintPosition": 3,
                "Type": 0
            },
            {
                "CABPosition": 4,
                "Code": "Email",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "Email",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Email",
                "Nature": 0,
                "PrintPosition": 4,
                "Type": 0
            },
            {
                "CABPosition": null,
                "Code": "AutreSociete",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "AutreSociete",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Autre Societe",
                "Nature": 3,
                "PrintPosition": null,
                "Type": 0
            },
            {
                "CABPosition": null,
                "Code": "AutreEmail",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "AutreEmail",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Autre Email",
                "Nature": 3,
                "PrintPosition": null,
                "Type": 0
            },
            {
                "CABPosition": null,
                "Code": "Commentaire",
                "Codifications": [],
                "DefaultValue": "",
                "Id": "Commentaire",
                "IsMandatory": false,
                "IsReadOnly": false,
                "Label": "Commentaire",
                "Nature": 3,
                "PrintPosition": null,
                "Type": 0
            }
        ],
        "IsAutorizedCreatVisitor": "False",
        "IsAutorizedVoiceMessage": "False",
        "RegisterStatus": 0,
        "RegisterStatusDescription": "OK"
      }
    }
 */
export const registerCheckpoint = (instance, exhId, userName, mobileId) => new Promise(function(resolve, reject) {
    if (!global.isCordovaContext && config.ENV === 'dev') {
        // DEV MODE
        instance = TEST_DATA.InstanceName;
        exhId = TEST_DATA.ExhibitorId;
        userName = TEST_DATA.userName;
        mobileId = TEST_DATA.mobileId;
    }

    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        body: JSON.stringify({
            instanceName: instance,
            exhibitorId: exhId,
            userName: userName,
            mobileId: mobileId,
        }),
    };
    fetchHelper(
        config.KLIPSO_LEADS.REGISTER_CHECKPOINT_WS_URL,
        opts,
        true, // parse response as json
        function(response) {
            if (!response || !response.RegisterCheckPointResult) {
                console.error(LOG_PREF+'Failed to register checkpoint because of empty response', response);
                reject(WS_ERRORS.UNKNOWN);
            }
            else if (response.RegisterCheckPointResult.RegisterStatusDescription !== 'OK') {
                console.error(LOG_PREF+'Failed to register checkpoint because of unexpected response', response);
                reject(WS_ERRORS.UNKNOWN);
            }
            else {
                resolve(response.RegisterCheckPointResult);
            }
        },
        function onFailure(error, message) {
            console.error(LOG_PREF+'Failed to register checkpoint / exhId:'+exhId+' / userName:'+userName+' / mobileId:'+mobileId);
            reject(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
})

if (config.ENV === 'dev') {
    global.registerCheckpoint = registerCheckpoint;

    global.testRegisterCheckpoint = async function() {
        let result = await registerCheckpoint(
            TEST_DATA.InstanceName,
            TEST_DATA.ExhibitorId,
            TEST_DATA.userName,
            TEST_DATA.mobileId
        ).catch(global.noop);

        console.log('registerCheckpoint result: ', result);
    };
}


/**
 * Get form fields
 *
 * reponse sample:
 * {
    "GetFormFieldsResult": [
        {
            "CABPosition": 1,
            "Code": "Prenom",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Prenom",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Prénom",
            "Nature": 0,
            "PrintPosition": 1,
            "Type": 0
        },
        {
            "CABPosition": 2,
            "Code": "Nom",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Nom",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Nom",
            "Nature": 0,
            "PrintPosition": 2,
            "Type": 0
        },
        {
            "CABPosition": 3,
            "Code": "Societe",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Societe",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Société",
            "Nature": 0,
            "PrintPosition": 3,
            "Type": 0
        },
        {
            "CABPosition": 4,
            "Code": "Email",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Email",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Email",
            "Nature": 0,
            "PrintPosition": 4,
            "Type": 0
        },
        {
            "CABPosition": 5,
            "Code": "TelephoneFixe",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "TelephoneFixe",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Téléphone fixe",
            "Nature": 0,
            "PrintPosition": 5,
            "Type": 0
        },
        {
            "CABPosition": null,
            "Code": "AutreNom",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "AutreNom",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Autre Nom",
            "Nature": 3,
            "PrintPosition": null,
            "Type": 0
        },
        {
            "CABPosition": null,
            "Code": "AutreSociete",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "AutreSociete",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Autre Societe",
            "Nature": 3,
            "PrintPosition": null,
            "Type": 0
        },
        {
            "CABPosition": null,
            "Code": "AutreEmail",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "AutreEmail",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Autre Email",
            "Nature": 3,
            "PrintPosition": null,
            "Type": 0
        },
        {
            "CABPosition": null,
            "Code": "Commentaire",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Commentaire",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Commentaire",
            "Nature": 3,
            "PrintPosition": null,
            "Type": 0
        }
    ],
    "GetFormFieldsStatus": 0,
    "GetFormFieldsStatusDescription": "OK"
  }
 */
export const getFormFields = (exhId, mobileId) => new Promise(function(resolve, reject) {
    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        body: JSON.stringify({
            instanceName: config.KLIPSO_LEADS.INSTANCE_NAME,
            exhibitorId: exhId,
            mobileId: mobileId,
        }),
    };
    fetchHelper(
        config.KLIPSO_LEADS.GET_FORM_FIELDS_WS_URL,
        opts,
        true, // parse response as json
        resolve,
        function onFailure(error, message) {
            console.error(LOG_PREF+'Failed to get form field / exhId:'+exhId+' / mobileId:'+mobileId);
            reject(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
})

if (config.ENV === 'dev') {
    global.getFormFields = getFormFields;

    global.testGetFormFields = async function() {
        let result = await getFormFields(
            'ef325617-82a7-33cf-cc42-238785d96901',
            '066d8e94-34a4-422b-a81b-75e189f2165a'
        ).catch(global.noop);

        console.log('getFormFields result: ', result);
    };
}


/*
{
    VisitorToSave: {
        CAB: "WLYAT68QF",
        VisitHistoricList: [{
            CheckPointId: "e44e406c-5f3e-e911-8100-005056ae26bf",
            ContactName: "TCL 5051D",
            DateVisit: "04\/03\/2019 10:30:29"
        }],
        ExternalId: "098ebcea-3dc5-47c1-be25-9b2e50e13f78",
        IsExistPhoto: false,
        IsExistVoiceMessage: false,
        FieldValueList: [
            {
                Id: "AutreNom",
                Label: "Autre Nom",
                Value: "autre nom",
                Values: [],
                Codes: []
            }, {
                Id: "AutreSociete",
                Label: "Autre Societe",
                Value: "autre société",
                Values: [],
                Codes: []
            }, {
                Id: "AutreEmail",
                Label: "Autre Email",
                Value: "test@leni.fr",
                Values: [],
                Codes: []
            }, {
                Id: "Commentaire",
                Label: "Commentaire",
                Value: "test sync",
                Values: [],
                Codes: []
            }
        ]
    },
    SaveAction: 1
},
*/


const HISTORY_DATE_VISIT_FORMAT = 'DD/MM/YYYY HH:mm:ss';

/**
 * @param  {array} contacts
 * @param  {object} context
 * @return {array}
 */
function transformContactsToSendToWebService(contacts, context) {
    let { editableFields } = splitFields(getKnownFormFields());

    let arrayToSend = contacts.map(contact => ({
        VisitorToSave: {
            CAB: contact.code,
            VisitHistoricList: contact.history.map(h => ({
                CheckPointId: context.checkPointId,
                ContactName: context.userName,
                DateVisit: moment.utc(h.date).format(HISTORY_DATE_VISIT_FORMAT),
            })),
            ExternalId: '098ebcea-3dc5-47c1-be25-9b2e53e13g78', // contact.guid,
            IsExistPhoto: false,
            IsExistVoiceMessage: false,
            FieldValueList: editableFields.map(function(formField) {
                let isSingleOrMultiChoice = (
                    formField.Type === FIELD_TYPES.SINGLE_CHOICE
                        || formField.Type === FIELD_TYPES.MULTI_CHOICE
                );
                let value = contact[formField.Code] || null;

                return {
                    Id: formField.Id,
                    Code: formField.Code,
                    Label: formField.Label,
                    Value: isSingleOrMultiChoice ? '' : value,
                    Values: isSingleOrMultiChoice
                                ? (Array.isArray(value)
                                    ? value
                                    : (value === null ? [] : [value]))
                                : [],
                };
            }),
        },
        SaveAction: 1
    }));
    return arrayToSend;
}



/**
 * Save visitors list
 *
 * response sample:
 * {
     "SaveVisitorRelationListResult": [
        {
            "CAB": "WLYAT68QF",
            "ExternalId": "098ebcea-3dc5-47c1-be25-9b2e50e13f78",
            "InternalId": "e5f30dea-81d5-d42a-ea2d-14a20d5865a9",
            "IsSaveOk": false
        },
        {
            "CAB": "WPQLP8FND",
            "ExternalId": "6d60face-dd89-4516-bbad-3d09c167bbf1",
            "InternalId": "6f6786c2-a25b-23f1-52f6-0fed0ac8d613",
            "IsSaveOk": false
        }
    ]
  }
 */
export const saveVisitorRelationList = () => (
    new Promise(function(resolve, reject) {

        // Filter contacts to send
        let contacts = getContacts(),
            contactsToSync = contacts.filter(contact => !contact.isSync);

        if (contactsToSync.length < 1) {
            console.info(LOG_PREF+contactsToSync.length+' contact to sync - skipping WS call');
            resolve();
            return;
        }

        console.info(LOG_PREF+'About to sync '+contactsToSync.length+' contacts');

        let context = getContext(),
            mobileId = getMobileId();

        // DEV - mock mobileId
        if (config.ENV === 'dev' && !global.isCordovaContext) {
            mobileId = TEST_DATA.mobileId;
        }

        // Transform contacts data
        let arrayToSend = transformContactsToSendToWebService(contactsToSync, context);

        // Call WS
        let opts = {
            method: 'POST',
            headers: [ HEADERS.JSON_CONTENT_TYPE ],
            body: JSON.stringify({
                instanceName: context.instance,
                exhibitorId: context.exhibitor.id,
                mobileId: mobileId,
                visitorToSaveList: arrayToSend,
            }),
        };
        fetchHelper(
            config.KLIPSO_LEADS.SAVE_WS_URL,
            opts,
            true, // parse response as json
            function onSuccess(response) {
                let listResults = response.SaveVisitorRelationListResult;
                if (!listResults || Array.isArray(listResults) !== true) {
                    reject(WS_ERRORS.UNKNOWN);
                    return;
                }
                let saveStatusOkCount = 0;

                // Avoids looping on contacts for each result
                let contactsCabIndexPairs = {};
                contacts.forEach(function(contact, index) {
                    contactsCabIndexPairs[contact.code] = index;
                });

                // Iterate on results to update contact 'lastSync' property
                listResults.forEach(function(result){
                    if (result.IsSaveOk !== true) {
                        console.warn(LOG_PREF+'Failed to save contact', result);
                        return;
                    }
                    let contactIndex = contactsCabIndexPairs[result.CAB];
                    if (typeof contactIndex !== 'number') { // safety net - pretty unexpected case
                        console.error(LOG_PREF+'Contact matching CAB '+result.CAB+' not found among local data', result);
                        return;
                    }
                    let contact = contacts[contactIndex];

                    // Update contact
                    contact.lastSync = new Date().getTime();
                    contact.isSync = true;
                    contacts.splice(contactIndex, 1, contact);
                    saveStatusOkCount++;
                });

                // Persist contacts and dispatch to store
                saveContacts(contacts);

                resolve();
            },
            function onFailure(error, message) {
                console.error(LOG_PREF+'Failed to save visitors', contactsToSync, error, message);
                reject(WS_ERRORS.NETWORK);
            },
            false // showModalOnError
        );
    })
)

if (config.ENV === 'dev') {
    global.saveVisitorRelationList = saveVisitorRelationList;

    global.testSaveVisitorRelationList = async function() {
        let result = await saveVisitorRelationList(
            'ef325617-82a7-33cf-cc42-238785d96901',
            '1253b432febfac14',
            [{
                VisitorToSave: {
                    CAB: "WLYAT68QF",
                    VisitHistoricList: [{
                        CheckPointId: "e44e406c-5f3e-e911-8100-005056ae26bf",
                        ContactName: "TCL 5051D",
                        DateVisit: "04\/03\/2019 10:30:29"
                    }],
                    ExternalId: "098ebcea-3dc5-47c1-be25-9b2e50e13f78",
                    IsExistPhoto: false,
                    IsExistVoiceMessage: false,
                    FieldValueList: [
                        {
                            Id: "AutreNom",
                            Label: "Autre Nom",
                            Value: "autre nom",
                            Values: [],
                            Codes: []
                        }, {
                            Id: "AutreSociete",
                            Label: "Autre Societe",
                            Value: "autre société",
                            Values: [],
                            Codes: []
                        }, {
                            Id: "AutreEmail",
                            Label: "Autre Email",
                            Value: "test@leni.fr",
                            Values: [],
                            Codes: []
                        }, {
                            Id: "Commentaire",
                            Label: "Commentaire",
                            Value: "test sync",
                            Values: [],
                            Codes: []
                        }
                    ]
                },
                SaveAction: 1
            },
            {
                VisitorToSave: {
                    CAB: "WPQLP8FND",
                    VisitHistoricList: [{
                        CheckPointId: "e44e406c-5f3e-e911-8100-005056ae26bf",
                        ContactName: "TCL 5051D",
                        DateVisit: "04\/03\/2019 10:33:17"
                    }],
                    ExternalId: "6d60face-dd89-4516-bbad-3d09c167bbf1",
                    IsExistPhoto: false,
                    IsExistVoiceMessage: false,
                    FieldValueList: [
                        {
                            Id: "AutreNom",
                            Label: "Autre Nom",
                            Value: "nom nom",
                            Values: [],
                            Codes: []
                        }, {
                            Id: "AutreSociete",
                            Label: "Autre Societe",
                            Value: "societe société",
                            Values: [],
                            Codes: []
                        }, {
                            Id: "AutreEmail",
                            Label: "Autre Email",
                            Value: "testtest@leni.fr",
                            Values: [],
                            Codes: []
                        }, {
                            Id: "Commentaire",
                            Label: "Commentaire",
                            Value: "test 2 Sync",
                            Values: [],
                            Codes: []
                        }
                    ]
                },
                SaveAction: 1
            }]
        ).catch(global.noop);

        console.log('saveVisitorRelationList result: ', result);
    };
}
