
import GenericItemPage from './GenericItemPage';
import Pages from 'src/pages/Pages';

let keysOfPagesUsingGenericItem;

export function initKeysOfPagesUsingGenericItem() {
    keysOfPagesUsingGenericItem = Object.keys(Pages).filter(pageKey => {
        return Pages[pageKey].component === GenericItemPage;
    });
}

export const getKeysOfPagesUsingGenericItem = () => keysOfPagesUsingGenericItem
