
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_SERVICES } from 'data/config/dataConfig';
import { SERVICE_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailPlaces       from 'src/components/detail-places/DetailPlaces';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import Description        from 'src/components/fiche/Description';
import PhoneRow           from 'src/components/fiche/PhoneRow';
import EmailRow           from 'src/components/fiche/EmailRow';
import WebsiteRow         from 'src/components/fiche/WebsiteRow';
import ShareButton        from 'src/components/share-button/ShareButton';
import NoteButton         from 'src/components/notes/NoteButton';

import '../common-fiche.scss';


class ServiceContent extends PureComponent {

    setScrollableContentEl = el => {
        this.contentContainerEl = el;
    }
    scrollTo = value => {
        if (this.contentContainerEl) {
            this.contentContainerEl.scrollTop = value;
        }
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_SERVICES,
            this.props.isFavorite
        );
    }

    render() {
        let lump = this.props.item.lump,
            hasPlaces = Array.isArray(this.props.item.references.places) && this.props.item.references.places.length > 0;

        const title = this.props.item.title,
            logoUrl = this.props.item.logo_file_name,
            description = this.props.item.description;

        return (
            <div className="fiche service content-font content-below-apptoolbar">

                {/* title */}
                <DetailFixedTitle
                    title={title}
                    isFav={this.props.isFavorite}
                    onFavClick={this.onClickOnFavIcon}
                    labels={this.props.labels}>
                    <NoteButton
                          itemTitle={this.props.item.title}
                          itemId={this.props.item.id}
                          dataType={DATA_TYPE_SERVICES}
                          hasNote={this.props.hasNote}
                          labels={this.props.labels}
                          actions={this.props.actions} />
                    <ShareButton
                        name={this.props.pageTitle}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={SERVICE_PAGE_KEY} />

                </DetailFixedTitle>

                <div className="content" ref={this.setScrollableContentEl}>
                    <div className="all-informations content-font">

                        <Description
                            logoFileName={logoUrl}
                            description={description}
                            isLogoFullWidth={this.props.isLogoFullWidth} />

                        {/* places */}
                        { hasPlaces &&
                            <div>
                                <DetailPlaces
                                    labels={this.props.labels}
                                    actions={this.props.actions}
                                    id={this.props.item.id}
                                    originalId={this.props.item.original_id}
                                    entityType={DATA_TYPE_SERVICES}
                                    places={lump.oneMapButton ? [] : this.props.item.references.places} />
                            </div>
                        }

                        {/* contacts bar */}
                        { (this.props.item.website || this.props.item.email || this.props.item.phone) &&
                            <div className="fiche-separator">{this.props.labels.common.information}</div>
                        }

                        <PhoneRow phone={this.props.item.phone} />

                        <WebsiteRow
                            website={this.props.item.website}
                            actions={this.props.actions} />

                        <EmailRow email={this.props.item.email} />

                    </div>
                </div>
            </div>
        );
    }

}

ServiceContent.propTypes = {
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    pageTitle   : PropTypes.string.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
};

export default ServiceContent;
