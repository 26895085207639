
import * as CordovaPushWooshManager from './CordovaPushWooshManager';
import * as WebPushWooshManager from './WebPushWooshManager';

import { isCordovaContext } from 'src/core/util/browser';


export default {
    init: isCordovaContext() ? CordovaPushWooshManager.init : WebPushWooshManager.init,
    setProfileTag: isCordovaContext() ? CordovaPushWooshManager.setProfileTag : WebPushWooshManager.setProfileTag,
}
