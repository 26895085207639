import React, { Component, PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import config from "data/config/config";

import * as actions from "src/store/actions";

import { isAndroid } from "src/core/util/browser";
import showConfirmModal from "src/core/util/showConfirmModal";

import "./FormModal.scss";

import { isIOS, isIphoneX } from "src/core/util/browser";
import { testEmail } from "src/core/util/StringUtil";

export const COMPONENT_KEY = "FormModal";
export const CONTAINER_DOM_ID = "form-modal-container";

const DEFAULT_DIALOG_PROPS = {
    // TODO: Export that to data/config/dialogsConfig ?
};

class FormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardVisible: false
        };
        window.addEventListener("keyboardDidShow", event => {
            this.setState({
                keyboardVisible: true
            });
        });
        window.addEventListener("keyboardDidHide", () => {
            this.setState({
                keyboardVisible: false
            });
        });
        //addStylesheetRules([[".alert-dialog", ["top", "30% !important"]]]);
        //addStylesheetRules([[".alert-dialog", ["top", "20% !important"]]]);
    }
    handleChange = e => {
        let form = this.state.form ? this.state.form : {};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    };
    onCloseButtonClick = e => {
        this.setState({
            form: {},
            formErrors: {}
        });
        const { actions } = this.props;
        actions.hideFormModal();
    };

    validateEmail(email) {
        return testEmail(String(email).toLowerCase());
    }

    validateForm(submit) {
        const { form, formAction, formActionPayload } = this.props;
        let errors = false;
        let formErrors = this.state.formErrors ? this.state.formErrors : {};
        form.fields.map((ele, index) => {
            if (ele.validation) {
                switch (ele.validation.type) {
                    case "email":
                        let email = this.state.form
                            ? this.state.form[ele.name]
                                ? this.state.form[ele.name]
                                : ""
                            : "";
                        errors = formErrors[ele.name] = !this.validateEmail(
                            email
                        );
                        break;
                    default:
                }
            }
        });
        this.setState({
            formErrors
        });
        if (!errors) {
            formAction(this.state.form, formActionPayload);
            this.onCloseButtonClick();
        }
    }
    render() {
        const {
            isOpen,
            form,
            formAction,
            formActionPayload,
            liEl,
            labels
        } = this.props;
        if (!form) return null;
        let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
            open: isOpen,
            onClose: actions.hideFormModal // important, to update `open` value in all cases
        });

        const fields = form.fields.map((ele, index) => {
            //TODO implement more fields types.
            switch (ele.type) {
                case "input":
                    return (
                        <div key={"field" + index}>
                            <label
                                className="form-modal-label"
                                htmlFor={ele.name}
                            >
                                {ele.label + " :"}
                            </label>
                            {this.state.formErrors &&
                                this.state.formErrors[ele.name] &&
                                ele.validation &&
                                ele.validation.validationMessage && (
                                    <div className="form-modal-validation-message">
                                        {ele.validation.validationMessage}
                                    </div>
                                )}
                            <input
                                type={ele.subType}
                                className={
                                    "form-modal-input " +
                                    (this.state.formErrors &&
                                    this.state.formErrors[ele.name]
                                        ? "form-modal-input-error"
                                        : "")
                                }
                                value={
                                    (this.state.form &&
                                        this.state.form[ele.name]) ||
                                    ""
                                }
                                name={ele.name}
                                onChange={this.handleChange}
                            />
                        </div>
                    );
                default:
                    return null;
            }
        });

        return (
            <Dialog
                {...dialogProps}
                style={
                    this.state.keyboardVisible
                        ? isIOS()
                            ? { top: "-40%" }
                            : { top: "-50%" }
                        : { top: 0 }
                }
            >
                <DialogTitle className="form-modal-title">
                    {form.title}
                </DialogTitle>

                <div className="form-modal">
                    <div className="form-modal-subtitle">{form.subtitle}</div>
                    {fields}
                    <div className="form-modal-buttons">
                        <div
                            id="form-modal-deleteButton"
                            className="form-modal-button"
                            onClick={this.onCloseButtonClick}
                        >
                            {labels.formModal.cancel}
                        </div>
                        <div
                            id="form-modal-closeButton"
                            className="form-modal-button"
                            onClick={() => {
                                this.validateForm();
                            }}
                        >
                            {labels.formModal.send}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

FormModal.propTypes = {
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormModal);
