
import config from 'data/config/config';

// Simple module avoiding a circular dependency
// data/config/pagesConfig.js
//   -> src/pages/map/MapPage.js
//     -> src/pages/map/mapMiddleware.js
//       -> src/core/navigation/Router.js
//         -> src/pages/Pages.js -> data/config/pagesConfig.js


const STATUS = {
    notLoaded: 'not_loaded',
    loading: 'loading',
    loaded: 'loaded',
    restarting: 'restarting',
};

let status;

function setStatus(value) {
    console.info('Map status: '+value);
    status = value;
}

setStatus(STATUS.notLoaded);

export default {
    setStatusNotLoaded: value => { setStatus(STATUS.notLoaded); },
    setStatusLoading: value => { setStatus(STATUS.loading); },
    setStatusLoaded: value => { setStatus(STATUS.loaded); },
    setStatusRestarting: value => { setStatus(STATUS.restarting); },

    isNotLoaded: value => status === STATUS.notLoaded,
    isLoading: value => status === STATUS.loading,
    isLoaded: value => status === STATUS.loaded,
    isRestarting: value => status === STATUS.restarting,
};

if (config.ENV === 'dev') {
    global.getMapContextStatus = () => status;
}