
/**
 * Note: This module is from old cake framework, and was named web_app.phonegap
 */

import config from 'data/config/config';

import * as Updater from './Updater';
import { clear as clearPersistedData } from 'src/core/data-and-assets/DbPersistence';
import { addHttpIfNeeded } from 'src/core/util/JsTools';



const LOG_PREF = '[FileSystemHelper] ';

// force timeout to 5 min for downloading file
const DOWNLOADFILE_TIMEOUT = 5;


let fs = null,
    update = true; // @see app/views/elements/device_configuration.ctp


const getErrorMessage = (e) => {
    console.log(LOG_PREF+'getErrorMessage');

    if (!e) {
        return '';
    }

    if (typeof e.code === 'undefined') {
        return e;
    }

    let msg = '';

    switch (e.code) {
        case window.FileError.QUOTA_EXCEEDED_ERR:
            msg = 'QUOTA_EXCEEDED_ERR';
            break;
        case window.FileError.NOT_FOUND_ERR:
            msg = 'NOT_FOUND_ERR';
            break;
        case window.FileError.NOT_READABLE_ERR:
            msg = 'NOT_READABLE_ERR';
            break;
        case window.FileError.PATH_EXISTS_ERR:
            msg = 'PATH_EXISTS_ERR';
            break;
        case window.FileError.SECURITY_ERR:
            msg = 'SECURITY_ERR';
            break;
        case window.FileError.INVALID_MODIFICATION_ERR:
            msg = 'INVALID_MODIFICATION_ERR';
            break;
        case window.FileError.INVALID_STATE_ERR:
            msg = 'INVALID_STATE_ERR';
            break;
        case window.FileError.ENCODING_ERR:
            msg = 'ENCODING_ERR';
            break;
        case window.FileError.NO_MODIFICATION_ALLOWED_ERR:
            msg = 'NO_MODIFICATION_ALLOWED_ERR';
            break;
        case window.FileError.TYPE_MISMATCH_ERR:
            msg = 'TYPE_MISMATCH_ERR';
            break;
        default:
            msg = 'Unknown Error: code = ' + e.code;
            break;
    }

    return msg;
};


/**
 Initialize all ressources for update
 You can initialize all files on iOS when you launch for the first time the application but
 for android, you can't and can only initialize files when this file is updated.
 updateDb is here for listing files who are initialized for Android.
 **/
const initializeRessources = () => {
    console.log(LOG_PREF+'initializeRessources');

    document.addEventListener('resume', () => {
        console.log(LOG_PREF+'resume event');
        // if (getCurrentPageKey() === MAP_PAGE_KEY) {
        //     console.log(LOG_PREF+'ignore update when MapPage is active');
        // } else {
            Updater.startUpdate();
        // }
    });
};


const initialize = (initAppFunction) => {
    console.log(LOG_PREF+'initialize');

    // MA: move later into another location
    const createDirectory = (path, success, fail) => {
        console.log(LOG_PREF+'createDirectory: ' + path);

        let dirs = path.split('/').reverse(),
            root = fs.root;

        const createDir = (dir) => {
            console.log(LOG_PREF+'createDir: ' + dir);

            const successCB = (entry) => {
                console.log(LOG_PREF+'Successfully created directory', entry);
                root = entry;

                if (dirs.length > 0) {
                    createDir(dirs.pop());
                } else {
                    console.log(LOG_PREF+'Successfully created all directories!');
                    if (typeof success === 'function') {
                        success();
                    }
                }
            };

            const failCB = (error) => {
                console.error(LOG_PREF+'Failed to create dir :', dir, error);
                if (typeof fail === 'function') {
                    fail(error);
                }
            };

            root.getDirectory(dir, {
                create: true,
                exclusive: false
            }, successCB, failCB);
        };

        createDir(dirs.pop());
    };

    const getFileSystem = (win, fail) => {
        console.log(LOG_PREF+'getFileSystem');

        if (typeof window.resolveLocalFileSystemURL !== 'function') {
            console.error(LOG_PREF+'The project is missing `resolveLocalFileSystemURL` normally provided by `cordova-plugin-file`');
            fail();
            return;
        }

        window.resolveLocalFileSystemURL(window.cordova.file.dataDirectory, win, fail);
    };

    const fileSystemWin = (fileSystem) => {
        console.log(LOG_PREF+'fileSystemWin');
        fs = fileSystem;

        let path = '';

        // MA: disable specific folder creation...
        const next = () => {
            if (update === true) {
                initializeRessources();
            }
            Updater.init(initAppFunction);
        };

        if (path === '') {
            next();
        }
        else {
            const _afterDirectoryCreated = () => {
                console.log(LOG_PREF+'_afterDirectoryCreated');
                fs.getDirectory(path, { create: true, exclusive: false },
                    // Success
                    (directoryEntry) => {
                        console.log(LOG_PREF+'set main folder to: ', directoryEntry.nativeURL);
                        fs = directoryEntry.filesystem;
                        next();
                    },
                    // Failure
                    (error) => {
                        console.error(LOG_PREF+'getDirectory failure', getErrorMessage(error), error);
                        // continue anyway...
                        update = false;
                        initAppFunction();
                    }
                );
            };

            // MA: go to _afterDirectoryCreated in all cases
            createDirectory(path,
                (entry) => {
                    console.log(LOG_PREF+'createDirectory success', entry);
                    _afterDirectoryCreated(path);
                },
                (error) => {
                    console.error(LOG_PREF+'createDirectory failure', error);
                    _afterDirectoryCreated(path);
                }
            );
        }

    };

    const fileSystemFail = (error) => {
        console.error(LOG_PREF+'requestFileSystem failure', getErrorMessage(error), error);
        // continue anyway...
        update = false;
        initAppFunction();
    };

    let APP_VERSION = process.env.APP_VERSION;

    // reset data localstorage is this app version is newer than the existing one (if any)
    if (typeof APP_VERSION === 'undefined') {
        console.error(LOG_PREF+'APP_VERSION is not defined!');
    }

    let versionCode = window.localStorage.getItem('versionCode');
    if (versionCode === null || parseInt(versionCode, 10) !== parseInt(APP_VERSION, 10)) {
        console.log(LOG_PREF+'Reset data from previous app because this app is newer!');

        clearPersistedData(function(err) {
            if (err) {
                // ignored
            }
            Updater.clearLocalStorage();
            window.localStorage.setItem('versionCode', APP_VERSION);
            getFileSystem(fileSystemWin, fileSystemFail);
        });
    } else {
        getFileSystem(fileSystemWin, fileSystemFail);
    }
};


/**
 * Returns the absolute current path of the latest version of an asset
 * @param  {string}  item: relative path of the asset
 */
function getPath(item) {
    if (typeof fs === 'undefined') {
        console.error(LOG_PREF+'Filesystem is not ready. (getPath)');
        return '';
    }

    let path;
    // see http://plugins.cordova.io/#/package/org.apache.cordova.file-transfer
    if (typeof fs.toURL === 'function') {
        path = fs.toURL();
    }
    else {
        console.error(LOG_PREF+'getPath: could not get root path');
        path = '';
    }



    let result = path + '/' + item;
    console.log(LOG_PREF+'getPath result: '+result);
    return result;
};




// Update a local file
const downloadFile = (file, rename, win, fail) => {
    if (config.UPDATE_ENABLED !== true) {
        console.log(LOG_PREF+'Updates are disabled');
        return;
    }

    console.log(LOG_PREF+'downloadFile: '+file);
    if (!rename) {
        rename = file;
    }
    let fileTransfer = new window.FileTransfer(),
        time         = new Date().getTime(),
        uri          = addHttpIfNeeded(encodeURI(config.BO_URL + file + "?t=" + time), true),
        filePath     = getPath(rename, true),
        timeout      = DOWNLOADFILE_TIMEOUT || (5 * 1000 * 60);

    let timeoutId = window.setTimeout(fileTransfer.abort, timeout);

    fileTransfer.download(
        uri,
        filePath,
        (entry) => {
            console.log(LOG_PREF+'fileTransfer.download success', entry);
            window.clearTimeout(timeoutId);
            if (typeof win === 'function') {
                win(entry);
            }
        },
        (error) => {
            console.error(LOG_PREF+'fileTransfer.download error', error);
            window.clearTimeout(timeoutId);
            if (typeof fail !== 'function') {
                return;
            }
            if (error.code === window.FileTransferError.FILE_NOT_FOUND_ERR ||
                    error.code === window.FileTransferError.INVALID_URL_ERR) {
                fail('serverError', error.http_status);
            }
            else if (error.code === window.FileTransferError.CONNECTION_ERR) {
                fail('connectionError', error.http_status);
            }
            else if (error.code === window.FileTransferError.ABORT_ERR) {
                fail('timeout');
            }
            else {
                fail('unknownError', error.http_status);
            }
        }
    );
    return fileTransfer;
};


// Delete a local file
const deleteFile = (file) => {
    console.log(LOG_PREF+'deleteFile');

    if (typeof fs === 'undefined') {
        console.error(LOG_PREF+'(deleteFile) Filesystem object is not ready');
        return;
    }

    const getFileWin = (fileEntry) => {
        console.log(LOG_PREF+'getFileWin', fileEntry);
        const removeWin = () => {
            console.log(LOG_PREF+"removeWin file:", fileEntry);
        };
        const removeFail = (error) => {
            console.error(LOG_PREF+"removeFail", getErrorMessage(error), error);
        };
        fileEntry.remove(removeWin, removeFail);
    };
    const getFileFail = (error) => {
        // if non existing file/folder, no error
        if (error.code === window.FileError.NOT_FOUND_ERR) {
            return;
        }
        console.error(LOG_PREF+"getFileFail", getErrorMessage(error), error);
    };
    fs.getFile(file, { create: false }, getFileWin, getFileFail);
};


// Delete a local folder
const deleteFolder = (folder, callback) => {
    console.log(LOG_PREF+'deleteFolder');
    //console.log(LOG_PREF+'deleteFolder:', folder);

    if (typeof fs === 'undefined') {
        console.error(LOG_PREF+'(deleteFolder) Filesystem object is not ready');
        return;
    }

    const getDirectoryWin = (directoryEntry) => {
        console.log(LOG_PREF+'getDirectoryWin');
        const deletedWin = () => {
            console.log(LOG_PREF+'deletedWin directory:', directoryEntry);
            if (typeof callback === 'function') {
                callback(true);
            }
        };
        const deletedFail = (error) => {
            console.error(LOG_PREF+"deletedFail", getErrorMessage(error), error);
            if (typeof callback === 'function') {
                callback(false);
            }
        };
        directoryEntry.removeRecursively(deletedWin, deletedFail);
    };
    const getDirectoryFail = (error) => {
        console.log(LOG_PREF+'getDirectoryFail');
        // if non existing file/folder, no error
        if (error.code === window.FileError.NOT_FOUND_ERR) {
            if (typeof callback === 'function') {
                callback(true);
            }
            return;
        }
        console.error(LOG_PREF+"getDirectory failure", getErrorMessage(error), error);
        if (typeof callback === 'function') {
            callback(false);
        }
    };

    fs.getDirectory(folder, { create: false }, getDirectoryWin, getDirectoryFail);
};


const renameFolder = (folder, newName, callback) => {
    console.log(LOG_PREF+'renameFolder');

    if (typeof fs === 'undefined') {
        console.error(LOG_PREF+'Filesystem is not ready. (renameFolder)');
        return;
    }

    const getDirectoryWin = (directoryEntry) => {
        console.log(LOG_PREF+'getDirectoryWin');

        const getParentWin = (parentEntry) => {
            console.log(LOG_PREF+'getParentWin', parentEntry);

            const moveToWin = () => {
                console.log(LOG_PREF+'moveToWin', parentEntry);
                if (typeof callback === 'function') {
                    callback(true);
                }
            };
            const moveToFail = (error) => {
                console.error(LOG_PREF+"moveToFail", getErrorMessage(error), error);
                if (typeof callback === 'function') {
                    callback(false);
                }
            };
            directoryEntry.moveTo(parentEntry, newName, moveToWin, moveToFail);
        };
        const getParentFail = (error) => {
            console.error(LOG_PREF+"getParentFail", getErrorMessage(error), error);
            if (typeof callback === 'function') {
                callback(false);
            }
        };
        directoryEntry.getParent(getParentWin, getParentFail);
    };
    const getDirectoryFail = (error) => {
        console.error(LOG_PREF+"getDirectoryFail", getErrorMessage(error), error);
        if (typeof callback === 'function') {
            callback(false);
        }
    };

    fs.getDirectory(folder, { create: false }, getDirectoryWin, getDirectoryFail);
};


const getDirectory = (directory, okCb, koCb) => {
    console.log(LOG_PREF+'getDirectory');
    if (typeof fs === 'undefined') {
        console.error(LOG_PREF+'Filesystem is not ready. (getDirectory)');
        return;
    }
    fs.getDirectory(directory, null, okCb, koCb);
};


const getFileContent = (file, win, fail) => {
    console.log(LOG_PREF+'getFileContent');

    fs.getFile(file, { create: false },
        // Success
        (fileEntry) => {
            console.log(LOG_PREF+'getFile success', fileEntry);
            fileEntry.file(
                // Success
                (file) => {
                    console.log(LOG_PREF+'file success');
                    let reader = new FileReader();
                    reader.onloadend = (event) => {
                        if (typeof win === 'function') {
                            win(event.target.result);
                        }
                    };
                    reader.readAsText(file);
                },
                // Failure
                (error) => {
                    console.log(LOG_PREF+'file failure', error);
                    if (typeof fail === 'function') {
                        fail();
                    }
                }
            );
        },
        // Failure
        (error) => {
            console.log(LOG_PREF+'getFile failure', error);
            if (typeof fail === 'function') {
                fail();
            }
        }
    );

};


const setFileContent = (file, content, win, fail) => {
    console.log(LOG_PREF+'setFileContent');

    if (typeof fs === 'undefined') {
        console.error(LOG_PREF+'Filesystem is not ready. (setFileContent)');
        return;
    }

    const _getfileWin = (fileEntry) => {
        console.log(LOG_PREF+'_getfileWin', fileEntry);

        fileEntry.createWriter(
            // Success
            (writer) => {
                console.log(LOG_PREF+'createWriter success');
                writer.onwriteend = (evt) => {
                    console.log(LOG_PREF+'writer.truncate.onwriteend');

                    writer.onwriteend = () => {
                        console.log(LOG_PREF+ file + ' file created successfully.');
                        if (typeof win === 'function') {
                            win();
                        }
                    };
                    writer.onerror = (error) => {
                        console.error(LOG_PREF+'writer.write.onerror', getErrorMessage(error), error);
                        if (typeof fail === 'function') {
                            fail();
                        }
                    };
                    writer.write(content);
                };
                writer.onerror = (error) => {
                    console.error(LOG_PREF+'writer.truncate.onerror', getErrorMessage(error), error);
                    if (typeof fail === 'function') {
                        fail();
                    }
                };
                writer.truncate(0);
            },
            // Failure
            (error) => {
                console.error(LOG_PREF+'createWriter failure', getErrorMessage(error), error);
                if (typeof fail === 'function') {
                    fail();
                }
            }
        );
    };
    const _getfileFail = (error) => {
        console.error(LOG_PREF+'getFile failure', getErrorMessage(error), error);
        if (typeof fail === 'function') {
            fail();
        }
    };
    fs.getFile(file, { create: true, exclusive: false }, _getfileWin, _getfileFail);
};


export default {
    initialize    : initialize,
    deleteFile    : deleteFile,
    deleteFolder  : deleteFolder,
    downloadFile  : downloadFile,
    getDirectory  : getDirectory,
    getFileContent: getFileContent,
    getPath       : getPath,
    renameFolder  : renameFolder,
    setFileContent: setFileContent,
};
