// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, Provider } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';

// config
import { DATA_TYPE_PLACES } from 'data/config/dataConfig';

import SearchPlaceTypes from './SearchPlaceTypes';

// app modules
import SearchPlaceRow from './SearchPlaceRow';
import TypeBar from 'src/components/type-bar/TypeBar';
import List from 'src/components/list/List';
import NoResult from 'src/components/no-result/NoResult';

import { getItemTogglePMR } from 'data/config/menuConfig';

// import { TYPES } from 'src/core/util/ConfigItemFactory';
import TogglePMRItem from 'src/components/menu/TogglePMRItem';

import * as actions from 'src/store/actions';


import '../listDialog.scss';
import './SearchPlaceDialog.scss';


const LOG_PREF = '[SearchPlaceDialog] ';

export const COMPONENT_KEY = 'SearchPlaceDialog';
export const CONTAINER_DOM_ID = 'search-place-dialog-container';


let store;
export function setReduxStore(_store) {
    store = _store
}

/**
 * TypeBar is showed closed when items count is higher than the value defined here
 * @type {Number}
 */
const CLOSED_IF_COUNT_ABOVE = 5;

const DEFAULT_DIALOG_PROPS = {
    // TODO: Export that to data/config/dialogsConfig ?
};


const getTypedLists = (items, searchType, searchedString, actions, labels, isPending) => {
    let content = [];

    if (items) {
        Object.keys(items).forEach(dataType => {

            // Ignore this dataType if it has no entry
            if (items[dataType].length === 0) {
                return;
            }

            // Prepare a 'places' array to display
            let placesItems = [];
            items[dataType].forEach(item => {

                item.references.places.forEach(place => {
                    if (place) {
                        // to add the info field to the placeItems
                        const placeRef = item.places.find(placeObj => placeObj.place_id === place.id) || {}

                        placesItems.push(Object.assign({}, place, {
                            memberType: dataType,
                            memberId: item.id,
                            memberOriginalId: item.original_id,
                            memberTitle: item.title,
                            ...placeRef
                        }));
                    }
                });
            });

            // Ignore if there is no place to display
            if (placesItems.length === 0) {
                return;
            }

            const listProps = {
                items: placesItems,
                dataType: DATA_TYPE_PLACES,
                actions: actions,
                labels: labels,
                isPending: isPending,
                highlight: searchedString,
                searchType: searchType,
                displayFavorites: false,
            };
            let count = listProps.items ? listProps.items.length : 0;

            console.log('listProps', listProps)

            content.push(
                <TypeBar
                    key={dataType}
                    label={labels.data[dataType][count > 1 ? 'plural' : 'singular']}
                    count={count}
                    isClosed={count > CLOSED_IF_COUNT_ABOVE}>

                    <Provider store={store}>
                        <List {...listProps} />
                    </Provider>
                </TypeBar>
            );
        });
    }

    return content.length > 0 ? content : <NoResult labels={labels} />;
};

const convertEntryForMap = entry => !entry ? null : {
    id: entry.originalId || entry.id,
    placeId: entry.placeId,
    type: entry.type,
};

const getBottomButtons = (searchType, entries, labels, actions, isPMREnabled) => {
    if (!searchType) {
        return;
    }

    let buttons = [];
    switch (searchType) {

        case SearchPlaceTypes.start:
            if (entries.start && entries.start.type) {
                // Show button to select destination
                buttons.push(
                    <div
                        key="go-dest"
                        className="btn-choose-dest"
                        onClick={() => {
                            actions.showSearchPlaceDialog(SearchPlaceTypes.dest);
                        }}>{labels.placeSearch.setDestination}</div>
                );
            }
            break;

        case SearchPlaceTypes.dest:
            // Show button to edit start
            buttons.push(
                <div
                    key="go-start"
                    className="btn-edit-start"
                    onClick={() => {
                        actions.showSearchPlaceDialog(SearchPlaceTypes.start);
                    }}>{labels.placeSearch.editStart}</div>
            );

            if ( /*entries.start && entries.start.placeId &&*/ entries.dest && entries.dest.placeId) {
                // Show button to compute itinerary
                buttons.push(
                    <div
                        key="submit"
                        className="btn-compute-route"
                        onClick={() => {
                            actions.hideSearchPlaceDialog();
                            actions.showMapItinerary(
                                convertEntryForMap(entries.start),
                                convertEntryForMap(entries.dest),
                                {userType : isPMREnabled ? "PMR" : "all"}
                                );
                        }}>{labels.placeSearch.itinerary}</div>
                );
            }
            break;

        case SearchPlaceTypes.poi:
            // No button. When user selects a POI, it is directly displayed on map
            break;

        default:
            console.error(LOG_PREF + 'Invalid search type: ' + searchType);
    }
    return buttons;
};


/**
 * Display a dialog (modal) allowing user to search for displayable POI
 * @param  {object} props
 */
class SearchPlaceDialog extends React.PureComponent {

    setSearchField = ref => {
        this.searchField = ref;
    }

    focusOnSearchField = (function() {
        if (this.searchField) {
            this.searchField.focus();
        }
    }).bind(this)

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isOpen && this.props.isOpen) {
            // autofocus on search field
            window.setTimeout(this.focusOnSearchField, 500);
        }
    }

    render() {

        let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
            open: this.props.isOpen,
            onClose: this.props.actions.hideSearchPlaceDialog, // important, to update `open` value in all cases
            disableBackdropClick: !this.props.isCancelable,
            disableEscapeKeyDown: !this.props.isCancelable,
        });

        let entries;
        if (this.props.selectedEntries && typeof this.props.selectedEntries.toJS === 'function') {
            entries = this.props.selectedEntries.toJS();
        } else {
            entries = {};
        }

        const pmrToggle = <TogglePMRItem
                                label={this.props.labels.menu.pmrQuestion2}
                                entry={getItemTogglePMR(this.props.labels)} />;

        let keyboardHeight = typeof this.props.keyboardHeight === 'number' ? this.props.keyboardHeight : 0;
        let containerMaxHeight = document.documentElement.clientHeight - keyboardHeight;

        return (
            <Dialog id="search-place-dialog"
                    className="list-dialog content-font dialog-with-inputs dialog-min-width"
                    {...dialogProps}
                    style={{ maxHeight: containerMaxHeight }}
                    >
                <div id="sp-inner">

                    { this.props.searchType &&
                        <SearchPlaceRow
                            searchFieldRef={this.setSearchField}
                            type={this.props.searchType}
                            selectedEntry={entries[this.props.searchType]}
                            actions={this.props.actions}
                            labels={this.props.labels}
                            searchPlacePlaceholder={this.props.searchPlacePlaceholder}
                            userIsLocated={this.props.userIsLocated} />
                    }

                    { this.props.isOpen && typeof this.props.searched === 'string' &&
                        <div className="list-dialog-content">
                            { getTypedLists(
                                this.props.results,
                                this.props.resultsType,
                                this.props.searched,
                                this.props.actions,
                                this.props.labels,
                                this.props.isPending)
                            }
                        </div>
                    }
                    {this.props.searchType == SearchPlaceTypes.dest && entries.dest && entries.dest.placeId && <Provider store={store}>
                        { pmrToggle }
                    </Provider>
                    }

                    <div className={['sp-buttons-container', this.props.searchType].join(' ')}>{
                        getBottomButtons(this.props.searchType, entries, this.props.labels, this.props.actions, this.props.isPMREnabled)
                    }</div>

                </div>
            </Dialog>
        );
    }
};

SearchPlaceDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    labels: PropTypes.object.isRequired,
    searchPlacePlaceholder: PropTypes.string,
    searchType: PropTypes.string,
    selectedEntries: PropTypes.object.isRequired,
    isPMREnabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY]


const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPlaceDialog);