
import React from 'react';
import PropTypes from 'prop-types';


function FooterBackButton({ labels, callback }) {
    return (
        <div
            className="klf-btn klf-back-btn"
            onClick={typeof callback === 'function' ? callback : null}
        >
            <div className="klf-back-btn-icon far fa-arrow-left" />
            <div className="klf-back-btn-label content-font">{labels.klipsoLeads.back}</div>
        </div>
    );
}

FooterBackButton.propTypes = {
    labels: PropTypes.object.isRequired,
    callback: PropTypes.func,
}

export default FooterBackButton;
