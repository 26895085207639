
import React from 'react';
import PropTypes from 'prop-types';

import { getBadgeFields } from 'src/core/klipso-leads/KlipsoLeadsData';

import './ScanResult.scss';


class ScanResult extends React.Component {

    renderResult() {
        let result = this.props.result;

        if (typeof result === 'string') {
            return result;
        }
        else if (result !== null && typeof result === 'object') {
            let badgeFields = getBadgeFields();

            return (
                <div>
                    <div>
                        <span className="klc-result-field">Code:</span> {result.code}
                    </div>

                    { !badgeFields
                        ? <p style={{ color: 'red' }}>Missing badge fields!</p>
                        : badgeFields.map(badgeField => (
                            <div>
                                <span className="klc-result-field">{badgeField.Label}:</span> {result[badgeField.Code]}
                            </div>
                        ))
                    }
                </div>
            );
        }
    }

    render() {
        return (
            <>
                { this.props.result &&
                    <div className="kl-scan-result-block">Result: {this.renderResult()}</div>
                }
                { this.props.resultFormat &&
                    <div className="kl-scan-result-block">Format: {this.props.resultFormat}</div>
                }
            </>
        );
    }
}

ScanResult.propTypes = {
    result: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    resultFormat: PropTypes.string,
}

export default ScanResult;
