
import { getBindedActions } from 'src/store/bindedActions';

function toggleKeyboard(isOpen, height) {
    window.MobiGeo.setKeyboardStatus(isOpen, height);   
    getBindedActions().updateKeyboardState({
        isOpen: isOpen,
        height: isOpen ? height : 0,
    });
}

export function addKeyBoardListeners() {
    window.addEventListener("keyboardDidShow", event => {
        toggleKeyboard(true, event.keyboardHeight);
    });

    window.addEventListener("keyboardWillHide", () => {
        toggleKeyboard(false);
    });
    window.addEventListener("keyboardDidHide", () => {
        toggleKeyboard(false);
    });
}
