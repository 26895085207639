
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdBanner from 'src/components/ad-banner/AdBanner';
import { getLeastSeenAdFromBundle } from 'src/core/config-json/ConfigJsonManager';

import * as actions from 'src/store/actions';

// const LOG_PREF = '[AdSwap] ';

export const STORE_KEY = 'AdSwap';


class AdSwap extends React.Component {

    state = { time: null }

    getBundle() {
        // If any property is missing, skip component rendering
        if (this.props.adKey
                && this.props.adConfig
                && this.props.adConfig.ads
                && this.props.adConfig.swap_delay
                && this.props.adConfig.bundles
                && this.props.adConfig.bundleAttributions) {

            let bundleName = this.props.adConfig.bundleAttributions[this.props.adKey];
            if (!bundleName) {
                console.log('No ad bundle for '+this.props.adKey);
                return null;
            }
            return this.props.adConfig.bundles[bundleName];
        }
    }

    triggerRender = () => {
        this.setState({ time: new Date().getTime() });
    }

    shouldComponentUpdate(nextProps, nextState) {
        let isPageVisibleChanged = this.props.isPageVisible !== nextProps.isPageVisible,
            isAppVisibleChanged = this.props.isAppVisible !== nextProps.isAppVisible,
            timeHasChanged = this.state.time !== nextState.time,
            pageAndAppAreVisible = nextProps.isPageVisible && nextProps.isAppVisible;

        return !!(pageAndAppAreVisible && (timeHasChanged || isPageVisibleChanged || isAppVisibleChanged));
    }

    logToAnalytics = (ad => {
        this.props.actions.adSwap(this.props.adKey, ad);
    }).bind(this)

    render() {
        let bundle = this.getBundle();
        if (!bundle) {
            return null;
        }

        let leastSeenAd = getLeastSeenAdFromBundle(bundle, this.props.adConfig.ads)
        if (!leastSeenAd) {
            return null;
        }

        let adFiles = this.props.adConfig.adsFiles[leastSeenAd.name];
        if (!adFiles) {
            return null;
        }

        if (this.ongoingTimeout) {
            window.clearTimeout(this.ongoingTimeout);
        }

        // Avoid swap if there is only one ad to show
        if (bundle.length > 1) {
            this.ongoingTimeout = window.setTimeout(this.triggerRender, this.props.adConfig.swap_delay);
        }

        window.setTimeout(this.logToAnalytics, 1, leastSeenAd);

        // console.log(LOG_PREF+'render (adKey: '+this.props.adKey+') - ad: '+leastSeenAd.name);
        return (
            <AdBanner
                ad={leastSeenAd}
                adFiles={adFiles}
                currentLang={this.props.labels.id}
                actions={this.props.actions} />
        );
    }
}

AdSwap.propTypes = {
    adKey: PropTypes.string, // can be null if no ad is specified for a list
    isPageVisible: PropTypes.bool.isRequired,
    isAppVisible: PropTypes.bool.isRequired,
    // from store:
    adConfig: PropTypes.object,
    actions : PropTypes.object,
    labels  : PropTypes.object,
};

const mapStateToProps = (state, ownProps) => state[STORE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdSwap);
