
import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { WS_ERRORS, getErrorFromHttpStatus } from '../WS_ERRORS';
import STATUSES from '../statuses';
import dummyToken from './dummyToken';
import { parseUserData } from './UserDataWS';
import { isCordovaContext, isAndroid } from 'src/core/util/browser';


const LOG_PREF = '[LoginWS Taiga] ';


export function performLogin(account, cb) {
    const url = config.LOGIN.WS_URL+`/login.htm?login=${account.username}&password=${account.password}`;

    let opts = {
        method: 'GET',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
    };

    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onLoginSuccess(response) {
            switch(response.status) {
                case STATUSES.SUCCESS:
                    window.setTimeout(function() {
                        // On cordova android there is a cookie issue, see links on ticket PBC-51
                        if (isCordovaContext() && isAndroid()
                                && window.cordova.plugins && window.cordova.plugins.CookieManagementPlugin) {

                            console.log(LOG_PREF+'Applying cookie fix for cordova android');
                            window.cordova.plugins.CookieManagementPlugin.flush();
                        }

                        cb(
                            null, // error code
                            dummyToken, // token
                            parseUserData(response.datas), // userdata
                        );
                    }, 1500);
                    break;

                case STATUSES.ERROR:
                    cb(
                        getErrorFromHttpStatus(response.http_code),
                        null, // token
                    );
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onLoginFailure(error, message) {
            cb(WS_ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}

if (config.ENV === 'dev') {
    global.performLoginUsingTaigaWs = performLogin;
}
