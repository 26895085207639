import {
	FETCH_SOCIAL_FEED,
	SOCIAL_FEED_LOADED,
	SET_SOCIAL_FEED_ERROR,
    HAS_NAVIGATED,
    CONFIG_JSON_LOADED
} from 'src/store/actionTypes';

import {
	showNotification,
    fetchSocialFeed
} from 'src/store/actions';

import {
	fetch,
	persistence
} from 'src/core/social/SocialService';

import { isSocialMediaBasicDisplay } from 'src/core/config-json/ConfigJsonManager';

import {
	MEDIAS_PAGE_KEY
} from 'src/pages/pagesKeys';




export default ({
	dispatch,
	getState
}) => next => action => {
	const state = getState()
	const mediasPageState = state[MEDIAS_PAGE_KEY]
	const labels = mediasPageState ? mediasPageState.labels : null
    const socialMediaConfig = mediasPageState ? mediasPageState.socialMediaConfig : null
    const enabledPlatforms = socialMediaConfig ? Object.keys(socialMediaConfig) : []
    const result = next(action);

	// for the moment facebook is handled with the social page plugin so no need to fetch anything
    if (enabledPlatforms.indexOf('facebook') > -1) {
        const fbIndex = enabledPlatforms.indexOf('facebook')
        enabledPlatforms.splice(fbIndex, 1)
    }
	// Linked in not implemented
	if (enabledPlatforms.indexOf('linkedin') > -1) {
        const liIndex = enabledPlatforms.indexOf('linkedin')
        enabledPlatforms.splice(liIndex, 1)
    }

	switch (action.type) {
        case CONFIG_JSON_LOADED:
            if (!isSocialMediaBasicDisplay()) {
                enabledPlatforms.forEach(platform => {
                    dispatch(fetchSocialFeed({ type: platform, initial: true }))
                })
            }
            break;

        case HAS_NAVIGATED:
            if (!isSocialMediaBasicDisplay()) {
                if (action.pageKey === MEDIAS_PAGE_KEY) {
                    enabledPlatforms.forEach(platform => {
                        dispatch(fetchSocialFeed({ type: platform, initial: true }))
                    })
                }
            }
            break;

    	case FETCH_SOCIAL_FEED:
    		fetch(action.payload);
    		break;

    	case SOCIAL_FEED_LOADED:
    		persistence.set(action.payload);
    		break;

    	case SET_SOCIAL_FEED_ERROR:
    		if (labels) {
    			dispatch(showNotification({
    				message: labels.common.noNetworkTitle
    			}))
    		}
    		break;

    	default:
	}
	return result;
};
