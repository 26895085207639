
import barCodeScannerConfig from 'data/config/barCodeScannerConfig';

import { get as getLabels } from 'src/core/Lang';

const LOG_PREF = '[BarCodeScanner] ';


export function scan(acceptedFormats) {
    return new Promise(function(resolve, reject) {

        if (!global.isCordovaContext) {
            console.error(LOG_PREF+'Can\'t scan QR code in web environement');
            reject();
            return;
        }
        if (!cordova.plugins
                || !cordova.plugins.barcodeScanner
                || typeof cordova.plugins.barcodeScanner.scan !== 'function') {

            console.error(LOG_PREF+'phonegap-plugin-barcodescanner is missing');
            reject();
            return;
        }

        let scannerConfig = barCodeScannerConfig(getLabels());

        // Ability to override accepted formats
        if (Array.isArray(acceptedFormats)) {
            scannerConfig.formats = acceptedFormats.join(',');
        }
        else if (typeof acceptedFormats === 'string') {
            scannerConfig.formats = acceptedFormats;
        }

        cordova.plugins.barcodeScanner.scan(resolve, reject, scannerConfig);
    });
}
