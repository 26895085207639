
import React from 'react';

import { getValue } from 'src/core/util/JsTools';


export default function({
    fieldName,
    className,
    currentFormValue,
    setValue,
    label,
    labels,
    multiple,
    values,
    setFieldVisiblity,
}) {
    if (typeof setFieldVisiblity === 'function') {
        setFieldVisiblity(fieldName, true);
    }

    return (
        <div
            key={fieldName}
            className={'generic-field-row '+(className || '')}
        >
            { label && <label>{label}</label> }
            <div className="generic-field-row-chkbox">
                { values.map((checkBoxConf, index) => renderCheckBox({
                    index,
                    checkBoxConf,
                    multiple,
                    currentFormValue,
                    setValue,
                    labels,
                })) }
            </div>
        </div>
    );
}

function renderCheckBox({
    index,
    checkBoxConf,
    multiple,
    currentFormValue,
    setValue,
    labels,
}) {

    let value = checkBoxConf.value;

    let isChecked = currentFormValue.indexOf(value) !== -1;

    function check() {
        if (multiple) {
            setValue(currentFormValue.concat(value));
        } else {
            setValue(value);
        }
    }

    function uncheck() {
        if (multiple) {
            setValue(currentFormValue.filter(_value => _value !== value));
        } else {
            setValue('');
        }
    }

    function getCheckBoxClassName() {
        let className = [ 'generic-chkbx' ];
        if (multiple) {
            if (isChecked) {
                className.push('fas', 'fa-check-square');
            } else {
                className.push('far', 'fa-square');
            }
        } else {
            if (isChecked) {
                className.push('fas', 'fa-circle');
            } else {
                className.push('fal', 'fa-circle');
            }
        }
        return className.join(' ');
    }


    return (
        <div key={index}
             onClick={isChecked ? uncheck : check}
             className={ isChecked ? 'generic-chkbox-row-checked' : ''}
             >
            <span className={getCheckBoxClassName()} />
            <span className="generic-chkbx-label">{getValue(labels, checkBoxConf.label) || checkBoxConf.label}</span>
        </div>
    );
}
