
// Libs
import './polyfills';
import * as localforage from 'localforage';
// import * as OfflinePluginRuntime from 'offline-plugin/runtime';

// Config
import config from 'data/config/config';

// app modules
import * as ConfigJson from './core/config-json/ConfigJsonManager';
import * as PollManager from './core/polls/PollManager';
import * as ContributionsFeedManager from './core/contributions-feed/ContributionsFeedManager';
import { addMeaningfulCssClass, initiVisiblityChangeListener } from './core/util/browser';
import * as CordovaHelper from './core/cordova/CordovaHelper';
import * as DataAssetsUtil from './core/data-and-assets/DataAssetsUtil';
import * as GoogleAnalyticsHelper from './core/analytics/GoogleAnalyticsHelper';
import * as HomeTilesCssHandler from './pages/home/HomeTilesCssHandler';
import * as InboxService from './pages/inbox/InboxService';
import * as Lang from './core/Lang';
import * as LoginService from './core/login/LoginService';
import * as Profile from './core/Profile';
import Push from './core/push';
import * as ResizeHandler from './core/util/ResizeHandler';
import * as Router from './core/navigation/Router';
import * as SynchronizedFavoritesService from 'src/core/favorites/SynchronizedFavoritesService';
import * as TileClickEffect from './core/util/TileClickEffect';
import * as mapUtil from './pages/map/mapUtil';
import { StoredManualPMRStatus } from 'src/pages/pmr/PmrHelper';
import * as smartBanner from './core/smartBanner';
import mountStandaloneComponents from './components-standalone/mountStandaloneComponents';

import createStore from './store/createStore';

import { MAP_TYPE } from 'data/config/mapConfig';

// Fontawesome pro
import '@fortawesome/fontawesome-pro/css/all.css';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot';
import 'material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf';
import 'material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff';
import 'material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2';


// app common css
import './scss/index.scss';
import './components/components.scss';
import 'data/config-scss/fonts.scss';
import 'data/config-scss/custom.scss';


global.isCordovaContext = window.browserDetect.isCordovaContext();


const initializaApp = () => {

    console.info('Initializing app '+process.env.APP_NAME+' v'+process.env.APP_VERSION+' build:'+process.env.BUILD_VERSION);

    // OfflinePluginRuntime.install();

    // Set localized labels (from persisted lang, or use default lang)
    Lang.init();

    // Detect default profile
    Profile.init();

    // Listen to resize event (HomePage tiles needs to recalculate their size)
    ResizeHandler.init();

    // Add css class indicating what browser/environment the app is running on.
    // This allows some specific adjustments and fixes.
    addMeaningfulCssClass();

    // Detect page visibility change
    initiVisiblityChangeListener();

    // Initialize Redux store
    let { store, actions } = createStore();

    // Get DOM root
    let rootEl = document.getElementById('app-root');
    [
        Router,
        HomeTilesCssHandler,
        TileClickEffect,
    ].forEach((module, index) => {
        module.setRootContainer(rootEl);
    });

    // Mount isolated components
    mountStandaloneComponents(store, rootEl);

    // Fetch data (for current language)
    DataAssetsUtil.init();

    // Init PushWoosh
    Push.init();

    // Load and init GA API
    GoogleAnalyticsHelper.init();

    smartBanner.injectSmartBanners();

    // Init synchronized favorites feature
    if (config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED === true) {
        SynchronizedFavoritesService.init();
    }

    // Init autologin (will start if a login token is found)
    if (config.LOGIN.FEATURE_ENABLED) {
        LoginService.init();
    }

    // Init contributions feed
    if (ContributionsFeedManager.isEnabled()) {
        ContributionsFeedManager.init()
    }

    InboxService.init(store);
    CordovaHelper.init();

    StoredManualPMRStatus.init();
    if (MAP_TYPE !== 'NOMAP') {
        mapUtil.addListeners();
    }

    if (global.isCordovaContext !== true) {
        // Fetch ad config
        ConfigJson.init();

        PollManager.init();
    } else {
        // In cordova context, when Updater module has initialized then ConfigJson.init is called
        // @see core/configJsonMiddleware
    }

    // dev helpers
    if (config.ENV === 'dev') {
        global.store = store;
        global.actions = actions;
        global.localforage = localforage;
    }
};


document.addEventListener(global.isCordovaContext ? 'deviceready' : 'DOMContentLoaded', () => {

    initializaApp();

    // First page is displayed only once ad config has been loaded (for intersticiel)
    // @see store/reducers/bootMiddleware
});
