
import React from 'react';
import PropTypes from 'prop-types';

import { isAndroid } from 'src/core/util/browser';

import Url from 'src/components/url/Url';


function DetailSocialRaw({ data, actions }) {
    if (!data || typeof data !== 'object') {
        return null;
    }

    const target = global.isCordovaContext && !isAndroid() ? '_system' : null

    return (
        <>
            { data.tw && <Url className="fa fa-twitter-square"  callback={actions.linkClicked} href={data.tw} target={target} /> }
            { data.fb && <Url className="fa fa-facebook-square" callback={actions.linkClicked} href={data.fb} target={target} /> }
            { data.ig && <Url className="fa fa-instagram" callback={actions.linkClicked} href={data.ig} target={target} /> }
            { data.ln && <Url className="fa fa-linkedin-square" callback={actions.linkClicked} href={data.ln} target={target} /> }
            { data.yt && <Url className="fa fa-youtube-square" callback={actions.linkClicked} href={data.yt} target={target} /> }
        </>
    );
}

DetailSocialRaw.propTypes = {
    data: PropTypes.object,
    actions: PropTypes.object.isRequired,
}

export default DetailSocialRaw;