
import { SPEAKER_PAGE_KEY } from 'src/pages/pagesKeys';

import SpeakerContent from './SpeakerContent';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

export default {
    key      : SPEAKER_PAGE_KEY,
    path     : '/speaker',
    elId     : DOM_ID,
    className: 'speaker-page',
    component: GenericItemPage,
    childComponent: SpeakerContent,
    relatedDataToFetch: [ 'events' ],
};
