
import { KLIPSO_LEADS_PAGE_KEY } from 'src/pages/pagesKeys';

import KlipsoLeadsPage from './KlipsoLeadsPage';
import reducer from './klipsoLeadsPageReducer';

export default {
    key      : KLIPSO_LEADS_PAGE_KEY,
    path     : '/klipso-leads',
    component: KlipsoLeadsPage,
    elId     : 'klipso-leads-page',
    getReduxReducer: (state, action) => reducer(state[KLIPSO_LEADS_PAGE_KEY], action),
};
