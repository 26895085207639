
// Libs
import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

const GenericMenuItem = ({ entry, associatedPageKey, closeMenu, isDisabled }) => {

    // CONTAINER
    let hasContainerStyle = entry.style && typeof entry.style.container === 'object' && entry.style.container !== null,
        attributes = {},
        classes = [ 'content-font' ];

    // css
    if (hasContainerStyle && entry.style.container.style) {
        attributes.style = entry.style.container.style;
    }
    //if (isDisabled === true) {
    //    classes.push('menu-item-disabled');
    //}
    if (hasContainerStyle && entry.style.container.className) {
        classes.push(entry.style.container.className);
    }
    attributes.className = classes.join(' ');

    // is tappable ?
    attributes.disabled = isDisabled === true;

    // onClick handler
    attributes.onClick = isDisabled === true ? null : () => {
        closeMenu(associatedPageKey);
        entry.action(associatedPageKey);
    };

    // ICON
    let hasIconStyle = entry.style && typeof entry.style.icon === 'object' && entry.style.icon !== null,
        iconClasses = ['menu-icon'];

    if (hasIconStyle && entry.style.icon.className) {
        iconClasses.push(entry.style.icon.className);
    }

    return (
        // https://material-ui.com/api/list-item/#listitem-api
        <ListItem {...attributes}>

            {/*<ListItemIcon>*/}
                <span className={ iconClasses.join(' ') }
                      style={ hasIconStyle && entry.style.icon.style ? entry.style.icon.style : null }
                      >{ hasIconStyle && typeof entry.style.icon.webfont === 'string' ? entry.style.icon.webfont : null }</span>
            {/*</ListItemIcon>*/}

            {/*<ListItemText primary={*/}
                <span className="menu-text" dangerouslySetInnerHTML={{ __html: entry.label }} />
            {/*} />*/}
        </ListItem>
    );
};

GenericMenuItem.propTypes = {
    entry    : PropTypes.object.isRequired,
    closeMenu: PropTypes.func.isRequired,
    // Indicate on which page the menu instance is attached
    associatedPageKey: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
};

export default GenericMenuItem;
