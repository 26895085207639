
import { HOME_PAGE_KEY } from 'src/pages/pagesKeys';

import HomePage from './HomePage';
import reducer from './homePageReducer';

export default {
    key      : HOME_PAGE_KEY,
    path     : '/home',
    component: HomePage,
    elId     : 'home-page',
    getReduxReducer: (state, action) => reducer(state[HOME_PAGE_KEY], action),
    toggleMode  : 'visibility', // NB: if HomePage `toggleMode` is set to display:none Masonry doesn't work great
};
