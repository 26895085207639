
import React from 'react';

import CTAButton from 'src/components/cta-button/CTAButton';

export default ({
    labels,
    actions,
    goToSimple,
}) => (
    <>
        <CTAButton
            id="sfs-edit-btn"
            label={labels.searchTaiga.editSearch}
            action={actions.taigaSearchClear}
            />

        <CTAButton
            id="sfs-go-to-simple-btn"
            label={labels.searchTaiga.goToSimpleSearch}
            action={goToSimple}
            />
    </>
)
