
import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header/Header';
import Content from '../content/Content';
import Footer from '../footer/Footer';

import '../disclaimer-common.scss';


function DisclaimerScreen({ labels, actions }) {

    function acceptDisclaimer() {
        actions.klipsoLeadsSetDisclaimerStatus(true);
    }

    return (
        <div id="kl-ds">
            <Header
                labels={labels}
                onCloseClick={() => actions.navigateToHome()}
            />

            <Content>
                <div
                    className="kl-disclaimer"
                    dangerouslySetInnerHTML={{ __html: labels.klipsoLeads.disclaimer }}
                />
            </Content>

            <Footer
                onClick={acceptDisclaimer}
                isTextBtnMode={true}
            >
                <div>{ labels.klipsoLeads.accept }</div>
            </Footer>
        </div>
    );
}

DisclaimerScreen.propTypes = {
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

export default DisclaimerScreen;