
import React from 'react';
import PropTypes from 'prop-types';

import Header from '../header/Header';
import Content from '../content/Content';
import ContactInfo from './ContactInfo';
import Footer from '../footer/Footer';
import FooterSaveButton from '../footer/FooterSaveButton';
import FooterCancelButton from '../footer/FooterCancelButton';
import InputModal from 'src/components/form/InputModal';
import CheckBoxes from 'src/components/form/CheckBoxes';
import TextField from 'src/components/form/TextField';
import InputSwitch from 'src/components/form/InputSwitch';

import { FIELD_TYPES, saveContact } from 'src/core/klipso-leads/KlipsoLeadsData';
import { saveVisitorRelationList } from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';
import { formatLongDateTime } from 'src/core/Lang';
import { simpleSort } from 'src/core/util/sortUtil';
import { testEmail } from 'src/core/util/StringUtil';

import './FormScreen.scss';

global.klMockHistoryDateDelta = 0;
// e.g: -24*60*60*1000 => 24 hours ago


const FIELDS_INPUT_TYPE = {
    [FIELD_TYPES.EDIT_TEXT]: 'text',
    [FIELD_TYPES.TEXT_AREA]: 'textarea',
    [FIELD_TYPES.DATE_TIME]: 'date',
    [FIELD_TYPES.NUMERIC]: 'number',
    [FIELD_TYPES.BOOLEAN]: 'switch',
    [FIELD_TYPES.SINGLE_CHOICE]: 'radio',
    [FIELD_TYPES.MULTI_CHOICE]: 'checkbox',
    [FIELD_TYPES.EMAIL]: 'email',
};

const dateToString = d => (
    !d ? '' : formatLongDateTime(d, true, true)
)

function getDateTimestamp(d) {
    if (typeof d === 'string' || typeof d === 'number') {
        return new Date(d).getTime();
    }
    return d.getTime();
}


class FormScreen extends React.Component {

    constructor(props) {
        super(props);
        this.renderEditableFormField = this.renderEditableFormField.bind(this);
        this.renderFormInput = this.renderFormInput.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.hasError = this.hasError.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.renderModalInput = this.renderModalInput.bind(this);

        // Store modified fields
        this.state = {};
    }

    save() {
        let updatedContact = Object.assign(this.props.contact, this.state);

        // Update history
        if (Array.isArray(updatedContact.history) !== true) {
            updatedContact.history = [];
        }
        let currentTime = new Date().getTime()
                + (typeof global.klMockHistoryDateDelta === 'number' ? global.klMockHistoryDateDelta : 0);
        updatedContact.history.unshift({ date: currentTime });
        updatedContact.isSync = false;

        // Persist contact
        saveContact(Object.assign(this.props.contact, this.state));

        this.exitForm();

        // Background sync - result is ignored / failure is silent
        saveVisitorRelationList();
    }

    cancel() {
        this.exitForm();
    }

    exitForm() {
        this.props.actions.klipsoLeadsExitFormScreen();
    }

    hasError(code, value) {
        let formField = this.getFormField(code);
        let hasError;

        switch (formField.Type) {
            case FIELD_TYPES.EMAIL:
                hasError = (
                    typeof value === 'string'
                        && value.length > 0
                        && testEmail(value) !== true
                );
                break;

            default:
                hasError = false;
        }

        // console.log('Field '+code+' value is '+(hasError ? 'not' : '')+' OK: '+value);
        return hasError;
    }

    getFormField(code) {
        return this.props.editableFields.find(formField => formField.Code === code)
    }

    getCodification = (formField, codifId) => (
        formField.Codifications.find(codif => codif.Id === codifId)
    )

    getValue(code) {
        if (typeof this.state[code] !== 'undefined') {
            // Field modified but not saved yet
            return this.state[code];
        }
        return this.props.contact[code] || this.getFormField(code).DefaultValue;
    }

    getValueLabel(fieldCode, value) {
        let formField = this.getFormField(fieldCode);

        switch (formField.Type) {

            case FIELD_TYPES.SINGLE_CHOICE:
                if (value === null || typeof value === 'undefined') {
                    return '';
                } else {
                    return this.getCodification(formField, value).Label;
                }

            case FIELD_TYPES.MULTI_CHOICE:
                if (Array.isArray(value)) {
                    return value.map(codifId => (
                        this.getCodification(formField, codifId).Label
                    )).join(', ');
                    break;
                }

            case FIELD_TYPES.DATE_TIME:
                return formatLongDateTime(new Date(), false);

            default:
                return value;
        }
    }

    onValueChange(fieldCode, value) {
        let formField = this.getFormField(fieldCode);
        switch (formField.Type) {

            case FIELD_TYPES.MULTI_CHOICE:
                if (Array.isArray(value)) {
                    if (value.length === 0) {
                        value = null;
                    } else if (value.length > 1) {
                        value.sort();
                    }
                }
                break;

            case FIELD_TYPES.DATE_TIME:
                if (typeof value === 'string' && value.length > 0) {
                    value = new Date(value).getTime();
                }
                break;

            default: // for linter
        }

        console.log('New '+fieldCode+' value: ', value);
        this.setState({ [fieldCode]: value });
    }

    renderEditableFormField(formField) {
        /* {
            "CABPosition": 1,
            "Code": "Prenom",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Prenom",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Prénom",
            "Nature": 0, // nb: editable FormField nature should be !== 0 and !== 1
            "PrintPosition": 1,
            "Type": 0
        } */

        let inputType = FIELDS_INPUT_TYPE[formField.Type];

        switch (formField.Type) {

            case FIELD_TYPES.BOOLEAN:
                return this.renderFormSwitch({
                    name: formField.Code,
                    placeholder: formField.Label,
                    readOnly: formField.IsReadOnly,
                    disabled: false,
                    required: formField.IsMandatory,
                });

            default:
                return (
                    <InputModal
                        key={formField.Code}
                        name={formField.Code}
                        type={inputType}
                        initialValue={this.getValue(formField.Code)}
                        readOnly={!inputType || formField.IsReadOnly}
                        required={formField.IsMandatory}
                        disabled={false}
                        placeHolder={formField.Label}
                        hasError={this.hasError}
                        renderClickableInput={this.renderFormInput}
                        renderModalInput={this.renderModalInput}
                        submit={this.onValueChange}
                        hideOnSubmit
                        labels={this.props.labels}
                        okButtonLabel={this.props.labels.common.ok}
                        className="kl-fs-modal dialog-min-width"
                    />
                );
        }
    }

    renderFormInput({
        name,
        className,
        placeholder,
        // type,
        onBlur,
        readOnly,
        disabled,
        required,
        onClick,
        // onChange,
    }) {
        let value = this.getValue(name);

        return (
            <div
                key={name}
                className={'kl-fs-field '+(className || '')}
                readOnly={readOnly}
                disabled={disabled}
                required={required}
                onClick={onClick}
            >
                <div className={'kl-fs-icon '+(value ? 'kl-fs-icon-check fal fa-check' : '')} />

                <div className="kl-fs-label-and-value">
                    <div className="kl-fs-label">{placeholder}</div>
                    { value &&
                        <div className="kl-fs-value">{this.getValueLabel(name, value)}</div>
                    }
                </div>

                <div className="kl-fs-chevron fas fa-chevron-right" />
            </div>
        );
    }

    renderFormSwitch({
        name,
        placeholder,
        readOnly,
        disabled,
        required,
    }) {
        let value = this.getValue(name);

        return (
            <div
                key={name}
                className="kl-fs-field kl-fs-field-switch"
                readOnly={readOnly}
                disabled={disabled}
                required={required}
            >
                <div className="kl-fs-icon" />

                <div className="kl-fs-label-and-value">
                    <div className="kl-fs-label">{placeholder}</div>
                </div>

                <InputSwitch
                    name={name}
                    value={value}
                    editable={!readOnly && !disabled}
                    submit={this.onValueChange}
                />
            </div>
        );
    }

    parseCheckBoxValue(name) {
        return this.getFormField(name).Codifications.map(codif => ({
            label: codif.Label,
            value: codif.Id,
        }));
    }

    renderModalInput({
        name,
        className,
        placeholder,
        type,
        value,
        onBlur,
        readOnly,
        disabled,
        required,
        onClick,
        onChange,
    }) {
        switch (type) {
            case 'text':   // EDIT_TEXT
            case 'number': // NUMERIC
            case 'date':   // DATE_TIME
            case 'email':  // EMAIL
            case 'textarea': // TEXT_AREA
                return (
                    <TextField
                        fieldName={name}
                        type={type}
                        className={className}
                        currentFormValue={value || ''}
                        setValue={onChange}
                        label={placeholder}
                        autofocus
                    />
                );

            case 'radio': // SINGLE_CHOICE
            case 'checkbox': // MULTI_CHOICE
                return (
                    <CheckBoxes
                        fieldName={name}
                        className={className}
                        currentFormValue={value || []}
                        setValue={onChange}
                        label={placeholder}
                        labels={this.props.labels}
                        multiple={type !== 'radio'}
                        values={this.parseCheckBoxValue(name)}
                    />
                );

            case 'switch': // BOOLEAN - Not displayed using InputModal
                return null;

            default:
                console.error('Unexpected field type: '+type);
                return null;
        }
    }

    render() {
        let {
            context,
            contact,
            // formFields,
            readOnlyFields,
            editableFields,
            labels,
        } = this.props;

        return (
            <div id="kl-form-screen">
                <Header
                    subtitle={context.exhibitor.name}
                    labels={labels}
                />

                <Content>
                    <ContactInfo
                        contact={contact}
                        readOnlyFields={readOnlyFields}
                        labels={labels}
                    />
                    <div className="kl-fs-title">{labels.klipsoLeads.formTitle}</div>

                    {/* Questionnaire */}
                    { editableFields.map(this.renderEditableFormField) }

                    {/* History */}
                    { Array.isArray(contact.history) &&
                            contact.history.length > 0 &&

                        <div className="kl-fs-history">
                            <div className="kl-fs-history-col1">
                                <div className="kl-fs-history-icon far fa-history" />
                            </div>
                            <div className="kl-fs-history-col2">
                                <div className="kl-fs-history-title title-font">
                                    {labels.klipsoLeads.historyTitle}
                                </div>
                                <div className="kl-fs-history-entries">
                                    {contact.history.map(hEntry => (
                                        <div key={getDateTimestamp(hEntry.date)}>
                                            {dateToString(hEntry.date)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </Content>

                <Footer>
                    <FooterCancelButton
                        callback={this.cancel}
                        labels={labels}
                    />
                    <FooterSaveButton
                        callback={this.save}
                        labels={labels}
                    />
                </Footer>
            </div>
        );
    }
}

FormScreen.propTypes = {
    context: PropTypes.object.isRequired, // licence, userName, exhibitor, event, checkpointId...
    contact: PropTypes.object.isRequired, // contact data
    // formFields: PropTypes.array,
    readOnlyFields: PropTypes.array,
    editableFields: PropTypes.array,
    labels : PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

export default FormScreen;
