
import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-custom';

import { isCordovaContext, isIOS } from 'src/core/util/browser';

import './SideIndex.scss';


const INDEX_ELEMENT_CLASSNAME = 'list-index-element';
const SET_SELECTED_STYLE_DELAY = 300;


class SideIndex extends React.PureComponent {

    state = {}

    isCordovaIOS = isCordovaContext() && isIOS()
    lastGoToIndexKey = null
    _scrollTopValues = null
    setSelectedStyleTimeout = null
    touchOngoing = false

    resetScrollTopValues() {
        this._scrollTopValues = null;
    }

    // Used for alphabetical list when items count is < ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE
    getScrollTopValues = () => {
        if (!this._scrollTopValues) {
            this._scrollTopValues = {};

            const separators = this.props.separatorsGetter();

            // Read all separators offset top value
            Object.keys(separators).forEach((indexKey) => {
                if (separators[indexKey]) {
                    this._scrollTopValues[indexKey] = separators[indexKey].offsetTop - 5;
                }
            });
        }
        return this._scrollTopValues;
    }

    getIndexFromScrollPosition = yValue => {
        // thanks https://stackoverflow.com/questions/8584902/get-closest-number-out-of-array
        let stv = this.getScrollTopValues();
        return Object.keys(stv).reduce(function(prev, curr) {
            return (Math.abs(stv[curr] - yValue) < Math.abs(stv[prev] - yValue) ? curr : prev);
        });
    }

    setSelectedIndexFromScrollPosition = throttle((yValue) => {
        this.setSelectedStyle(this.getIndexFromScrollPosition(yValue));
    }, 100)

    goTo = indexKey => {
        this.lastGoToIndexKey = indexKey;

        if (this.props.contentByIndex) {
            // Refresh content
            this.props.scrollTo(null, indexKey);
        }
        else {
            const scrollTopValue = this.getScrollTopValues()[indexKey];
            // console.log('Scroll to index: '+indexKey+ ' / scrollTop value: '+scrollTopValue + '  (source:'+source+')');
            this.props.scrollTo(scrollTopValue);
        }

        // Apply style after a delay
        if (this.state.currentIndexKeyWithStyle !== this.lastGoToIndexKey) {
            this.resetSelectedStyle();
            if (this.setSelectedStyleTimeout) {
                window.clearTimeout(this.setSelectedStyleTimeout);
            }
            this.setSelectedStyleTimeout = window.setTimeout(this.setSelectedStyle, SET_SELECTED_STYLE_DELAY, this.lastGoToIndexKey);
        }
    }

    resetSelectedStyle() {
        this.setState({ currentIndexKeyWithStyle: null });
    }
    setSelectedStyle = indexKey => {
        if (indexKey && this.state.currentIndexKeyWithStyle !== indexKey) {
            this.setState({ currentIndexKeyWithStyle: indexKey });
        }
    }

    /**
     * Mouse event attached to each <li>
     * @param  {object} e
     */
    onLiSelected = e => {
        let indexKey = e.target.dataset.indexkey;
        if (indexKey) {
            this.goTo(indexKey, 'onLiSelected');
            return indexKey;
        }
    }

    getIndexFromEventCoordinates(e) {
        // Beware: e.target contains the element where the touchmove started
        let el;
        if (e.touches && e.touches.length > 0) {
            let evtPos = e.touches[0];
            // @see http://caniuse.com/#feat=element-from-point
            el = document.elementFromPoint(evtPos.clientX, evtPos.clientY);

            if (el && el.classList.contains(INDEX_ELEMENT_CLASSNAME)) {
                return el.dataset.indexkey;
            }
        }
    }

    onMouseEnter = e => {
        if (this.isCordovaIOS) {
            // PRIN-65 ionic wkwebview weird behavior
            return;
        }
        this.onLiSelected(e);
    }

    onClick = e => {
        if (this.touchOngoing) {
            // PRIN-65 ionic wkwebview weird behavior
            return;
        }

        let indexKey = this.onLiSelected(e);
        if (indexKey) {
            this.setSelectedStyle();
        }
    }

    /**
     * Handle touchmove event
     * @param {object} e: event
     */
    touchMoveHandler = e => {
        this.setTouchOngoing(true); // don't set it in touchStart or click will be prevented

        let indexKey = this.getIndexFromEventCoordinates(e);
        if (indexKey) {
            this.goTo(indexKey, 'touchMoveHandler');
        }
    }


    rafHold = false

    /**
     * Handle touchmove event on <ul>
     * @param  {object} e
     */
    onTouchMove = e => {
        // @see https://facebook.github.io/react/docs/events.html
        e.persist();
        // e.preventDefault();

        if (!this.rafHold) {
            requestAnimationFrame(() => {
                this.rafHold = false;
                this.touchMoveHandler(e);
            });
            this.rafHold = true;
        }
    }

    onTouchEnd = e => {
        window.setTimeout(this.setTouchOngoing, 50, false);
    }

    setTouchOngoing = value => {
        this.touchOngoing = value;
    }

    render() {
        const getLabel = typeof this.props.getLabel === 'function'
                            ? this.props.getLabel
                            : value => value;

        // Selected index on first render
        let currentIndex = this.state.currentIndexKeyWithStyle;
        if (!currentIndex && !this.setSelectedStyleTimeout) {
            currentIndex = this.props.indexes[0];
        }

        return (
            <ul className="side-index"
                style={this.props.style || {}}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
                >
              {
                (this.props.indexes || []).map(indexKey => (
                    <li key={indexKey}
                        className={INDEX_ELEMENT_CLASSNAME + (indexKey === currentIndex ? ' list-index-element-selected' : '')}
                        onMouseEnter={this.onMouseEnter}
                        onClick={this.onClick}
                        data-indexkey={indexKey}
                        dangerouslySetInnerHTML={{ __html: getLabel(indexKey) }}
                    />
                ))
              }
            </ul>
        );
    }
};

SideIndex.propTypes = {
    indexes       : PropTypes.array.isRequired,
    separatorsGetter: PropTypes.func, // needed for alphabetical list whose item count is below ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE
    getLabel      : PropTypes.func,
    scrollTo      : PropTypes.func.isRequired,
    style         : PropTypes.object,
    contentByIndex: PropTypes.bool,
};

export default SideIndex;
