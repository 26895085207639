
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import './OverlayLoader.scss';


function OverlayLoader({ label, position }) {

    // Forced position center for now - too much CSS PITA between devices
    position = 'center';

    return (
        <div className={'kl-overlay-loader kl-ol-'+position}>
            <div>
                { label &&
                    <div className="kl-overlay-loader-label">{label}</div>
                }
                <CircularProgress />
            </div>
        </div>
    );
}

OverlayLoader.propTypes = {
    label: PropTypes.string,
    position: PropTypes.oneOf(['top', 'center', 'bottom']),
};

OverlayLoader.defaultProps = {
    position: 'center',
};

export default OverlayLoader;