
import { prefixWith } from 'src/store/reducers/utils';
import { get as getProfile } from 'src/core/Profile';
import { getBindedActions } from 'src/store/bindedActions';

import * as config from 'data/config/config';

export const LOG_PREF = '[ContributionsFeedManager]';

export const prefix = prefixWith('contributions-feed');


let fetchInterval = null;


export const isEnabled = () => {
    const profile = getProfile() || 'default'
    return config.CONTRIBUTIONS_FEED.FEATURE_ENABLED && (profile === 'default')
}

export const init = () => {
    if (isEnabled()) {
        console.log(`${LOG_PREF} init`)

        // const contributionsFeedUrl = config.CONTRIBUTIONS_FEED.URL;

        // every config.CONTRIBUTIONS_FEED.INTERVAL seconds
        fetchInterval = window.setInterval(() => {
            getBindedActions().fetchContributionsFeed();
        }, config.CONTRIBUTIONS_FEED.INTERVAL)

        // in cordova when app is pulled from the background
        document.addEventListener('resume', () => {
            console.log(LOG_PREF+'resume event');
            getBindedActions().fetchContributionsFeed();
        });

        // at init time
        getBindedActions().fetchContributionsFeed();
    }
};

export const stop = () => {
    if (fetchInterval) {
        window.clearInterval(fetchInterval)
    }
}
