import {
    HIDE_NOTE_MODAL,
    LANG_CHANGED,
    SHOW_NOTE_MODAL,
    GET_NOTE,
    NOTE_SAVED,
    KEYBOARD_TOGGLED
} from "src/store/actionTypes";
import {
    langChanged,
    updateObject,
    noteSaved,
    updateKeyboardState
} from "src/store/reducers/commons";

import { get as getLabels } from "src/core/Lang";

const getDefaultState = () => ({
    labels: getLabels(),
    isOpen: false
});



const _toggleModal = (state, action, value) => {
    return updateObject(state, {
        isOpen: value,
        itemTitle: action.itemTitle,
        itemId: action.itemId,
        dataType: action.dataType,
        liEl: action.liEl
    });
};
const getNote = (state, action) => {
    return updateObject(state, {
        note: action.note
    });
};

export default (state = getDefaultState(), action) => {
    switch (action.type) {
        case KEYBOARD_TOGGLED:
            return updateKeyboardState(state, action)
        case HIDE_NOTE_MODAL:
            return _toggleModal(state, action, false);
        case LANG_CHANGED:
            return langChanged(state, action);
        case SHOW_NOTE_MODAL:
            return _toggleModal(state, action, true);
        case GET_NOTE:
            return getNote(state, action);
        case NOTE_SAVED:
            return noteSaved(state, action);
        default:
            return state;
    }
};
