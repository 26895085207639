
import config from 'data/config/config';

import Swal from 'sweetalert2'

import { get as getLabels } from 'src/core/Lang';

const LOG_PREF = '[showConfirmModal] ';


/**
 * @param  {object} opts such as:
 * {
 *   title: (optional) string
 *   text : (optional) string
 *   yesBtnLabel: (optional) string - default: labels.common.ok
 *   noBtnLabel : (optional) string - default: labels.common.no
 *
 *   anywayCb: (optional) function - callback executed is both case, before the other callbacks
 *   yesCb   : (optional) function
 *   noCb    : (optional) function
 * }
 *
 * e.g
 * showConfirmModal({
      title: 'Test',
      text: 'Hey il parait que...',
      yesBtnLabel: 'Oui!',
      noBtnLabel: 'Oulà non',
      anywayCb: function() { console.log('executed anyway'); },
      yesCb: function() { console.log('confirmed'); },
      noCb: function() { console.log('cancelled'); },
   })

   showConfirmModal({
      text: 'Attention ça va démarrer',
   })
 */
function showConfirmModal(opts) {
    if (!opts) {
        console.error(LOG_PREF+'Missing confirm modal options');
        return;
    }

    let labels = getLabels();

    let yesBtnLabel = opts.yesBtnLabel || labels.common.ok,
        noBtnLabel  = opts.noBtnLabel  || labels.common.no;

    // See sweetalert api documentation
    // https://sweetalert2.github.io/#usage

    Swal.fire({
        title: opts.title,
        html : opts.text,
        showCancelButton : typeof opts.noCb === 'function',
        confirmButtonText: yesBtnLabel,
        cancelButtonText : noBtnLabel,
        customClass: {
            container: 'dialog-container',
            title: 'dialog-title',
        },
        heightAuto: false,
        animation: false,
        reverseButtons: true,
    })
    .then(result => {
        if (typeof opts.anywayCb === 'function'){
            opts.anywayCb();
        }

        if (result && result.value) {
            // confirmed
            if (typeof opts.yesCb === 'function'){
                opts.yesCb();
            }
        } else {
            // cancelled
            if (typeof opts.noCb === 'function'){
                opts.noCb();
            }
        }
    });
}

export default showConfirmModal;

if (config.ENV) {
    global.showConfirmModal = showConfirmModal;
}
