
import React from 'react';

import renderToggleButton from './renderToggleButton';
import renderField from './renderField';
import { getValue } from 'src/core/util/JsTools';

const LOG_PREF = '[SearchForm renderSection] ';


export default ({
    index,
    section,
    fieldsHooks,
    performSearch,
    isSearchOngoing,
    labels,
    setFieldVisiblity,
    toggleButtonsVisiblityHooks,
}) => (

    <section
        key={index}
        className={section.className || null}>

        { section.label &&
            <label>{ getValue(labels, section.label) }</label>
        }

        {/* BUTTONS */}
        { (section.buttons || []).map(function(toggleButtonConfig, index) {
            switch (toggleButtonConfig.type) {
                case 'TOGGLE':
                    return renderToggleButton({
                        key: index,
                        toggleButtonConfig,
                        fieldsHooks,
                        performSearch,
                        isSearchOngoing,
                        labels,
                        setFieldVisiblity,
                        toggleButtonsVisiblityHooks,
                      });

                default: console.error(LOG_PREF+'Unmanaged button type '+toggleButtonConfig.type);
            }
        }) }

        {/* FIELDS */}
        { section.fields &&
            Object.keys(section.fields).map(fieldName => renderField({
                fieldName,
                fieldConf: section.fields[fieldName],
                fieldsHooks,
                performSearch,
                isSearchOngoing,
                labels,
                setFieldVisiblity,
            }))
        }
    </section>
)
