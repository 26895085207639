
import isOnline from './isOnline';
import { get as getLabels } from 'src/core/Lang';
import { getBindedActions } from 'src/store/bindedActions';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { isIOS } from 'src/core/util/browser';


const LOG_PREF = '[JsTools] ';

/**
 * Return an array from an object
 * @param  {object} obj
 * @return {array}
 */
export const objectToArray = (obj) => {
    return Object.keys(obj).map((key) => obj[key]);
};


/**
 * Return a querystring from object properties
 * @param  {object}  obj
 * @param  {boolean} encoreUri
 * @return {string}
 */
export const serialize = (obj, encodeUri = true) => {
    if (!obj) {
        return '';
    }
    if (typeof obj !== 'object') {
        console.error(LOG_PREF+'Cannot serialize non \'object\' argument', obj);
        return '';
    }
    let value = '';

    Object.keys(obj).forEach(optKey => {
        let optValue = obj[optKey],
            stringifiedValue = ['array','object'].indexOf(typeof optValue) === -1 ? optValue : JSON.stringify(optValue);
        value += '&'+optKey+'='+ (encodeUri ? encodeURI(stringifiedValue) : stringifiedValue);
    });
    return value;
};


/**
 * Sort an array of stringified integers
 * @param  {string} str1
 * @param  {string} str2
 * @return {boolean}
 */
export const sortNumbersAsStrings = (str1, str2) => {
    return parseInt(str1, 10) > parseInt(str2, 10);
};


/**
 * Should ensure that a url is clickable,
 * preventing <a href="www.xyz.com"...> from redirecting to something like: http://oursite/ourpage/www.xyz.com
 * @param  {string} link
 * @return {string}
 */
export const addHttpIfNeeded = (link, useHttps=false) => {
    if (!link) {
        return '';
    }
    if (link.indexOf('://') === -1) {
        return 'http'+(useHttps ? 's' : '')+'://'+link;
    }
    return link;
};


const protocolSeparator = '://';
/**
 * Remove protocol if any (e.g "https://www.abce.fr" -> "www.abcde.fr")
 * @param  {string} link
 * @return {string}
 */
export const removeLinkProtocol = (link) => {
    if (!link) {
        return '';
    }
    let index = link.indexOf(protocolSeparator);
    if (index !== -1) {
        return link.slice(index+protocolSeparator.length);
    }
    return link;
};


/**
 * Return the attribute value of an object from a path
 * @param {object|array} obj
 * @param {string} attr, example: "user.address.street"
 * @return {*}
 */
export const getValue = (obj, attr) => {
    if (!obj) {
        return obj;
    }
    if (typeof attr !== 'string'){
        throw new Error('Invalid argument! expected "attr" to be a string, but got ' + typeof attr);
    }
    let path = attr.split('.');

    // Simple case
    if (path.length === 1){
        return obj[path];
    }
    else {
        // Case with sub-levels
        let tmpValue = obj[path.shift()];
        let step;

        //uses the assignation as a way to make an iteration
        //TODO: reverse the loop to avoid shifting the whole
        //array at each iteration
        while (typeof (step = path.shift()) !== 'undefined'){ //eslint-disable-line no-cond-assign
            if (tmpValue === null || typeof tmpValue === 'undefined' || tmpValue.hasOwnProperty(step) === false){
                return null;
            }
            tmpValue = tmpValue[step];
        }
        return tmpValue;
    }
};

/**
 * @param  {string} href
 * @return {boolean} true: if url could be opened / false: if no network was available
 */
export function openUrl(href, cb, target) {
    function perform() {
        window.open(href, target || '_blank');
    }

    if (typeof cb !== 'function') {
        cb = function(){};
    }

    if (global.isCordovaContext === true && href.indexOf('://') === -1) {
        // Cordova + no protocol => local resource => no need to check network
        perform();
        cb(true);
        return;
    }
    if (global.isCordovaContext === true) {
      isOnline(status => {

          if (status === true) {
              // web OK
              perform();
              cb(true);
          }
          else {
              // web KO
              getBindedActions().showNotification({
                  message: getLabels().common.noNetworkTitle,
                  level: NotificationLevels.ERROR,
              });
              cb(false);
          }
      });
    } else {
    perform();
    cb(true);
  }

};


/**
 * Merge two arrays and remove duplications (works for primitives only)
 * @param  {array} arr1
 * @param  {array} arr2
 * @return {array|null}
 */
export const mergeArrayWithUniqueValues = (arr1, arr2) => {
    if (Array.isArray(arr1) !== true) {
        console.error('arr1 is not an array');
        return null;
    }
    if (Array.isArray(arr2) !== true) {
        console.error('arr2 is not an array');
        return null;
    }
    let _result = arr1.concat(arr2);

    // Remove duplicates
    const testIndex = (lastIndex, i) => lastIndex !== -1 && lastIndex > i;
    for (let i=0; i<_result.length-1; i++) {
        let lastIndex;
        do {
            lastIndex = _result.lastIndexOf(_result[i]);
            if (testIndex(lastIndex, i)) {
                _result.splice(lastIndex, 1);
            }
        } while (testIndex(lastIndex, i));
    }
    return _result;
};


/**
 * Return the attribute value of an object from a path
 * @param {object|array} obj
 * @param {string} attr, example: "user.address.street"
 * @return {*}
 */
export const getValueFromStringAttribute = (obj, attr) => {
    if (!obj) {
        return;
    }
    if (typeof attr !== 'string'){
        throw new Error('Invalid argument! expected \'attr\' to be a string, but got ' + typeof attr);
    }
    let path = attr.split('.');

    // Simple case
    if (path.length === 1){
        return obj[path];
    }
    else {
        // Case with sub-levels
        let tmpValue = obj[path.shift()];
        let step = path.shift();
        while (step){
            if (!tmpValue || tmpValue.hasOwnProperty(step) === false){
                return null;
            }
            tmpValue = tmpValue[step];

            // iterate
            step = path.shift();
        }
        return tmpValue;
    }
};

export const convertFileSrc = function(url) {

    var webviewServerUrl = 'http://localhost';
    if (isIOS()){
        webviewServerUrl = 'ionic://localhost';
    }


    if (!url) {
        return url;
    }
    if (url.startsWith('/')) {
        return webviewServerUrl + '/_app_file_' + url;
    }
    if (url.startsWith('file://')) {
        return webviewServerUrl + url.replace('file://', '/_app_file_');
    }
    if (url.startsWith('content://')) {
        return webviewServerUrl + url.replace('content:/', '/_app_content_');
    }
    return url;
};


export const getRandomLetter = () => String.fromCharCode(Math.floor(Math.random()*26)+65);


/**
 * Get query parameter value
 * @source https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 * @param  {string} name
 * @param  {string} url
 */
export function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");

    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


/**
 * Cast `value` as String
 * @param {*} value
 */
export const stringify = value => typeof value !== 'string' ? String(value) : value;


global.noop = function noop() {}
