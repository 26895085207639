
import { PAPER_PAGE_KEY } from 'src/pages/pagesKeys';

import PaperContent from './PaperContent';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

export default {
    key      : PAPER_PAGE_KEY,
    path     : '/paper',
    elId     : DOM_ID,
    className: 'paper-page',
    component: GenericItemPage,
    childComponent: PaperContent,
    relatedDataToFetch: [ 'cats', 'events' ],
};
