import React from 'react';
import PropTypes from 'prop-types';

class FacebookPage extends React.Component {

    state = {
        width : 0,
        height: 0,
    }

    containerRef = React.createRef()

    shouldComponentUpdate(nextProps, nextState) {
        const { src } = this.props
        const { width, height } = this.state
        return (
            nextProps.src !== src
                || nextState.width !== width
                || nextState.height !== height
        );
    }

    componentDidMount() {
        this.setState({
            width: this.containerRef.current.offsetWidth,
            height: this.containerRef.current.offsetHeight,
        })
    }

    render() {
        const { src } = this.props
        const { width, height } = this.state

        const source = encodeURIComponent(src)

        return (
            <div
              className='facebook-page-timeline'
              ref={this.containerRef}
            >
                {width > 0 &&
                    <iframe
                        title="facebook-page"
                        src={`https://www.facebook.com/plugins/page.php?href=${source}` +
                          `&tabs=timeline` +
                          `&width=${width <= 500 ? width : 500}` +
                          `&height=${height}` +
                          `&small_header=false` +
                          `&adapt_container_width=true` +
                          `&hide_cover=false` +
                          `&show_facepile=true` +
                          `&appId=286745252020603`}
                        width={width <= 500 ? width : 500}
                        height={height}
                        style={{
                            border: 'none',
                            overflow: 'hidden',
                            height,
                            width: width <= 500 ? width : 500
                        }}
                        scrolling='yes'
                        frameBorder='0'
                        // allowTransparency
                        allow='encrypted-media'
                    />
                }
            </div>
        )
    }

}

FacebookPage.propTypes = {
    src: PropTypes.string.isRequired,
};

export default FacebookPage;
