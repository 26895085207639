
import config from 'data/config/config';

import { isChrome, isFirefox } from 'src/core/util/browser';
import { notificationHandler } from 'src/pages/inbox/InboxService';
import { get as getLabels } from 'src/core/Lang';


const LOG_PREF = '[WebPush] ';

let Pushwoosh;

function isLibLoaded() {
    if (!window.Pushwoosh) {
        window.addEventListener('load', init);
        return false;
    }
    return true;
}

/**
 * @doc https://docs.pushwoosh.com/platform-docs/pushwoosh-sdk/web-push-notifications/web-push-sdk-3.0
 */
export function init() {
    if (!config.WEB_PUSH || !config.WEB_PUSH.FEATURE_ENABLED) {
        console.info('Web Push disabled');
        return;
    }

    if (!isChrome() && !isFirefox()) {
        console.info('Web Push disabled (only chrome and firefox are supported)');
        return;
    }

    if (!isLibLoaded()) {
        return;
    }
    console.info(LOG_PREF+'init');

    // Lib is loaded from a CDN with a script tag, see config/env.js
    Pushwoosh = window.Pushwoosh;

    Pushwoosh.push(['init', {
        logLevel: 'info', // possible values: error, info, debug
        applicationCode: config.appId, // you application code from Pushwoosh Control Panel
        // safariWebsitePushID: 'web.com.example.domain', //  unique reverse-domain string, obtained in you Apple Developer Portal. Only needed if you send push notifications to Safari browser
        defaultNotificationTitle: config.WEB_PUSH.DEFAULT_NOTIFICATION_TITLE, // sets a default title for push notifications
        defaultNotificationImage: config.WEB_PUSH.DEFAULT_NOTIFICATION_IMAGE, // URL to custom custom notification image
        autoSubscribe: config.WEB_PUSH.AUTO_SUBSCRIBE, // or true. If true, prompts a user to subscribe for pushes upon SDK initialization
        subscribeWidget: {
          enable: config.WEB_PUSH.SUBSCRIBE_WIDGET_ENABLED,
          position: 'bottomLeft', //possible values: ‘bottomLeft’, ‘bottomRight’, ‘topLeft’, ‘topRight’
          bgColor: '#12AE7E',
          bellColor: 'white',
          bellStrokeColor: '#08754f',
          bellButtonBorder: '1px solid #379676',
          shadow: '0px 0px 6px rgba(0, 0, 0, 0.75)',
          size: '48px',
          indent: '20px',
          zIndex: '999999',
          tooltipText: {
            successSubscribe: getLabels().webPush.successSubscribe,
            needSubscribe: getLabels().webPush.needSubscribe,
            blockSubscribe: getLabels().webPush.blockSubscribe,
            alreadySubscribed: getLabels().webPush.alreadySubscribed,
          },
        },
        // userId: 'user_id', // optional, set custom user ID
        // tags: { 'Name': 'John Smith' }   // optional, set custom Tags
    }]);

    Pushwoosh.push(['onReady', function(api) {
        console.log(LOG_PREF+'Pushwoosh ready');
    }]);

    Pushwoosh.push(['onSubscribe', function(api) {
        console.log(LOG_PREF+'event onSubscribe');
    }]);

    Pushwoosh.push(['onUnsubscribe', function(api) {
        console.log(LOG_PREF+'event onUnsubscribe');
    }]);

    Pushwoosh.push(['onNotificationClick', function(api, payload) {
        console.log(LOG_PREF+'event onNotificationClick', payload);
        /* e.g
        payload: {
            body: "SERF organise un cocktail sur son stand. A tout de suite ! 🍸",
            buttons: [],
            campaignCode: "",
            code: "notificationCode-1573126772772-bfe23c21",
            customData: {
                page: "Exhibitor",
                originalId: "0dffab38-7cdb-e911-80e2-005056ae0696",
            },
            duration: 20,
            icon: "https://cp.pushwoosh.com/img/logo-medium.png",
            image: "",
            inboxId: "",
            messageHash: "O",
            openUrl: "/",
            requireInteraction: false,
            tag: "{"url":"/","messageHash":"O","customData":{"page":"Exhibitor","originalId":"0dffab38-7cdb-e911-80e2-005056ae0696"}}",
            title: "SOFCOT 2019",
            url: "/",
        }
        */
        if (payload) {
            notificationHandler(payload);
        }
    }]);
}


export function setProfileTag(value) {
    if (!Pushwoosh) {
        return;
    }
    Pushwoosh.push(function(api) {
        var customTags = { Profile: value };

        api.setTags(customTags).then(function(res) {
            var skipped = res && res.skipped || [];
            if (!skipped.length) {
                console.log(LOG_PREF+'Successfully set \'Profile\' tag: '+value);
            } else {
                console.warn(LOG_PREF+'Skipped tags:', skipped);
            }
        }).catch(function(err) {
            console.error(LOG_PREF+'setTags error:', err);
        });});
}