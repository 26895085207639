
import React from 'react';
import PropTypes from 'prop-types';

import { saveVisitorRelationList } from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';


function FooterSyncButton({ hasNonSyncData, actions }) {

    async function onClick() {
        actions.klipsoLeadsSetSyncOngoing(true);

        let error;
        try {
            await saveVisitorRelationList();
        } catch(e) {
            error = e;
        }

        actions.klipsoLeadsSetSyncOngoing(false);

        if (error) {
            showGenericWsErrorNotification(error);
        }
    }

    return (
        <div
            className="klf-btn klf-sync-btn far fa-sync"
            disabled={hasNonSyncData === false}
            onClick={hasNonSyncData ? onClick : null}
        />
    );
}

FooterSyncButton.propTypes = {
    hasNonSyncData: PropTypes.bool,
    actions: PropTypes.object.isRequired,
}

export default FooterSyncButton;
