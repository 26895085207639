
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import config from 'data/config/config';

import {
    CATEGORIES_DATA_TYPE,
    CATEGORIES_MAPPING
} from 'data/config/dataConfig';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';

import {
    FAVORITES_PAGE_KEY,
    LIST_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY,
    SEARCH_PAGE_KEY,
    SYNOPTIC_AGENDA_PAGE_KEY,
} from 'src/pages/pagesKeys';

import SearchField from 'src/components/search/SearchField';

import './AppToolbar.scss';


export const APP_TOOLBAR_HEIGHT = 48;


const getButton = (iconClassName, iconStyle, action, label) => (
    <span className="app-toolbar-icon" onClick={action} title={label}>
        <span className={iconClassName} style={iconStyle} />
    </span>
);

class AppToolbar extends PureComponent {

    setSearchFieldRef = ref => {
        this.searchField = ref;
    }

    hasRootCategoryButtonInToolbar = () => !this.props.searchFieldVisible && this.props.rootCategoryButtonInToolbar

    hasSynchroFavBtnStatus = () => this.props.isSynchroFavFeatureEnabled
                                    && !this.props.searchFieldVisible
                                    && this.props.synchroFavBtnStatus

    // BACK

    hasBackButton = () => this.props.hasBackButton

    handleClickOnBackButton = () => {
        if (this.props.searchFieldVisible) {
            this.clearSearchResults();
            this.hideSearchField();
        } else {
            this.props.actions.navigateBack();
        }
    }

    // HOME

    hasHomeButton = () => !this.props.searchFieldVisible && this.props.hasHomeButton

    navigateToHome = () => {
        let homePage = config.getHomePage(this.props.profile);
        this.props.actions.navigate(homePage.pageKey, homePage.props);
    }

    // FAVORITES

    hasFavButton = () => !this.props.searchFieldVisible && this.props.hasFavButton === true

    navigateToFavoritesPage = () => {
        this.props.actions.navigate(FAVORITES_PAGE_KEY);
    }

    // SYNOPTIC

    hasSynopticAgendaButton = () => !this.props.searchFieldVisible && this.props.hasSynopticAgendaButton

    navigateToSynopticAgendaPage = () => {
        this.props.actions.navigate(SYNOPTIC_AGENDA_PAGE_KEY, this.props.agendaProps ? { agendaProps: this.props.agendaProps } : null);
    }

    // AGENDA

    hasBackToAgendaButton = () => !this.props.searchFieldVisible && this.props.hasBackToAgendaButton

    navigateBackToAgendaPage = () => {
        this.props.actions.navigate(LIST_GROUPS_PAGE_KEY, this.props.agendaProps);
    }

    // MENU

    hasMenuButton = () => this.props.hasMenuButton

    openMenu = () => {
        // this.hideSearchField();
        this.props.actions.openMenu(this.props.pageKey);
    }

    // FILTER

    hasFilterButton = () => !this.props.searchFieldVisible && this.props.hasFilterButton

    handleFilterButtonClick = () => {
        this.props.actions.showFilterDialog(this.props.contextualDataTypes);
    }

    // SEARCH

    hasSearchButton = () => this.props.pageKey !== SEARCH_PAGE_KEY
                                && !this.props.searchFieldVisible
                                && this.props.hasSearchButton === true

    handleSearchButtonClick = () => {
        if (this.props.contextualDataTypes) {
            this.showSearchField();
            this.focusOnSearchField();
        } else {
            this.props.actions.navigate(SEARCH_PAGE_KEY);
        }
    }

    showSearchField = () => {
        this.props.actions.setSearchFieldVisible(this.props.pageKey, true);
    }

    focusOnSearchField = () => {
        // NB: on iOS, the focus can only be set following a user action (no timeout possible)
        if (this.searchField) {
            this.searchField.focus();
        }
    }

    getSearchFieldPlaceholder({ contextualDataTypes, labels }) {
        const placeholder = []
        if (Array.isArray(contextualDataTypes)) {
            contextualDataTypes.forEach(dataType => {
                let type = dataType

                if (CATEGORIES_DATA_TYPE.indexOf(dataType) > -1) {
                    type = CATEGORIES_MAPPING[dataType]
                }

                const text = labels.data[type].plural.toLowerCase()

                if (placeholder.indexOf(text) === -1) {
                    placeholder.push(text)
                }
            })
        }

        if (labels.search.keywords) {
            placeholder.push(labels.search.keywords)
        }

        return placeholder.join(', ')
    }

    hideSearchField = () => {
        this.props.actions.setSearchFieldVisible(this.props.pageKey, false);
    }

    clearSearchResults = () => {
        this.props.actions.clearSearchResults(this.props.pageKey);
    }

    performSearch = str => {
        this.props.actions.performSearch(str, this.props.pageKey, this.props.contextualDataTypes, true);
    }

    getClassName = () => (
        'app-toolbar'
        + (this.props.theme ? ' theme-'+this.props.theme : '')
        + (!this.props.disableDropShadow ? ' at-with-dropshadow' : '')
    )

    render() {
        return (
            <div className={this.getClassName()}
                 ref={this.setToolbarEl}>

                <div className="left">
                    { this.hasBackButton() &&
                         getButton('material-icons arrow_back toolbar-back-btn', null, this.handleClickOnBackButton, this.props.labels.common.goBack)
                    }
                </div>

                <CSSTransition
                    in={this.props.searchFieldVisible}
                    classNames="search-field"
                    timeout={0}
                    >

                    <SearchField
                        key={0}
                        ref={this.setSearchFieldRef}
                        placeholder={this.getSearchFieldPlaceholder(this.props)}
                        perform={this.performSearch}
                        clearResults={this.clearSearchResults}
                        searchResults={this.props.searchResults}
                        minChars={this.props.pageKey === LIST_PAGE_KEY ? 1 : SEARCH_MIN_CHARS}
                        isContextual={true}
                        hasCancelButton={true}
                        onCancel={this.hideSearchField}
                        />
                </CSSTransition>

                { !this.props.searchFieldVisible &&
                    <div className="center content-font">{this.props.contextualTitle || this.props.title}</div>
                }

                <div className="right">

                    { this.hasSynopticAgendaButton() &&
                        getButton('fa fa-calendar-alt', { fontSize: '.95em' }, this.navigateToSynopticAgendaPage, this.props.labels.common.goToSynopticAgenda)
                    }

                    { this.hasBackToAgendaButton() &&
                        getButton('fa fa-calendar-week', { fontSize: '.95em' }, this.navigateBackToAgendaPage, this.props.labels.common.goBackToAgenda)
                    }

                    { this.hasRootCategoryButtonInToolbar() &&
                        getButton('fa fa-list', null, this.props.navigateToRootCategory, this.props.labels.common.goToRootCategory)
                    }

                    { this.hasHomeButton() &&
                        getButton('fa fa-home', null, this.navigateToHome, this.props.labels.common.goToHome)
                    }

                    { this.hasFavButton() === true &&
                        getButton('material-icons star', null, this.navigateToFavoritesPage, this.props.labels.common.goToFavorites)
                    }

                    { this.hasSearchButton() === true &&
                        getButton('material-icons search', null, this.handleSearchButtonClick, this.props.labels.common.goToSearch)
                    }

                    { this.hasFilterButton() &&
                        getButton(
                            'fa fa-filter'+(this.props.isDataFiltered ? ' filter-enabled' : ''),
                            { fontSize: '.8em' },
                            this.handleFilterButtonClick,
                            this.props.labels.common.goToFilter)
                    }

                    { this.hasSynchroFavBtnStatus() &&
                        getButton(
                            `fa fa-refresh synchro-fav-btn synchro-fav-btn-${this.props.synchroFavBtnStatus}`,
                            null,
                            this.props.actions.synchroFavoritesIconClicked,
                            ''
                        )
                    }

                    { this.hasMenuButton() === true &&
                        getButton('material-icons menu', null, this.openMenu, this.props.labels.common.openMenu)
                    }
                </div>
            </div>
        );
    }

}

AppToolbar.propTypes = {
    labels         : PropTypes.object.isRequired,
    actions        : PropTypes.object.isRequired,
    profile        : PropTypes.string.isRequired,
    isDisplayed    : PropTypes.bool,
    title          : PropTypes.string.isRequired,
    contextualTitle: PropTypes.string,
    pageKey        : PropTypes.string,
    hasBackButton  : PropTypes.bool,
    hasMenuButton  : PropTypes.bool,
    hasHomeButton  : PropTypes.bool,
    hasFavButton   : PropTypes.bool,
    hasSearchButton: PropTypes.bool,
    hasFilterButton: PropTypes.bool,
    hasSynopticAgendaButton: PropTypes.bool,
    agendaProps: PropTypes.object,
    hasBackToAgendaButton: PropTypes.bool,
    onSearchClear  : PropTypes.func,
    synchroFavBtnStatus: PropTypes.string,
    rootCategoryButtonInToolbar : PropTypes.bool,
    navigateToRootCategory: PropTypes.func,
    searchResults: PropTypes.object,
    lastResize   : PropTypes.number,
    isDataFiltered: PropTypes.bool,
    disableDropShadow: PropTypes.bool,
    searchFieldVisible: PropTypes.bool,
    theme: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => state["appToolbar"]

export default connect(mapStateToProps)(AppToolbar)
