
import React from 'react'
import PropTypes from 'prop-types';

import { formatDayLong } from 'src/core/Lang';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { removeHtml } from 'src/core/util/DomTools';

import ContactButton from 'src/pages/participant/ContactButton';

/**
 * Used when a value is modified by List component
 * (e.g a string being added ...<b>...</b>... as a search result)
 * @type {String}
 */
export const ORIGINAL_PROP_PREFIX = 'original';


const getFavIconCssClasses = (itemIsFavorite) => {
    let favIconClassname = ['star', 'icon-font'];
    if (itemIsFavorite) {
        favIconClassname.push('is-fav');
    }
    return favIconClassname;
};

const renderImage = (src, className) => (
    <img
        className={'list-el-image '+(className ? className : '')}
        alt=""
        src={ src ? getUrl(src) : null }
    />
)

/**
 * This is a class instead of a stateless component because `ref` callback
 * is needed for ListElement of type separators. @see AlphabeticalList `getScrollTopValues`
 */
class ListElement extends React.Component {

    shouldComponentUpdate(nextProps) {

        let differs = [];
        Object.keys(nextProps).forEach(propName => {
            if (nextProps[propName] !== this.props[propName]
                    && JSON.stringify(nextProps[propName]) !== JSON.stringify(this.props[propName])) {
                differs.push(propName);
            }
        });

        return differs.length > 0;
    }

    render() {

        let {
            text,
            originaltext,
            textMinor,
            textMinorBis,
            textStyle,
            originaltextMinor,
            hasFavoriteButton,
            showGoToButton,
            goToButtonLabel,
            showContactButton,
            event,
            isFavorite,
            isFastAndUgly,
            isSeparator,
            leftImage,
            leftImageClassName,
            image,
            image2,
            image3,
            underImage,
            counter,
            isClickable = true,
            className = '',
            isNoteItem,
            note,
            contactedOnLabel,
            contactDate,
            ...attrs
        } = this.props;

        const
            showFavoriteIcon = !isFastAndUgly && hasFavoriteButton === true,
            showImage        = !isFastAndUgly && typeof image === 'string' && image!=='',
            showImage2       = !isFastAndUgly && typeof image2 === 'string' && image2!=='',
            showImage3       = !isFastAndUgly && typeof image3 === 'string' && image3!=='',
            showUnderImage       = !isFastAndUgly && typeof underImage === 'string' && underImage!=='',
            showCounter      = typeof counter === 'number';


        if (isSeparator) {
            return <li className="horizontal-separator" {...attrs}><span className="separator-text">{text}</span></li>;

        // Minimal fast&ugly mode for very long lists
        } else if (isFastAndUgly) {
           return <li data-is-clickable={isClickable === true ? '' : null} {...attrs}><span>{text}</span></li>;

        } else {


            let noteExcerpt = isNoteItem && note && note.length > 150 ? (note.substring(0,150)+"...") : note;


            return (
                <li
                    title={removeHtml(originaltext || text)}
                    className={className + (!showFavoriteIcon && (!showImage || isNoteItem) && (!event || isNoteItem) ? ' no-previous-sibling' : '')}
                    data-is-clickable={isClickable === true ? '' : null}
                    {...attrs} >

                    { showFavoriteIcon && <span className={getFavIconCssClasses(isFavorite).join(' ')}>e</span> }

                    {/* Event time */}
                    { event && !isNoteItem &&
                            <span className="list-el-event">
                                <div className="start-date" dangerouslySetInnerHTML={{ __html: formatDayLong(event.start_date) }} />
                                <div className="start-hour">{event.start_time}</div>
                                { event.start_date !== event.end_date && <div className="end-date" dangerouslySetInnerHTML={{ __html: formatDayLong(event.end_date) }} /> }
                                <div className="end-hour">{event.end_time}</div>
                            </span>
                    }

                    { leftImage && renderImage(leftImage, leftImageClassName) }
                    { !leftImage && leftImageClassName &&
                        <span className={leftImageClassName} />
                    }

                    <div className={"itemWrapper"}>
                        <span className="list-el-text">
                            <span style={textStyle} dangerouslySetInnerHTML={{ __html: text }}></span>
                            <div className="list-el-details">
                                { textMinor && !isNoteItem && <span className="text-minor" dangerouslySetInnerHTML={{ __html: textMinor }} /> }
                                { textMinorBis && !isNoteItem && <span className="text-minor-bis" dangerouslySetInnerHTML={{ __html: textMinorBis }} /> }
                            </div>
                        </span>

                          {
                            isNoteItem &&
                            <span
                            className="noteExcerpt"
                            >
                                {"\""+noteExcerpt+"\""}
                            </span>
                        }
                    </div>

                    <div className="list-el-icons">
                        { showImage2 && <img className="list-el-image-small" alt="" src={ getUrl(image2) } /> }

                        { showImage3 && <img className="list-el-image-small" alt="" src={ getUrl(image3) } /> }
                    </div>

                    { showImage && showUnderImage ?
                        <div className="list-el-dual-images">
                         <img className="list-el-image" alt="" src={ getUrl(image) } />
                         { <img className="list-el-image under-image" alt="" src={ getUrl(underImage) } /> }
                        </div>
                        :
                        showImage && renderImage(image)
                    }

                    { showCounter && <span className="list-el-counter"><span>{counter}</span></span> }

                    {/* Itinerary button */}
                    { showGoToButton === true && <span className="list-el-btn go-to">{goToButtonLabel}</span> }

                    {/* contact */}
                    { showContactButton === true && <ContactButton participantId={attrs['data-id']} /> }
                    { contactDate && <span className="list-el-contacted">{contactedOnLabel + formatDayLong(contactDate)}</span>}

                    { isClickable && <span className="list-el-chevron fa fa-chevron-right"></span>  || <span className="list-el-chevron fa fa-chevron-right nochevron"></span> }
                </li>
            );
        }
    };
}

/**
 * @see app-react/data/config/listConfig.getHelpers
 */
ListElement.propTypes = {
    id               : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    text             : PropTypes.string.isRequired,
    originaltext     : PropTypes.string,
    textMinor        : PropTypes.string,
    originaltextMinor: PropTypes.string,
    className        : PropTypes.string,
    hasFavoriteButton: PropTypes.bool,
    isFavorite       : PropTypes.bool,
    isSeparator      : PropTypes.bool,
    showGoToButton   : PropTypes.bool,
    goToButtonLabel  : PropTypes.string,
    showContactButton: PropTypes.bool,
    contactedOnLabel : PropTypes.string,
    contactDate      : PropTypes.instanceOf(Date),
};

export default ListElement;
