
import React from 'react';

import { getValue } from 'src/core/util/JsTools';

import TextField from '../form/TextField';
import CheckBoxes from '../form/CheckBoxes';

const LOG_PREF = '[SearchForm renderField] ';


export default function({
    fieldName,
    fieldConf,
    fieldsHooks,
    performSearch,
    isSearchOngoing,
    labels,
    setFieldVisiblity,
}) {

    let hook = fieldsHooks[fieldName],
        label = fieldConf.label ? getValue(labels, fieldConf.label) : null;

    switch (fieldConf.type) {

        case 'text': return TextField({
            fieldName,
            ...hook,
            label,
            performSearch,
            isSearchOngoing,
            hasSearchButton: !!fieldConf.hasSearchButton,
            setFieldVisiblity,
        });

        case 'checkbox': return CheckBoxes({
            fieldName,
            ...hook,
            label,
            labels,
            multiple: fieldConf.multiple,
            values: fieldConf.values,
            setFieldVisiblity,
        });

        default: console.error(LOG_PREF+'TODO: render field type \''+fieldConf.type+'\'');
    }
}
