import React from 'react';
import PropTypes from 'prop-types';

import ListsPerDataType from 'src/components/list/ListsPerDataType';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';

import './ContextualSearch.scss'


class ContextualSearch extends React.Component {

    render() {
        return (
            <div className={"contextual-search-results content-below-apptoolbar "+(this.props.className || '')}>

                <div className="list-search-content content-font">
                    <ListsPerDataType
                        items={this.props.items}
                        favorites={this.props.favorites}
                        userData={this.props.userData}
                        actions={this.props.actions}
                        labels={this.props.labels}
                        pageKey={this.props.pageKey}
                    />
                </div>

                <DummyKeyboardPlaceholder keyboardHeight={this.props.keyboardHeight} />

                <IphonexFlexMargin />
            </div>
        );
    }
}

ContextualSearch.props = {
    className: PropTypes.string,
    items: PropTypes.object,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    keyboardHeight: PropTypes.number,
    userData: PropTypes.object,
    pageKey: PropTypes.string,
};

export default ContextualSearch;
