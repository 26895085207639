import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';

import MediaTabItem from './MediaTabItem';
import MediaTab from './MediaTab';

const tabHeight = 48

class MediasPageContent extends Component {
    state = {
        value: 0,
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (JSON.stringify(this.props) !== JSON.stringify(nextProps)) || nextState.value !== this.state.value
    }

    handleChange = (event, value) => {
        const { socialMediaConfig } = this.props

        this.setState({ value })

        const mediaKeys = Object.keys(socialMediaConfig)
        const platformClicked = mediaKeys[value]
        const platformConfig = socialMediaConfig[platformClicked]

        if (platformConfig.link) {
            window.open(platformConfig.link, '_system')
        }
    }

    handleChangeIndex = index => {
        this.setState({ value: index })
    }

    render() {
        const { socialMediaConfig, actions } = this.props
        const mediaKeys = Object.keys(socialMediaConfig)
        const contentStyle = { height: '100%' }

        return (
            <div className="media-content-container content-font content-below-apptoolbar">
                <div className="media-timeline">

                    {/* Tabs API: https://material-ui.com/api/tabs/ */}
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                    >
                        {mediaKeys.map((platform, index) => {
                            const config = socialMediaConfig[platform]
                            const active = this.state.value === index
                            return (
                                <MediaTabItem
                                    key={platform}
                                    config={config}
                                    active={active}
                                    actions={actions}
                                />
                            )
                        })}
                    </Tabs>

                    {/* SwipeableViews API: https://react-swipeable-views.com/api/api/ */}
                    <SwipeableViews
                        slideStyle={contentStyle}
                        containerStyle={contentStyle}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                    >
                        {mediaKeys.map(platform =>
                            <MediaTab
                                key={platform}
                                height="100%"
                                platform={platform}
                                config={socialMediaConfig[platform]}
                                data={{
                                    twitter: this.props.twitter,
                                    facebook: this.props.facebook,
                                    instagram: this.props.instagram,
                                    youtube: this.props.youtube,
                                    linkedin: this.props.linkedin,
                                }}
                                actions={this.props.actions}
                            />
                        )}
                    </SwipeableViews>
                </div>
            </div>
        );
    }

}

MediasPageContent.propTypes = {
    socialMediaConfig: PropTypes.object.isRequired,
    twitter: PropTypes.object,
    facebook: PropTypes.object,
    instagram: PropTypes.object,
    youtube: PropTypes.object,
    linkedin: PropTypes.object,
    adConfig: PropTypes.object,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
};

export default MediasPageContent;
