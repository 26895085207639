
import { SYNOPTIC_AGENDA_PAGE_KEY } from 'src/pages/pagesKeys';

import SynopticAgendaPage from './SynopticAgendaPage';
import reducer from './synopticAgendaPageReducer';

export default {
    key      : SYNOPTIC_AGENDA_PAGE_KEY,
    path     : '/synoptic-agenda',
    component: SynopticAgendaPage,
    elId     : 'synoptic-agenda-page',
    getReduxReducer: (state, action) => reducer(state[SYNOPTIC_AGENDA_PAGE_KEY], action),
};
