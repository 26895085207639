
import React from 'react';
import PropTypes from 'prop-types';

import './Header.scss';


function Header({ labels, subtitle, onCloseClick }) {

    return (
        <div className="klipso-leads-header title-font">
            <div className="klh-left"></div>

            <div className="klh-center">
                <span className="klh-title">{labels.klipsoLeads.title}</span>
                { subtitle &&
                    <span className="klh-subtitle">{ subtitle }</span>
                }
            </div>

            <div className="klh-right">
                { typeof onCloseClick === 'function' &&
                    <span
                        className="klh-close-btn far fa-times"
                        onClick={onCloseClick}
                        />
                }
            </div>
        </div>
    );
}

Header.propTypes = {
    subtitle: PropTypes.string,
    labels: PropTypes.object.isRequired,
    onCloseClick: PropTypes.func,
}

export default Header;
