
import config from 'data/config/config';

import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { getBindedActions } from 'src/store/bindedActions';

import {
    VALIDATE_POLL_CODE,
    SUBMIT_POLL
} from 'src/store/actionTypes';


import { pollSubmitted } from 'src/core/polls/PollManager'

export const ERRORS = {
	SUBMITTED: 'SUBMITTED',
	OUTDATED_BEFORE: 'OUTDATED_BEFORE',
	OUTDATED_AFTER: 'OUTDATED_AFTER',
	FILLED: 'FILLED',
	CODE: 'CODE',
	NO_CODE: 'NO_CODE',
	NO_ID: 'NO_ID',
    LOGIN  : 'LOGIN',
    NETWORK: 'NETWORK',
	SERVER: 'SERVER'
};


export function setError(error, detail) {
	getBindedActions().setPollError(error, detail);
}

export function setPage(page) {
	getBindedActions().setPollPage(page)
}

export function setId(poll_id) {
	getBindedActions().setPollId(poll_id)
}

export function setCode(poll_code) {
	getBindedActions().setPollCode(poll_code)
}

export function showDialog(poll_id) {
    getBindedActions().showPollDialog(poll_id)
}

export const ERROR_MANAGEMENT_TABLE = {
	NO_ID: () => setPage('list'),
	NO_CODE: () => setPage('form'),
	CODE: () => setPage('form'),
	LOGIN  : () => {
		getBindedActions().hidePollDialog()
		getBindedActions().navigate(LOGIN_PAGE_KEY)
	},
	SUBMITTED: () => setPage('submit'),
	FILLED: (context) => {
		if (context === VALIDATE_POLL_CODE) {
			setPage('formValid')
		}
		else if (context !== SUBMIT_POLL) {
			setPage('submit')
		}
	},
	OUTDATED_BEFORE: () => setPage('error'),
	OUTDATED_AFTER: () => setPage('error'),
    NETWORK: () => setPage('error'),
	SERVER: () => setPage('server')
}

/**
 * Call the API to submit the poll
 * @param  {object}   data
 */
export function submit(poll, poll_id) {
    let opts = {
        method: 'POST',
        body: JSON.stringify(poll),
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
    };

    fetchHelper(
        config.POLL.URL,
        opts,
        true, // isJson
        data => { _onApiCallSuccess(data, poll_id); },
		() => { _onApiCallFailure(poll)},
        false // showModalOnError
    );
}

function _onApiCallSuccess(data, poll_id) {
    if (data.success) {
        pollSubmitted(poll_id)
	    getBindedActions().setPollPage('submitted');
    }
    else {
		// No need to switch page since the server error is handled by the submit page
		getBindedActions().setPollError(ERRORS.SERVER);
    }
}

function _onApiCallFailure(poll) {
	// No need to switch page since the network error is handled by the submit page
    getBindedActions().setPollError(ERRORS.NETWORK);
}
