
import { PARTICIPANT_PAGE_KEY } from 'src/pages/pagesKeys';

import ParticipantContent from './ParticipantContent';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

import { isSessionValid }  from 'src/core/login/LoginService';

export default {
    key      : PARTICIPANT_PAGE_KEY,
    path     : '/participant',
    elId     : DOM_ID,
    className: 'participant-page',
    component: GenericItemPage,
    childComponent: ParticipantContent,
    relatedDataToFetch: [ 'participantContactData', 'participantDbEvents' ],
    isGranted: () => isSessionValid(),
};
