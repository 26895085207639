
import { ENV } from 'data/config/config';

import { scan } from 'src/core/cordova/BarCodeScanner';
import { getBadgeFields } from './KlipsoLeadsData';
import { getTestDataBadge } from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

const LOG_PREF = '[KlipsoLeadsBadgeHelper] ';

global.klMockBadge = false;


/**
 * Uncrypt the content of badge (QR code)
 * @param  {string}
 * @return {string}
 */
const uncrypt = string => new Promise(function (resolve, reject) {
    if (!string) {
        console.warn(LOG_PREF+'No string to uncrypt');
        resolve(null);
        return;
    }
    if (!window.mobilespotApp
            || typeof window.mobilespotApp.uncryptKlipsoBadge !== 'function') {

        console.error(LOG_PREF+'cordova-plugin-mobilespot-app is missing');
        reject();
        return;
    }

    window.mobilespotApp.uncryptKlipsoBadge(string, resolve, reject);
})

/**
 * String format is expected as:
 * code&value1#value2#value3#...
 *
 * e.g: "HW744RQ86H&Ribeiro#CHRISTINE#VACATAIRE#ducat.c@live.fr#"
 *
 * see doc: https://mobile-spot.atlassian.net/wiki/spaces/KLEAD/pages/113704964/Current+App+and+testing.
 *
 * @param  {string} string
 * @return {null|object}
 */
function splitUncryptedString(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return null;
    }

    const badgeFields = getBadgeFields();
    if (!badgeFields) {
        console.error('Can\'t parse klipso badge string because of missing badge fields (FormFields where CABPosition is a number)');
        return null;
    }
    console.log('Badge fields: ', badgeFields);

    // Parse values
    let ampersandIndex = string.indexOf('&');
    if (ampersandIndex === -1) {
        return null;
    }
    let code = string.slice(0, ampersandIndex);
    let values = string.slice(ampersandIndex+1).split('#');

    let result = {
        code: code,
        // a unique id needs to be generated (see doc)
        guid: code+'-'+new Date().getTime(),
    };

    // A form field is defined such as:
    /*{
        "CABPosition": 1,
        "Code": "Prenom",
        "Codifications": [],
        "DefaultValue": "",
        "Id": "Prenom",
        "IsMandatory": false,
        "IsReadOnly": false,
        "Label": "Prénom",
        "Nature": 0,
        "PrintPosition": 1,
        "Type": 0
    }*/

    badgeFields.forEach(function(badgeField, i) {
        let fieldCode = badgeField.Code,
            cabPosition = badgeField.CABPosition,
            indexInValues = badgeField.CABPosition-1;

        if (indexInValues > values.length-1) {
            console.warn('Can\'t read a value at CABPosition '+cabPosition+' in string: '+string);
            return;
        }
        console.log('Field for CABPosition '+cabPosition+' is '+fieldCode+', its value is: '+values[indexInValues]);

        result[fieldCode] = values[indexInValues];
    });

    return result;
}

// For test purpose
if (ENV === 'dev') {
    global.splitUncryptedString = splitUncryptedString;
}


export async function performScan({ actions, labels, callback }) {

    // DEV MODE
    if ((!global.isCordovaContext || global.klMockBadge === true) && ENV === 'dev') {
        let content = splitUncryptedString(getTestDataBadge());

        callback({
            result: content,
            resultFormat: 'QR_CODE',
        });
        return;
    }

    try {
        let result = await scan();
        if (!result) {
            actions.showNotification({
                message: labels.klipsoLeads.scanError,
                level: NotificationLevels.WARNING,
            });
            return;
        }

        let content;
        if (result.text && result.text.length && result.text[0] === '#') {
            // crypted badge (when content starts with a '#')
            // see: cordova/src-app-plugins/cordova-plugin-mobilespot-app/src/android/KlipsoLeadsQrCodeDecoder.java
            content = await uncrypt(result.text).catch(e => {
                console.error('Failed to uncrypt QR code: '+e);
            });
            content = splitUncryptedString(content);
        } else {
            // CAB or uncrypted badge
            content = result.text;
        }

        callback({
            result: content,
            resultFormat: result.format,
        });

    } catch (e) {
        actions.showNotification({
            message: labels.klipsoLeads.scanDenied,
            level: NotificationLevels.WARNING,
        });
    }
}
