//const {VISITOR_PRO_PROFILE, VISITOR_GP_PROFILE, PRESS_PROFILE } = require('./profiles');
var _require = require('../../src/pages/pagesKeys'),
    AIRCRAFT_PAGE_KEY = _require.AIRCRAFT_PAGE_KEY,
    EXHIBITOR_PAGE_KEY = _require.EXHIBITOR_PAGE_KEY,
    EXPERIENCE_ITEM_PAGE_KEY = _require.EXPERIENCE_ITEM_PAGE_KEY,
    FAVORITES_PAGE_KEY = _require.FAVORITES_PAGE_KEY,
    FLIGHTS_SCHEDULE_PAGE_KEY = _require.FLIGHTS_SCHEDULE_PAGE_KEY,
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY = _require.LIST_GROUPS_PAGE_KEY,
    LIST_PAGE_KEY = _require.LIST_PAGE_KEY,
    MEDIAS_PAGE_KEY = _require.MEDIAS_PAGE_KEY,
    NEWPRODUCT_PAGE_KEY = _require.NEWPRODUCT_PAGE_KEY,
    PMR_PAGE_KEY = _require.PMR_PAGE_KEY,
    SEARCH_PAGE_KEY = _require.SEARCH_PAGE_KEY,
    SEARCH_TAIGA_PAGE_KEY = _require.SEARCH_TAIGA_PAGE_KEY;
/**
 * Toolbar configuration per profile
 */


export default function (profile, pageKey) {
  // Home does not have AppToolbar component
  if (pageKey === HOME_PAGE_KEY) {
    return {};
  }

  switch (profile) {
    // Same for every profile
    // case PROFILE_VISITEUR:
    // case PROFILE_EXPOSANT:
    // case PROFILE_PRESSE:
    default:
      return {
        homeButtonInToolbar: true,
        favButtonInToolbar: pageKey !== FAVORITES_PAGE_KEY,
        searchButtonInToolbar: pageKey !== SEARCH_PAGE_KEY && pageKey !== SEARCH_TAIGA_PAGE_KEY,
        menuButtonInToolbar: true,
        // Used when some profiles have access to reduced version of the app:
        // (e.g http://eurosatory-dev.mobile-spot.com/?profile=webapp-exh)
        rootCategoryButtonInToolbar: false && (pageKey === LIST_PAGE || pageKey === LIST_GROUPS_PAGE_KEY)
      };
  }
}
;